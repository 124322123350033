:root {
  --font-family: "Montserrat", sans-serif;

  /* Theme based colors section */
  --icons-color: #0276DB;
  --sec-bg-color: #043677;


  --pri-font-color: #212121;
  --pri-bg-color: #F5F7F9;
  --sec-font-color: #fff;
  --tri-bg-color: #ffffff;
  --light-white-color: #fff;
  --body-font: 0.729vw;
  --para-font: 0.9375vw;
  --small-title: 0.833vw;
  --card-title: 0.525vw;
  --border-color: #9fb0c2;
  --page-desktop: 33px 60px;
  --gray-color: #7d8a9b;
  --lightgray-color: #575353;
  --darkgray-color: #2f2f2f;
  --light-gray: #ccc;
  --light-violet: #819abb;
  --violet: #8b9db0;
  --border-bottom-color: #b0b9c5;
  --progress-bar-color: #5681EF;
  --black-color: black;
  --gray: #d5dde5;
  --border-left-color: #a8b6c3;
  --slider-arrow-color: #a8b6c3;
  --search-border-color: #8391a4;
  --filter-border-color: #d5d5d5;
  --form-color: #d2dbe3;
  --form-field-color: #000;
  --browse: #53a9ff;
  --orange-background: #d44e36;
  --login-btn-background: #5681EF;


  /*Analytics styles*/
  --background-color-graphs: rgba(221, 221, 221, 0.5);
  --analytics-no-data-font: bold 100% sans-serif;
  --analytics-no-data-font-20x: bold 20px sans-serif;
  --analytics-no-data-color: black;
  --analytics-no-data-align: center;

  --analytics-primary-color: #062349;
  --analytics-enrolled-course-color: #E1E5E9;
  --analytics-white: #FFFFFF;
  --analytics-inActive-colour: #919DAE;
  --analytics-low-engaged-color: #BBCDF9;

  --analytics-hour_spent-color: #5681EF;
  --analytics-not-started-color: #095CD8;
  --analytics-ongoing-color: #628FD2;



}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* Video player ending */

body {
  font-family: var(--font-family) !important;
  background-color: var(--pri-bg-color);
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
div,
li,
span {
  font-family: var(--font-family) !important;
}

ul {
  margin: 0;
  padding: 0;
}

ul li {
  list-style-type: none;
}

img {
  width: 100%;
}

/***********************************************
                    spacings
************************************************/
.m-50 {
  margin-bottom: 50px;
}

.m-30 {
  margin-bottom: 30px;
}

.m-20 {
  margin-bottom: 20px;
}

.m-40 {
  margin-bottom: 40px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-230 {
  margin-top: 230px;
}

.mt-50 {
  margin-top: 8.5em;
}

.b-0 {
  border: none !important;
}

.react-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
}

@media (max-width:991px) {
  .b-lg-0 {
    border: 0 !important;
  }
}

/***********************************************
                    Scroll bar Section 
************************************************/


::-webkit-scrollbar {
  width: 6px !important;
}

ul::-webkit-scrollbar {
  width: 5px !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
  -webkit-text-fill-color: #333;
}

::-webkit-scrollbar-thumb {
  background-color: var(--icons-color);
  border-radius: 25px;
}

@media (max-width:700px) {
  ::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  .MuiTabs-scroller::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }

  ul::-webkit-scrollbar {
    width: 0px !important;
  }
}

.row,
.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.new-quiz-thumb img {
  border-radius: 0.5vw;
}


.session-warning-alert {
  position: absolute;
  top: 20px
}

/***********************************************
                    commons
************************************************/

.gif {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gif-quiz {
  height: 42.4vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner-border img {
  cursor: var(--slider-arrow-color) !important;
}

.gif .spinner-border {
  width: 100px;
  height: 100%;
  margin: auto;
  display: grid;
  place-items: center;
}

.spinner-border {
  border: none !important;
  --bs-spinner-animation-name: none !important;
}

#loader,
.loader {
  /* animation: 1.3s lazyload ease-in-out infinite; */
  background: var(--light-gray);
}

.loader,
#loader img {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  opacity: 0.83;
}

@keyframes lazyload {
  0% {
    opacity: 0;
  }

  25% {
    opacity: 0.3;
  }

  50% {
    opacity: 0.6;
  }

  75% {
    opacity: 0.3;
  }

  100% {
    opacity: 0;
  }
}

/******************** a tag ********************/
a {
  text-decoration: none;
  color: var(--pri-font-color);

}

a:hover {
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

p {
  font-weight: 400;
  font-family: var(--font-family) !important;
  font-size: 16px;
  padding-top: 10px;
}

/******************** buttons ******************/

/******************** fields ******************/
input,
select,
textarea {
  outline: unset !important;
  width: 100%;
  font-family: var(--font-family) !important;
}

#grid-search {
  border: unset;
  padding: 0px 3.9vw;
  font-size: 0.9375vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.85px;
  color: var(--pri-font-color);
  background-color: transparent;
}

.uploaded-list {
  width: 10;
  height: 10;
  margin-right: "10px";
  border-radius: '50%';
  background-color: 'green'
}

.uploaded-list-radioBtn {
  width: 5%;
  margin: 0px 10px;
}

.lms-search-width-collection {
  width: 50%;
}

.lms-search-width-collection-video {
  width: 70%;
}

.lms-search-width {
  width: 62%;
}

/***********************************************
                    flex-layout
************************************************/
.lms-flex {
  display: flex;
}

.lms-flex-align-center {
  display: flex;
  align-items: center;
}

.lms-flex-align-center ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #FFFFFF;
  opacity: 1;
  /* Firefox */
  opacity: 1;
  /* Firefox */
}

.lms-flex-align-center :-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #FFFFFF;
}

.lms-flex-align-center ::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #FFFFFF;
}

.lms-flex-start {
  display: flex;
  justify-content: flex-start;
}

.lms-flex-between {
  display: flex;
  justify-content: space-between;
  /* cursor: pointer; */

}

/***********************************************
                     Mainpage -layout
  ************************************************/

#aside {
  width: 7%;
  height: 100vh;
  position: fixed;
  background-color: var(--tri-bg-color);
  border-right: solid 0.5px var(--border-color);
  z-index: 999;
}

.alert {
  position: absolute;
  top: 20px
}
#side-nav-main{
  width: 7%;
}

@media (max-width: 991px) {
  #aside {
    display: none;
  }

  #side-nav-main {
    display: none;
  }
}

.aside-space {
  padding: 1.5625vw 1.25vw 3.3854166vw 1.25vw;
}

.lms-text {
  text-align: center;
}

.left-nav-height {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 91vh;
}

.lms-logo {
  margin-bottom: 4.6875vw;
  cursor: pointer;
}

/* 
.lms-logo img {
  height: 1.51041vw;
} */

.terms-conditions-con p {
  font-size: 0.62vw;
}

.terms-conditions-con p:hover {
  font-weight: 600;
}

.terms-conditions-con {
  cursor: default;
  padding: 0.46vw;
  display: flex;
  justify-content: space-around;
  border-top: 0.5px solid #A8B6C3;
}

.terms-conditions-con a {
  margin-bottom: 0px;
  cursor: pointer;
}

.menu-img {
  margin-bottom: 1.041666vw;
  cursor: pointer;
  height: 3.64vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu-img a {
  display: block;
  margin: unset;
}

.menu-img .active::after {
  content: "";
  position: absolute;
  right: -1.3vw;
  border-right: 0.25vw solid var(--icons-color);
  z-index: 1;
  height: 100%;
  top: 0;
  opacity: 1;

}

.menu-img .is-active {
  opacity: 1;
}

.menu-img a {
  opacity: 0.6;
}

.menu-img a:active {
  opacity: 1;
}

.menu-img a:hover {
  opacity: 1;
}

.menu-res-img svg {
  opacity: 0.5;
}

.active.menu-res-img svg {
  opacity: 1;
}

.download-space img {
  width: 1.66vw !important;
  height: 1.51041vw !important;
}

.menu-img svg {
  width: 1.604166vw !important;
  height: 1.604166vw !important;
}
.admin-nav-childs svg{
  width:1vw !important;
}

.lms-res-headerspace {
  position: relative;
}

.copy-clipboard {
  margin-top: 10px;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  border: 0.5px solid;
  border-radius: 5px;
  color: #444;
  border: 1px solid #CCC;
  background: #DDD;
  box-shadow: 0 0 5px -1px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  vertical-align: middle;
  padding: 5px;
  text-align: center;
}

.lms-menu-logo {
  display: none;
}

.reishauer {
  display: none;
}

.vr-toggle-row {
  display: flex;
  width: 100%;
  /*Optional*/
  table-layout: fixed;
  /*Optional*/
  color: var(--analytics-primary-color);
  font-family: var(--font-family);
  padding-top: 15px;
  padding-bottom: 8px;
  /* justify-content: center; */
  padding-top: 1.8vw;
  align-items: center;

}


.vr-toggle-column {
  /* display: table-cell;
  width: 45%; */
  padding-left: 10px;
  padding-right: 10px;
  font-size: 1.15vw !important;
  float: left;
  width: 45%;
}

.vr-info-para {
  color: var(--analytics-primary-color);
  font-weight: 600;
  font-size: 0.8vw !important;
}

.vr-info-para a {
  color: var(--analytics-not-started-color);
  font-weight: 600;
  font-size: 0.8vw !important;
}

.vr-info-para a:hover {
  text-decoration: underline;
}

.vr-toggle-column-sub {
  display: flex;
  padding-left: 10px;
  width: 45%;
  padding-right: 10px;
  font-size: 1.15vw !important;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 3vw;
  height: 1.2vw;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color .2s;
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 1vw;
  height: 1vw;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  /* box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29); */
}

.react-switch-checkbox:checked+.react-switch-label .react-switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.react-switch-checkbox:checked~.react-switch-label {
  background: var(--analytics-primary-color);
}

@media (max-width: 991px) {
  .reishauer {
    display: block;
    width: 67.9px;
    height: 23px;
    mix-blend-mode: darken;
  }

  .lms-menu-logo {
    display: block;
    width: 4%;
    /* margin-right: 40px; */
  }

  .row>* {
    margin: 0;
    padding: 0;
  }

  .lms-menu-logo img {
    width: 30px;
    height: 24px;
    cursor: pointer;
  }

  /* .lms-res-dashboard {
    width: 64%;
  } */

  .lms-res-dashboard h1 {
    position: fixed;
    font-size: 18px;
    top: 70px;
    left: 20px;
    background-color: var(--pri-bg-color);
    width: 95%;
    padding: 10px 0px;
  }

  /* .lms-res-icon {
    width: 20%;
  } */

  .high-responsive {
    top: 360px;
  }

  .low-responsive {
    top: 118px;
  }

  .lms-low-search-ul {
    top: 100px;
  }

}

.lms-res-icon {
  width: max-content;
}

.responsive-search {
  justify-content: space-between;
  width: 93.2%;
  z-index: 1;
  background-color: #FFFFFF;
  position: fixed;
  display: none;
}

@media (max-width:991px) {
  .responsive-search {
    display: flex;
  }

  .lms-search-width {
    display: none;
  }

  #grid-search {
    padding: 15px 50px;
    font-size: 18px;
  }

  #grid-search::placeholder {
    display: none !important;
  }

  .browse #grid-search {
    border: 1px solid #BCBCBC !important;
  }

  .lang-icon svg {
    width: 28px !important;
  }

  .lang-icon {
    width: auto !important;
  }

  .notes-tab-btn {
    margin-bottom: 30px;
  }
}

/* .lms-res-dashboard {
  width: 25%;
} */

.user-details {
  align-items: center;
  position: relative;
}

@media (max-width: 576px) {
  .lms-menu-logo {
    /* margin-right: 34px; */
    width: 10%;
  }

  .lms-videotabs-sec-r {
    width: 15% !important;
  }

  #header {
    width: 95.4%;
    padding: 12px 0px !important;
    justify-content: unset !important;
  }

  .user-details {
    display: none !important;
  }

  .high-responsive {
    width: 93%;
    top: 379px;
  }

  /* .lms-res-dashboard {
    width: 53%;
  } */

  /* .lms-res-icon {
    width: 48%;
  } */

  /* .lms-menu-logo img {
    width: 24px;
    height: 19px;
    margin-right: 34px;
  } */

  .reishauer {
    width: 71.2px;
    height: 24px;
  }
}

/* @media (max-width: 470px) {
  .lms-res-dashboard {
    width: 33%;
  }
} */

.lms-search {
  display: block;
  margin-right: 20px;
  cursor: pointer;
}

@media (max-width: 576px) {
  .lms-search {
    display: block;
    margin: 0px;
  }

  .lms-search img {
    width: 24px;
    height: 24px;
    margin-right: 24px;
  }
}

@media (max-width: 991px) {
  .page-container {
    margin-left: 0;
    width: 100%;
    padding: 20px;
  }

  .user-details {
    display: none !important;
  }

  .row {
    margin: unset;
  }

  .container,
  .container-fluid,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm {
    padding: unset;
    margin: unset;
  }

  .active-tab p {
    border-bottom: 2px var(--icons-color) solid;
    font-weight: 700;
  }

  .lms-res-sidespace a {
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .page-container {
    padding: 20px;
  }

  .lms-dashboard-form-r {
    width: 85% !important;
  }

}

.mobile-res-filter {
  display: none;
}

@media (max-width: 991px) {
  .lms-search-ul span {
    margin-right: 40px;
  }

  .mobile-res-filter {
    display: block;
  }

  .lms-high-search-ul {
    padding-top: 100px !important;
    margin-bottom: 20px;
  }

  .lms-videotabs-sec {
    width: 70px;
  }

  #sort-label {
    display: none;
  }

  .lms-videotabs-sec-r {
    border: 0.5px solid #A8B6C3;
    width: 10%;
    justify-content: center;
    height: 49px;
    display: flex;
  }

  .lms-dashboard-form input[type="text"] {
    border: 0;
  }

  .lms-videotabs-sec-r span {
    margin-left: 20px;
  }

  .lms-videotabs-sec span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.75px;
    color: var(--violet);
  }

  .lms-dashboard-form button {
    background: none;
    border: none;
    padding: 0;
  }

  .lms-dashboard-form-r {
    /* opacity: 0.5; */
    height: 49px;
    border-radius: 3px;
    border: 0.5px solid #A8B6C3;
    background-color: #FFFFFF;
    width: 90%;
  }

  .br-0 {
    border-right: 0;
  }

  .bl-0 {
    border-left: 0;
  }

  .lms-dashboard-form-r input {
    padding: 12px 54px 11px 10px;
  }

  ::placeholder {
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.66px;
    color: var(--pri-font-color);
  }
}

/***********************************************
                      spacings
  ************************************************/
.m-50 {
  margin-bottom: 2.604166vw;
}

@media (max-width: 991px) {
  .m-50 {
    margin-bottom: 48px;
  }
}

@media (max-width: 576px) {
  .m-50 {
    margin-bottom: 39px;
  }
}

.m-30 {
  margin-bottom: 1.5625vw;
}

.m-40 {
  margin-bottom: 40px;
}

/***********************************************
                      Menu Menu
  ************************************************/

/***********************************************
                      Header
  ************************************************/
#header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 90.5%;
  z-index: 10;
  /* height: 110px;   */
  padding: 1vw 0px 0.5vw 0px;
  background-color: var(--pri-bg-color);
}

#header .lms-search {
  display: none;
}

.lms-responsive-menu {
  display: none;
}

@media (max-width: 991px) {
  .lms-responsive-header {
    display: none !important;
  }

  .lms-responsive-menu {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 37px;
    display: none !important;
  }

  #header {
    width: 95%;
    padding: 15px 0px !important;
    justify-content: space-between !important;
  }

  .menu-search {
    margin-right: 24px;
    width: 24px;
    height: 24px;
  }

  .menu-bell {
    margin-right: 25px;
    width: 19px;
    height: 23px;
  }

  .menu-w1 {
    width: 50%;
  }

  .menu-w2 {
    width: 45%;
    text-align: end;
  }

  .menu-w3 {
    width: 5%;
  }

  .lms-menu-w1 {
    width: 6%;
  }

  .lms-menu-w1 img {
    width: 30px;
    height: 24px;
  }

  .lms-menu-w2 {
    width: 54%;
  }

  .lms-menu-w3 {
    width: 40%;
    text-align: end;
  }
}

@media (max-width: 576px) {
  .lms-menu-w1 img {
    width: 24px;
    height: 19px;
  }

  .reishauer-logo {
    width: 71.2px;
    height: 24px;
  }

  #header .lms-search {
    display: block;
  }

  .menu-res-img {
    padding: 10px 0px;
  }
}

.menu-res-img a {
  margin: 0px;
}

.page-title {
  letter-spacing: 1.22px;
  color: var(--pri-font-color);
  font-weight: bold;
  font-size: 1.35416vw;
}

.right-content {
  padding: 0px 1.5vw;
}

.search-icon img {
  width: 1.25vw;
  height: 1.25vw;
  opacity: 0.49;
  color: var(--pri-font-color);
}

.search-title {
  margin-left: 1.0416vw;
  font-size: 0.9375vw;
  opacity: 0.5;
}

#global-search-modal input[type="search"]::-webkit-search-cancel-button {
  display: none;
}

#global-search-modal input[type=search]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

#global-search-modal input[type=search]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

#global-search-input {
  padding: 0;
  background-color: unset;
  border: unset;
}

#notification svg {
  width: 1.09375vw;
  height: 1.354vw;
}

@media (max-width: 991px) {
  #notification svg {
    width: 21.2px;
    height: 24px;
  }
}

@media (max-width: 576px) {
  #notification svg {
    width: 19px;
    height: 23px;
  }
}

.right-content {
  align-items: center;
}

.right-content #notification {
  cursor: pointer;
}

.drop-down .profile {
  margin-right: 0.78125vw;
}

@media (max-width: 991px) {
  .drop-down .profile {
    margin-right: 13px;
  }
}

@media (max-width: 576px) {
  .profile-dropdown img {
    display: none;
  }
}

.profile-title {
  color: var(--pri-font-color);
  font-size: 0.93vw;
  margin: 0px 1vw;
  direction: rtl;
  font-weight: 600;
}
.profile-cont{
  position: relative;
  cursor: pointer;
}
.profile-cont .svg-con{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: linear-gradient(360deg,lightgray,#eeecec, transparent);
  width: 101%;
  height: 101%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
  opacity: 0;
}

.svg-con img{
  width: 50%;
  height: 50%;
}

.profile-cont:hover .svg-con{
  opacity: 1;
  transition: 0.5s;
}

.drop-down .profile-title {
  margin-right: 1.979vw;
  font-size: 0.9375vw;
}

.bootstrap-img {
  width: 80% !important;
  padding: 0px !important;
}

.bootstrap-profile {
  width: 60% !important;
  padding: 0px !important;
}

.badges-img {
  width: 50px !important;
  padding: 0px !important;
}

.lang-name-p {
  padding: 0px !important;
}

@media (max-width: 991px) {
  .drop-down .profile-title {
    font-size: 15px;
  }

  .profile-title {
    font-size: 16px;
  }
}

@media (max-width: 713px) {
  .drop-down .profile-title {
    font-size: 10px;
  }
}

@media (max-width: 576px) {
  .profile-title {
    display: none;
  }
}

#global-search-input {
  opacity: 0.5;
  font-size: 0.9375vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.85px;
  color: var(--pri-font-color);
}

.profile img {
  width: 2.5vw;
  height: 2.5vw;
}
.profile-badges{
  color: var(--icons-color);
  display: flex;
  gap: 5px;
  align-items: center;
}
.profile-badges img{
  width: 20px;
}

@media (max-width: 991px) {
  .profile img {
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 576px) {
  .profile img {
    width: 24px;
    height: 24px;
  }
}

.lms-search-responsive {
  display: none;
}
.lms-search-responsive a{
  margin: 0;
}

.lms-search-ul {
  position: fixed;
  z-index: 1;
  width: 93%;
  background-color: #F5F7F9;
}

@media (max-width: 991px) {
  .lms-search-responsive {
    display: block;
  }
  .lms-search-ul{
    padding-top:20px ;
  }

  .lms-responsive-menucontent {
    padding: 20px 30px 43px 20px;
    background-color: var(--light-white-color);
  }

  .lms-responsive-menucontent img {
    width: 93px;
    height: 31px;
  }

  .lms-responsive-menuul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }

  .lms-responsive-menuli img {
    width: 24px;
    height: 24px;
  }

  .lms-responsive-menuli {
    margin-bottom: 40px;
  }

  .lms-responsive-menuli span {
    margin-left: 28px;
  }

  .lms-res-logo {
    border-bottom: solid 1px var(--border-bottom-color);
    padding-bottom: 24.5px;
    margin-bottom: 69.5px;
  }

  .menuli-border {
    border-bottom: solid 1px var(--border-bottom-color);
    margin-bottom: 371.5px;
    padding-bottom: 40px;
  }

  .lms-responsive-menucontent-c1 {
    width: 13%;
  }

  .lms-responsive-menucontent-c2 {
    width: 87%;
  }

  .lms-responsive-menucontent-c2 img {
    width: 39px;
    height: 39px;
    margin-left: 11px;
    margin-right: 11px;
  }

  .lms-logout img {
    width: 26px;
    height: 23px;
  }

  .lms-logout span {
    opacity: 0.51;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    color: var(--pri-font-color);
    margin-left: 30px;
  }
}

/***********************************************
                   Profile Badge
  ***********************************************/

p {
  margin: 0;
  padding: 0;
}

h1 {
  margin: 0;
}

.lms-r-c2 {
  text-align: left;
}

.primary-button,
.cir-btn,
.download-btn {
  background: var(--tri-bg-color);
  border-radius: 6.13483px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.14vw;
  /* identical to box height */
  padding: 0.53vw 1.04vw;
  text-align: center;
  color: var(--pri-font-color);
  cursor: pointer;
}

@media (max-width: 991px) {

  .primary-button,
  .sec-btn {
    font-size: 16px;
    width: 150px !important;
    height: 50px !important;
    padding: 10px 20px;
  }

  .personal-info {
    width: 100% !important;
  }
}

.admin-button {
  border: 1px solid #D2DBE3;
  background-color: unset;
  padding: 0.52vw 1.04vw;
  margin-top: 0.2vw;
}

.blue-dbbar-con {
  position: fixed;
  z-index: 3;
  width: 90.1%;
  background-color: var(--pri-bg-color);
  padding-bottom: 0.8vw;
}

.lms-bluecontainer-sec {
  padding: 1vw;
  background: var(--icons-color);
  border-radius: 8px;
  /* margin-bottom: 1.5625vw; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.blue-con {
  width: 24%;
}

.blue-bar-br {
  border-right: 0.5px solid #FFFFFF;
}

.blue-bar-bl {
  border-left: 0.5px solid #FFFFFF;
}

.blue-bar-br {
  border-right: 0.5px solid #FFFFFF;
}

.blue-bar-bl {
  border-left: 0.5px solid #FFFFFF;
}

.blue-bar-h2{
  font-size: 1.875vw !important;
  color: #ffffff !important;
  font-weight: 700 !important;
}
@media (max-width: 991px) {
  .lms-bluecontainer-sec {
    padding: 18px 25px 17px 20px;
  }

  .blue-con {
    width: 50%;
    margin-bottom: 20px;
  }

  .blue-dbbar-con {
    width: 93%;
    margin-top: 90px !important;
  }

  .lms-m,
  .lms-r {
    padding: 0px 20px;
  }
  .blue-bar-h2{
    font-size: 18px !important;
  }
}

@media (max-width: 576px) {
  .lms-bluecontainer-sec {
    padding: 15px 15px 5px 21px;
    margin-bottom: 20px;
    border-radius: 0;
  }

  .personal-info {
    width: 100% !important;
  }

  .blue-bar-br {
    border: 0;
  }

}

/* changed by srikannth nakka

.lms-user-desc {
  width: 38% !important;
}



.lms-user-button {
  width: 20% !important;
}
*/
@media (max-width: 576px) {
  .lms-r-flex {
    flex-wrap: wrap;
  }
}

/* .lms-l {
  width: 40%;
} */

/* @media (max-width: 991px) {
  .lms-l {
    width: 50%;
  }
}

@media (max-width: 576px) {
  .lms-l {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: solid 0.5px var(--light-white-color);
  }
} */

/* .lms-m {
  width: 30%;
} */

/* @media (max-width: 991px) {
  .lms-m {
    width: 50%;
  }
} */

/* @media (max-width: 576px) {
  .lms-m {
    width: 100%;
    margin-bottom: 15px;
    border-bottom: solid 0.5px var(--light-white-color);
  }
}

.lms-r {
  width: 30%;
} */

/* @media (max-width: 991px) {
  .lms-r {
    display: none;
  }
}

@media (max-width: 576px) {
  .lms-r {
    width: 100%;
    display: block;
  }
} */
.blue-image1 img{
  border-radius: 3px;
  width: 4vw;
  height:4vw ;
}
.edit-profile-img img {
  width: 3.22vw;
  height: 3.22vw;
  border-radius: 50px;
}


.blue-image1 {
  width: 88%;
  display: flex;
}

.edit-profile-img {
  display: flex;
  width: 5vw;
}

.btn-edit-profile {
  width: 8.22vw;
}

.blue-profile-wd {
  width: 4.589vw;
}

@media (max-width: 991px) {

  .edit-profile-img img,
  .blue-image1 img {
    width: 80px;
    height: 80px;
    border-radius: 4px;
  }

  .blue-image1 canvas {
    width: 70px !important;
    height: 70px !important
  }

  .blue-profile-wd {
    width: 11.729vw;
    padding: 0 20px;
  }

  .edit-profile-img {
    width: 100px;
  }

  .blu-img-cont {
    width: 100px;
  }

  .userprofile {
    width: 90% !important;
  }
  .blue-image1{
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blue-image1 img {
    width: 70px;
    height: 70px;
  }
}

@media (max-width: 709px) {
  .blue-image1 img {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 640px) {
  .blue-image1 img {
    width: 70px;
    height: 70px;
  }

  .lms-bluecontainer-sec {
    display: block;
  }

  .blue-con {
    width: 100%;
    border: 0;
  }

  .lms-l {
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.22);
    padding: 0px 0px 20px 0px;
  }

  .lms-m,
  .lms-r {
    padding: 0px;
  }
}

@media (max-width: 576px) {
  .blue-image1 img {
    width: 68px;
    height: 68px;
  }
}

.profile-image {
  width: 5.895833vw;
  height: 5.895833vw;
  border-radius: 50%;
}

.btn-edit-close {
  width: 20px;
}

.blue-image-wd-badge {
  width: 3vw;
}

.blue-image2 img,
.blue-bar-progress {
  width: 100%;
}

.blue-image-wd{
  background-color: #0062B8;
  box-shadow: 9px 9px 28px 0px #0000002B;
  border-radius: 10px;
  width: 5.510vw;
  height:  3.94vw;
  display: grid;
  place-items: center;
}
.blue-image-wd img{
  width: 50%;
}

@media (max-width: 991px) {
  .blue-image-wd {
    width: 100px;
    height: 80px;
  }

  .profile-image {
    width: 100px;
    height: 100px;
    border-radius: 168px;

  }

  .btn-edit-close {
    width: 15px;
  }

  .blue-image-wd-badge {
    width: 90px;
  }
}

@media (max-width: 640px) {

  .high-responsive {
    top: 330px;
  }

  .blue-profile-wd {
    width: 90px;
    padding: 0;
  }

  .low-responsive {
    top: 115px;
  }

  .blue-image-wd,
  .blue-image-wd-badge {
    width: 100px;
    height: 70px;
  }

  .high-responsive {
    top: 600px;
  }

  .lms-high-search-ul {
    padding-top: 100px !important;
  }

}

@media (max-width: 576px) {

  .blue-image-wd-badge {
    width: 130px;
  }
}

@media (max-width: 346px) {
  .profile-image {
    width: 110px;
    height: 110px;
    border-radius: 110px;
  }

  .edit-del {
    margin-top: 10px !important;
  }
}

.blue-image-wd-2 {
  width: 5vw;
}

@media (max-width: 640px) {
  .blue-image2 img {
    width: 80%;
    height: 66px;
  }
}

.profile-heading {
  font-size: 1.25vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 124%;
  /*letter-spacing: 1.6px;*/
  color: var(--sec-font-color);
  margin-bottom: 0.4vw;
}

@media (max-width: 991px) {
  .profile-heading {
    font-size: 24px;
  }

  .blue-image-wd-2 {
    width: auto;
  }
}


.profile-para {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1vw;
}

.profile-para p {
  opacity: 0.7;
  font-size: 0.9vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.66px;
  color: var(--sec-font-color);
}

/* Changed by srikanth nakka
.lms-p-d {
  display: block;
  padding: 0;
}
*/

@media (max-width: 576px) {
  .lms-p-d {
    display: none;
  }
}

.lms-p-r {
  display: none;
}

@media (max-width: 576px) {
  .lms-p-r {
    display: block;
    max-width: 252px;
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .profile-para p {
    font-size: 12px;
  }
  .profile-para{
    padding: 0 20px;
  }
  .user-personal-details{
    padding: 20px !important;
  }
}

@media (max-width: 790px) {
  .profile-para p {
    font-size: 10px;
  }
}

progress#file {
  width: 100%;
}

.progress-statistics-text {
  opacity: 0.85;
  font-size: 0.9375vw;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: 0.85px;
  color: var(--sec-font-color);
  padding: 0;
  margin-bottom: 0.9vw;
}

@media (max-width: 991px) {
  .progress-statistics-text {
    font-size: 14px;
    margin-bottom: 10px;
  }
}

@media (max-width: 576px) {
  .blu-btn-cont {
    flex-direction: column;
    align-items: flex-start;
  }
}

.viewdetails-text {
  font-size: 0.525vw;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.83;
  letter-spacing: 0.56px;
  color: var(--sec-font-color);
  text-decoration: none;
  text-transform: uppercase;
}

@media (max-width: 991px) {
  .viewdetails-text {
    font-size: 12px;
  }
}

@media (max-width: 768px) {
  .viewdetails-text {
    font-size: 10px;
  }
}

.viewdetails-text svg {
  margin: 0px 1.0416vw;
  /* font-size: 1.0416vw; */
}

@media (max-width: 576px) {
  .viewdetails-text i {
    margin-left: 30px;
    font-size: 12px;
  }
}

/* Changed by srikanth nakka
.lms-l-c1 {
  margin-right: 1.04166vw;
}
*/

@media (max-width: 991px) {
  .lms-l-c1 {
    margin-right: 25px;
  }
}

@media (max-width: 640px) {
  .lms-l-c1 {
    margin-right: 15px;
  }
}

@media (max-width: 576px) {
  .lms-l-c1 {
    margin-right: 15px;
  }
}

.lms-m-c1 {
  margin-right: 1.04166vw;
}

@media (max-width: 991px) {
  .lms-m-c1 {
    margin-right: 18px;
  }
}

@media (max-width: 576px) {
  .lms-m-c1 {
    margin-right: 12px;
  }
}

.lms-r-c1 {
  margin-right: 1.04166vw;
}

@media (max-width: 576px) {
  .lms-r-c1 {
    margin-right: 12px;
  }
}

@media (max-width: 576px) {

  .lms-flex-m {
    margin-bottom: 15px;
  }
}

/* 
.lms-close-icon img {
  float: right;
} */

.lms-close-icon {
  align-self: flex-start;
  display: flex;
  justify-content: flex-end;
  width: 11%;
}

.lms-close-icon img {
  width: 0.677083vw;
  height: 0.677083vw;
  position: absolute;
  position: absolute;
}

@media (max-width: 991px) {
  .lms-close-icon img {
    width: 13px;
    height: 13px;
    top: 5%;
    right: 2%;
    top: 5%;
    right: 2%;
  }
}

/********************grid search***************/
#search,
#search-for-brows {
  background-color: var(--tri-bg-color);
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  position: relative;
  padding: 5px 0;
  border: 1px solid Var(--light-violet);
}

#search-for-brows {
  margin-bottom: 20px;
}
#search-for-brows::-webkit-scrollbar{
  display: none;
}

.search-marg {
  margin-bottom: 1vw;
}

@media (max-width: 991px) {
  .responsive-search-tabs {
    display: none;
  }

  .tabs-low-margin {
    margin-top: 60px !important;
  }

  .search-nav-bar-brows,
  .search-nav-bar {
    display: none;
  }

  #search-for-brows {
    margin-bottom: 0px;
  }

  .browse {
    display: block;
  }

  #search-for-brows {
    margin-top: 40px;
    background-color: transparent;
    align-items: flex-start;
    flex-direction: column;
    width: 97%;
    overflow: auto;
  }

  .right-icon {
    width: max-content !important;
    justify-content: flex-start !important;
    height: 40px !important;
  }

  #right-icon-right {
    margin-right: 60px;
  }

  #right-icon-left {
    margin-left: 60px;
  }

  #cards-grid-browse {
    padding-top: 20px !important;
  }

  .right-icon p {
    font-size: 16px !important;
    padding: 0 !important;
    opacity: 0.6;
  }

  .filter-sort {
    display: none !important;
  }

  .universal-search-sec {
    padding: 20px 20px 18.2px 20px;
    opacity: 0.63;
    border-bottom: solid 1px var(--search-border-color);
  }

  .lms-dashboard-form button {
    background: none;
    border: none;
    padding: 0;
  }

  .lms-dashboard-form input {
    border: none;
  }

  .lms-dashboard-form i {
    width: 1.25vw;
    height: 1.25vw;
    color: var(--light-violet);
    font-size: 0.9375vw;
  }

  .universal-search-w1 {
    width: 70%;
  }

  .universal-search-w2 {
    width: 30%;
  }

  .universal-search-close {
    text-align: end;
  }
}

.right-icon svg {
  width: 1.25vw;
  height: 1.25vw;
}

.right-icon p {
  font-size: 0.8333vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  padding: 0px 10px;
  text-transform: capitalize;
  color: var(--pri-font-color) !important;
}

.search-img {
  width: 100%;
  opacity: 0.76;
  position: relative;
}

.search-img img {
  width: 1.25vw;
  height: 1.25vw;
  margin: 0px 2vw;
  position: absolute;
}

.search-right .right-icon {
  /* Changed by sreekanth  opacity: 0.76; */
  border-left: solid 0.5px var(--border-left-color);
  height: 2.8125vw;
  width: 11.4055vw;
  text-align: center;
  justify-content: center;
  padding: 0 5px;
  position: relative;
}

.search-right {
  justify-content: flex-end !important;
}

.justify-end {
  justify-content: end;
}

.opc-dropdown {
  opacity: 1;
}

.right-icon-add-course {
  border: solid 0.5px var(--pri-font-color) !important;
  height: 3.8vw;
  width: 11.4055vw;
  justify-content: unset !important;
  padding: 0 5px;
  margin: 2px;
  position: relative;
  font-size: 0.8333vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: var(--violet);
  text-transform: capitalize;
}

.search-right .active {
  position: relative;
  opacity: 1;
  font-weight: 600;
}

.active p {
  font-weight: bold;
  opacity: 1;
  color: var(--icons-color) !important;
}

.search-right .active::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: -6px;
  border-bottom: 2.5px solid var(--icons-color);
}

@media (max-width:991px) {
  .search-right .active::after {
    width: 0%;
  }

  .browse .search-right {
    overflow: auto;
    justify-content: unset !important;
    padding: 0;
  }

  .search-img img {
    width: 24px;
    height: 24px;
    margin: 0px 20px;
  }

  .right-icon svg {
    width: 24px;
    height: 24px;
    margin: 10px;
  }

  .left-border {
    border-left: 0.5px solid #D2DBE3 !important;
  }

  .right-border {
    border: 0 !important;
    border-right: 0.5px solid #D2DBE3 !important;
  }

  .right-icon p {
    line-height: 185%;
  }
}

.search-right .right-icon:hover {
  opacity: 1;
  cursor: pointer;
}

.search-right .right-icon:hover p {
  font-weight: 700;
  color: var(--icons-color) !important;
}

.search-right .right-icon:nth-child(4):hover {
  opacity: 1;
}

@media (max-width:991px) {
  .search-right .right-icon {
    padding: 0px 30px !important;
  }

  .browse .right-icon {
    padding: 0px !important;
    border: unset;
  }
}

/* Changed by Srikanth nakka */

/* .right-icon svg {
  margin-right: 1.30208vw;
}

.lms-videodesktop-tabs {
  width: 50%;
}
*/

.lms-videodesktop-tabs-collection {
  width: 60%;
}

.lms-videodesktop-tabs-collection-video {
  width: 60%;
}

.viewdetails-disabled{
  pointer-events: none;
}

.cards-grid {
  border-radius: 8px;
  margin-bottom: 20px;
  /* overflow: auto;
  padding-top: 410px;
  height: calc(60vh - 100px); */
}

.tabs-low-margin {
  margin-top: 5.5vw;
}

.cards-low-margin {
  padding-top: 9vw;
}

#cards-grid .view-heading {
  padding: 0;
}

#cards-grid-browse {
  border-radius: 8px;
  margin-bottom: 20px;
  /* overflow: auto;
  height: calc(60vh - 100px); */
}

.table-collection-checkbox input{
  margin-left: 0 !important;
}

@media (max-width: 2530px) {

  .tabs-low-margin {
    margin-top: 5vw;
  }
}

.cards-grid-browse {
  border-radius: 8px;
  margin-bottom: 20px;
}

.card-flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.card-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.5vw;
}


@media (max-width: 576px) {
  .card-grid {
    flex-wrap: wrap;
  }
}

.card-space {
  width: 18.5%;
  margin-bottom: 2vw;
  position: relative;
  background-color: #ffffff;
  border-radius: 5%;
  cursor: pointer;
}
.card-space a{
  padding: 1vw;
  margin-bottom: 0;
  height: 100%;
}
.card-space img{
  border-radius: 5%;
}
.card-space:hover{
  box-shadow: 0px 4.2px 37.8px 0px #00000033;

}
.searched-course-grid .card-space{
  width: 19%;
}
.after-view-all .card-space{
  width: 18% !important;
}
@media (max-width: 991px) {
  .card-space {
    width: 31.8%;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .card-space {
    width: 49%;
  }

  .card-space:nth-child(4n + 2) {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  .card-space {
    margin-right: 0;
    width: 100%;
  }

  .card-space:nth-child(4n + 1) {
    margin-right: 0;
  }
}

.card-img{
  height: 8.85vw;
}
.left-arrow-ic{
  width: 0.6vw;
  cursor: pointer;
}

.card-img img {
  /* border-radius: 10px; */
  margin-bottom: 0.5vw;
  height: 100%;
  object-fit: cover;
}

.card-info {
  margin: 0.80833vw 0  0.40833vw 0 ;
}

.card-title,
.card-date {
  font-size: var(--card-title);
}

@media (max-width: 991px) {
  .card-title {
    font-size: 10px;
  }
  .left-arrow-ic{
    width: 10px;
    cursor: pointer;
  }

  .card-img{
    height: 150px;
  }

  .card-date {
    font-size: 14px;
  }

  .card-info {
    margin: 12px 0px;
  }

  .browse .lms-videodesktop-tabs {
    margin-top: 10px;
    width: 99%;
  }
}

@media (max-width: 576px) {
  .card-date {
    font-size: 12px;
  }
  .card-img{
    height:220px;
  }
}

.card-title {
  border: solid 1.1px var(--pri-font-color);
  padding: 0.364vw 0.8333vw 0.4166vw;
  border-radius: 2.2px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--pri-font-color);
  text-transform: uppercase;
}

@media (max-width: 576px) {
  .card-title {
    padding: 6px 7px 6px 8px;
  }
}

.card-date {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
}

.playlist-heading {
  font-size: 0.9375vw;
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: 0.75px;
  color: var(--pri-font-color);
  margin-bottom: 0.41666vw;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  width: 13vw;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 1vw;
}

@media (max-width: 991px) {
  .playlist-heading {
    font-size: 16px;
    padding: 0px 20px;
  }
}

@media (max-width: 768px) {
  .playlist-heading {
    font-size: 14px;
  }
}

.card-para {
  opacity: 0.8;
  font-size: var(--body-font);
  line-height: 1.43;
  letter-spacing: 0.66px;
  color: var(--pri-font-color);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.83vw;
  height: 3.7vw;
}
.card-info p {
  font-size: 0.78vw;
}

@media (max-width: 991px) {
  .card-para {
    font-size: 14px;
    margin-bottom: 40px;
    margin-top: 8px;
    font-weight: 400;
    line-height: 20px;
  }
  .card-info p {
    font-size:12px;
  }
}

@media (max-width: 576px) {
  .react-player {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.video-detail-wrap {
  justify-content: space-between !important;
  margin-top: 9rem;
}

@media (max-width: 991px) {
  .video-detail-wrap {
    flex-wrap: wrap;
    margin-top: 95px;
    margin-top: 95px;
  }
}

@media (max-width: 576px) {
  .video-detail-wrap {
    margin-top: 95px;
    margin-top: 95px;
  }
}

.lms-w1 {
  width: 62%;
  /* margin-right: 3.15104vw; */
}

.lms-video {
  position: relative;
  height: 100%;
  width: 100%;
}

.lms-video-size{
  width: 54.37vw;
  height: 30vw;
}
 @media (max-width:1400px) {
  .lms-video-size{
    width: 54.37vw;
    height: 30vw;
  }
 }

 @media (max-width:991px) {
  .lms-video-size{
    width: 100%;
    height:576px;
  }
 }

 @media (max-width:576px) {
  .lms-video-size{
    width: 100%;
    height:100%;
  }
  .resp-margin{
    margin-top: 0 !important;
  }
 }
.question-con .MuiTypography-root {
  color: var(--pri-font-color) !important;
  font-size: 0.9375vw;
}

.lms-video-vr-player {
  width: 100%;
  height: 100%;
}

#container {
  position: relative;
  /* Enable relative positioning for child elements */
  width: 100%;
  height: 100%;
}

#overlay-div {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10.3%;
  background-color: rgba(0, 0, 0, 0.831);
  color: white;
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.vr-seektime {
  align-self: center;
  float: left;
  padding-left: 1.2vh;
  font-size: 1.15vh;
  margin-right: 1.2vh;
}

.vr-videotime {
  align-self: center;
  float: left;
  font-size: 1.15vh;
  margin-left: 1.2vh;
}

.vr-left-section {
  float: left;
  display: flex;
  align-self: center;
}

.vr-left-section img {
  width: 3%;
  height: 3%;
  margin-left: 1.2vh;
  align-self: center;
}

.vr-right-section {
  float: right;
  justify-content: flex-end;
  display: flex;
  align-self: center;
  margin-right: 7vh;
  width: 36%;
  height: 42%;
}

.vr-seekbar-div {
  display: flex;
  width: 100%;
}

.vr-right-section img {
  width: 23%;
  margin-right: 1vh;
}

.input-range {
  position: absolute;
  bottom: 20px;
}

.group-error-msg {
  margin: 3vw 0.56vw;
  padding: 0.5vw;
  display: flex;
  justify-content: center;
  border-radius: 2vh;
  border: 1px solid #e2b1b1;
  background-color: #e2b1b1;
}

.group-error-msg p {
  font-weight: 600;
  font-size: 0.83vw;
  color: #222222;
}

@media (max-width: 991px) {
  .group-error-msg p {
    font-size: 14px;
  }
}




/* @media (min-width: 1024px) {
  #overlay-div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 14%;
    background-color: rgba(0, 0, 0, 0.831);
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .vr-right-section img {
    width: 23%;
    margin-right: 1vh;
  }

  .vr-right-section {
    float: right;
    justify-content: flex-end;
    display: flex;
    align-self: center;
    padding-right: 7vh;
    width: 59%;
    height: 42%;
  }
} */

@media (max-width: 1650px) {
  #overlay-div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 10.3%;
    background-color: rgba(0, 0, 0, 0.831);
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .vr-right-section img {
    width: 15%;
    margin-right: 1vh;
  }

  .vr-right-section {
    float: right;
    justify-content: flex-end;
    display: flex;
    align-self: center;
    /* margin-right: 10vh; */
    width: 58%;
    height: 42%;
  }
}

@media (max-width: 1500px) {
  #overlay-div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 12.5%;
    background-color: rgba(0, 0, 0, 0.831);
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .vr-right-section img {
    width: 23%;
    margin-right: 1vh;
  }

  .vr-right-section {
    float: right;
    justify-content: flex-end;
    display: flex;
    align-self: center;
    margin-right: 10vh;
    width: 58%;
    height: 42%;
  }
}


@media (max-width: 1350px) {
  #overlay-div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 14.3%;
    background-color: rgba(0, 0, 0, 0.831);
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .vr-right-section img {
    width: 23%;
    margin-right: 1vh;
  }

  .vr-right-section {
    float: right;
    justify-content: flex-end;
    display: flex;
    align-self: center;
    margin-right: 10vh;
    width: 58%;
    height: 42%;
  }
}

@media (max-width: 1065px) {
  #overlay-div {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16%;
    background-color: rgba(0, 0, 0, 0.831);
    color: white;
    text-align: center;
    display: flex;
    justify-content: space-between;
  }

  .vr-right-section img {
    width: 23%;
    margin-right: 1vh;
  }

  .vr-right-section {
    float: right;
    justify-content: flex-end;
    display: flex;
    align-self: center;
    /* padding-right: 10vh; */
    width: 70%;
    height: 42%;
  }
}

@media (max-width: 991px) {
  .lms-w1 {
    width: 100%;
    margin-right: 0;
  }
}

.lms-w2 {
  width: 35%;
  padding: 0px 1vw;
}

@media (max-width: 991px) {
  .lms-w2 {
    width: 100%;
    padding: 0px;
  }
}

@media (max-width: 991px) {
  .lms-slider-space {
    padding: 0px 32px;
  }

  .MuiTabs-scroller {
    height: 42px !important;
  }

  .resp-margin{
    margin-top: 270px;
  }

}

@media (max-width: 576px) {
  .lms-slider-space {
    padding: 0;
  }

  .MuiTabs-scroller {
    overflow: auto !important;
    height: 30px !important;
  }

  /* .lms-w2 {
    padding-right: 20px;
    padding-left: 20px;
  } */
}

#card-headingspace {
  padding: 2.3vw 0px 1.5416666vw 1.3vw;
  /* margin-bottom: 0; */
  font-weight: bold;
  line-height: normal;
  font-stretch: normal;
  font-style: normal;
}

@media (max-width: 991px) {
  #card-headingspace {
    padding-top: 0;
    padding-bottom: 20px;
    margin-top: 0;
  }
}

.prof-heading {
  margin-bottom: 20px;
}

.lms-carddate {
  color: var(--pri-font-color);
}

.lms-card-w {
  width: 100%;
}

.card-title-w {
  width: max-content;
  /* margin-bottom: 0.78125vw; */
}

@media (max-width: 991px) {
  .card-title-w {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .card-title {
    font-size: 12px;
    padding: 10px 20px;
  }

  .lms-card-w {
    margin-bottom: 50px;
  }
}

.card-heading-m {
  margin-bottom: 0;
  width: unset;
  font-size: 0.733vw !important;
}

@media (max-width: 991px) {
  .card-heading-m {
    font-size: 16px !important;
  }

  .card-title {
    font-size: 12px;
    padding: 5px 10px;
  }
}

@media (max-width: 824px) {
  .card-heading-m {
    font-size: 12px !important;
  }
}

@media (max-width: 576px) {
  .card-title-w {
    margin-top: 7px;
  }

  #card-headingspace {
    margin-bottom: 0;
    padding-left: 0;
  }
}

@media (max-width: 417px) {
  .card-heading-m {
    font-size: 12px !important;
  }
}

@media (max-width: 391px) {
  .card-heading-m {
    font-size: 12px !important;
  }
}

.lms-card-space {
  margin-right: 1.5625vw;
}

.save-btn-new {
  display: flex;
  align-items: center;
  border: 1px solid var(--pri-font-color);
  border-radius: 0.4166vw;
  width: 6.875vw;
  height: 2.239vw;
  justify-content: center;
  background-color: transparent;
}

.save-btn-new span {
  font-size: 0.7291vw;
  font-weight: 700;
  margin: 0px 5px;
}

.save-btn-new-saved {
  display: flex;
  align-items: center;
  border: 1px solid var(--icons-color);
  border-radius: 0.4166vw;
  width: 6.875vw;
  height: 2.239vw;
  justify-content: center;
  background-color: var(--icons-color);
}

.save-btn-new-saved span {
  font-size: 0.7291vw;
  font-weight: 700;
  margin: 0px 5px;
  color: var(--light-white-color);
}

.lms-texticon-w2 {
  width: 9.635vw;
  margin-top: 2vw;
}

.new-save-svg {
  width: 0.677vw;
  height: 0.88vw;
}

.share-icon {
  width: 2.2395vw;
  height: 2.2395vw;
}

.share-ic {
  padding: 0 1vw !important;
}

@media (max-width: 991px) {
  .lms-texticon-w2 svg {
    width: 43px;
    height: 43px;
  }

  .save-btn-new,
  .save-btn-new-saved {
    width: 132px;
    height: 43px;
    border-radius: 8px;
  }

  .new-save-svg {
    width: 20px !important;
    height: 20px !important;
  }

  .save-btn-new-saved span {
    font-size: 14px;
  }

  .lms-texticon-w2 {
    width: 200px;
  }

  .save-btn-new span {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .lms-texticon-w2 {
    width: 190px;
  }
}

.lms-profheading {
  font-size: 1.775vw;
  color: var(--pri-font-color);
  line-height: 124%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  /* letter-spacing: 1.69px; */
}

/*
.css-1ujnqem-MuiTabs-root{
  margin-top: 20px;
}
*/
@media (max-width: 991px) {
  .lms-profheading {
    font-size: 36px;
  }
}

@media (max-width: 615px) {
  .lms-profheading {
    font-size: 30px;
  }
}

@media (max-width: 576px) {
  .lms-profheading {
    font-size: 18px;
  }
}

.share-save-icon {
  text-align: end;
  display: flex;
  justify-content: space-between !important;
  align-items: center;
}


/* Changed by srikanth
.save-icon {
  margin-right: 1.30208vw;
}
*/

.share-icon,
.close-icon,
.save-icon,
.btn-view-certificate,
.download-btn,
.share-btn,
.user-close-icon,
.btn-edit-profile,
.btn-edit-close {
  cursor: pointer;
}

hr {
  margin-top: 1.5625vw;
  margin-bottom: 1.0833vw;
  color: var(--gray);
  opacity: 1;
}

@media (max-width: 576px) {
  hr {
    margin-top: 13.4px;
    margin-bottom: 20px;
  }
}

.lms-tabcard img {
  width: 3.1901vw;
  height: 3.1901vw;
}

@media (max-width: 991px) {
  .lms-tabcard img {
    width: 49px;
    height: 49px;
  }
}

.lms-tabcard {
  padding: 1.0416vw 0.520833vw 0.520833vw 0.78125vw;
  border-radius: 6px;
  border: solid 0.5px var(--gray);
  width: 31.2%;
  margin-right: 1.0416vw;
}

@media (max-width: 991px) {
  .lms-tabcard {
    padding: 20px 18px 10px 15px;
    margin-right: 20px;
    width: 23.3%;
  }
}

@media (max-width: 893px) {
  .lms-tabcard {
    margin-right: 11px;
    /* width:24% !important;  */
  }
}

.lms-tabcard:nth-child(3) {
  margin-right: 0;
}

@media (max-width: 991px) {
  .lms-tabcard:nth-child(4) {
    margin-right: 0;
  }

  .lms-tabcard:nth-child(3) {
    margin-right: 20px;
  }
}

@media (max-width: 893px) {
  .lms-tabcard:nth-child(3) {
    margin-right: 11px;
  }
}

@media (max-width: 576px) {
  .lms-tabcard:nth-child(2) {
    margin-right: 0;
  }

  .lms-tabcard:nth-child(4) {
    margin-right: 0;
  }
}

@media (max-width: 942px) {
  .lms-tabcard {
    width: 23.2%;
  }
}

@media (max-width: 768px) {
  .lms-tabcard {
    width: 31%;
  }
}

@media (max-width: 576px) {
  .lms-tabcard {
    width: 40%;
  }
}

@media (max-width: 479px) {
  .lms-tabcard {
    width: 43%;
  }
}

/*@media(max-width:448px){
  .lms-tabcard{
    width:43%;
  }
} */
@media (max-width: 448px) {
  .lms-tabcard {
    width: 47.5%;
  }
}

.lms-tabcardspace {
  margin-bottom: 1.0416vw;
  display: flex;
}

@media (max-width: 991px) {
  .lms-tabcardspace {
    margin-bottom: 17px;
  }
}

.lms-iconspace {
  margin-bottom: 1.3541vw;
}

.lms-wrap {
  flex-wrap: wrap;
}

.lms-datespace {
  margin-top: 1.7604vw;
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .lms-datespace {
    margin-top: 57px;
  }

}

.doc-button {
  padding: 0.2083vw 0.5729vw 0.3125vw;
  border-radius: 3px;
  background-color: var(--sec-bg-color);
  font-size: var(--card-title);
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  color: var(--sec-font-color);
  border: none;
}

@media (max-width: 991px) {
  .doc-button button {
    padding: 6px 11px 6px 11px;
    font-size: 12px;
  }
}

.lms-tabcontent {
  margin-top: 2.0833vw;
  color: var(--pri-font-color);
}

.video-de-docs,.video-upload-docs {
  display: flex;
  flex-wrap: wrap;
  gap:1.3vw;
}
.video-de-docs .MuiSvgIcon-fontSizeMedium{
  display: none !important;
}

.video-de-docs li,.video-upload-docs li {
  width: 30%;
  list-style-type: none;
}

.course-docs-tab li {
  width: 9vw !important;
}

.course-docs-tab .dropfile{
  width: 100% !important;
}

@media (max-width: 576px) {
  .lms-tabcontent {
    margin-top: 15px;
  }
}

.lms-tabcontent p {
  opacity: 0.5;
  font-size: var(--small-title);
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.75px;
  color: var(--pri-font-color);
  margin-bottom: 2vw;
}

@media (max-width: 991px) {
  .lms-tabcontent p {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .lms-tabcontent p {
    font-size: 12px;
  }
}

.lms-tablink {
  opacity: 0.5;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: var(--pri-font-color);
}

.react-multiple-carousel__arrow {
  background: none;
}

.react-multiple-carousel__arrow:hover {
  background: none;
}

.react-multiple-carousel__arrow::before {
  color: var(--slider-arrow-color) !important;
}

.react-multiple-carousel__arrow--left::before {
  right: 74px;
}

.react-multiple-carousel__arrow--right::before {
  right: -46px;
}

.react-tabs__tab {
  padding: 0 0 0.3125vw 0;
  /* width:7.4vw; */
  flex-wrap: wrap;
  color: var(--pri-font-color);
  font-size: 0.8333vw;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  opacity: 0.51;
  outline: unset !important;
  margin-bottom: 1vw;
}

.tabs-l-margin {
  margin-left: 2vw;
}

.tabs-r-margin {
  margin-right: 2vw;
}

.tabsStyling {
  font-size: 0.833vw;
  font-weight: 400;
  opacity: 0.5;
  cursor: pointer;
}


@media (max-width: 991px) {
  .react-tabs__tab {
    font-size: 16px;
    margin: 0 19px 0 0;
    width: unset;
  }

  .tabs-l-margin {
    margin-left: 40px;
  }

  .tabs-r-margin {
    margin-right: 40px;
  }

  .tabsStyling {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .react-tabs__tab {
    font-size: 16px;
    margin-right: 50px;
  }
}


.react-tabs__tab--selected {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none !important;
  font-weight: 700;
  opacity: 1;
  color: var(--pri-font-color);
}

.react-tabs__tab--selected:after {
  content: "";
  display: block;
  position: relative;
  width: 40%;
  border-bottom: 3px solid var(--pri-font-color);
  margin-top: 6px;
}

.react-tabs__tab-list {
  border-bottom: none;
  width: 50%;
  padding: 0 2vw;

}

.lms-tabs-sec .react-tabs__tab-list {
  width: 100%;
  padding: 0 !important;
  display: flex;
}

@media (max-width: 576px) {
  .react-tabs__tab-list {
    margin-bottom: 20px;
  }

  .lms-tabs-sec .react-tabs__tab-list {
    width: 100%;
    overflow: auto;
    display: flex;
  }
}

/************************************************************************************
                                Video slider
*************************************************************************************/
.videoslider-desktop {
  display: block;
}

.slick-next:before,
.slick-prev:before {
  color: var(--form-field-color);
}

@media (max-width: 576px) {
  .videoslider-desktop {
    display: none;
  }

  .slick-prev {
    left: -21px;
  }

  .slick-next {
    right: -16px;
  }
}

.videoslider-r {
  display: none;
}

@media (max-width: 576px) {
  .videoslider-r {
    display: block;
  }
}

@media (max-width: 576px) {
  .lms-video-r {
    flex-wrap: wrap;
  }
}

@media (max-width: 991px) {
  .react-player {
    height: 100%;
  }

  .responsive-share-save {
    flex-direction: column;
  }

  .lms-texticon-w1 {
    margin-bottom: 25px;
  }

  .res-catg-marg {
    margin: 0;
  }
}

/* @media (max-width: 576px) {
  .sharesave-sec {
    margin-bottom: 15px;
  }
} */

/**********************************************************************
       Drop downs
***********************************************************************/
.dropdown {
  position: relative;
  padding: 10px;
  cursor: pointer;
}

.dropdown:hover .dropdown-content {
  display: block;
  position: absolute;
  z-index: 100;
  width: max-content;
  border-radius: 6px;
  box-shadow: 0 10px 38px 0 rgba(200, 200, 200, 0.5);
  background-color: var(--tri-bg-color);
  top: 2.08vw;
}

@media (max-width:991px) {
  .dropdown:hover .dropdown-content {
    top: 30px;
  }
}

.dropdown:hover .rtl-drop-down {
  left: 0px !important;
}

.dropdown:hover .ltr-drop-down {
  right: 0px !important;
}

.collection-tabs svg {
  width: 2.75vw;
  height: 1.25vw;
}

.border-for-collection-tabs {
  height: 2.5vw;
  display: flex;
  border-left: 0.5px solid #A8B6C3;
  margin-top: 0.5166vw;
  opacity: 0.76;
}

.form-field {
  padding: 20px;
  border-bottom: 1 px solid var(--form-field-color);
  display: grid;
  text-align: left;
}

.form-field-course1 {
  padding: 20px;
  border-bottom: 1 px solid var(--form-field-color);
  text-align: left;
}

.form-field-course2 {
  padding: 0 20px 20px 20px;
  border-bottom: 1 px solid var(--form-field-color);
  text-align: left;
}

.course-drop-btn {
  background-color: var(--pri-bg-color);
  border: 0.2px solid #043677;
  border-radius: 5px;
  width: 200px;
  padding: 20px;
  font-size: large;
  color: var(--sec-bg-color);
}

.lms-responsive-dashboard h1 {
  display: none;
}

@media (max-width: 991px) {
  .lms-responsive-dashboard h1 {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.85px;
    color: var(--pri-font-color);
    display: block;
    margin-bottom: 13px;
  }
}

@media (max-width: 576px) {
  .lms-responsive-dashboard h1 {
    margin-bottom: 37px;
  }
}

.lang-icon {
  cursor: pointer;
  width: 1.691vw;
  height: 1.40625vw;
}

.lang-icon svg {
  width: 90%;
}

.profile-drop-container button {
  cursor: pointer;
}

.dropdown-content label {
  opacity: 1;
  font-size: 0.78125vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  color: var(--pri-font-color);
}

#filter {
  padding: 0.65vw 1.17vw 0.84vw 0.9765vw;
  border-radius: 3px;
  border: solid 1px var(--filter-border-color);
  font-size: 0.9114vw;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.66px;
  color: var(--pri-font-color);
  margin-top: 0.846vw;
  width: 15.36vw;
  /* height: 2.669vw; */
}

@media (max-width: 991px) {
  .dashboard-bottomspace h1 {
    margin-bottom: 18px !important;
  }

  .dropdown-content label {
    font-size: 14px;
  }

  #filter {
    width: max-content;
    padding: 7px 10px;
    margin-top: 10px;
  }
}

.lms-first-slider {
  display: block;
  margin-left: 20px;
}

@media (max-width: 991px) {
  .lms-first-slider {
    display: none;
  }
}

.lms-second-slider {
  display: none;
}

@media (max-width: 991px) {
  .lms-second-slider {
    display: block;
    margin-top: 100px;
  }

  .vd-heading {
    display: none !important;
  }
}

@media (max-width: 991px) {
  .similarvideos {
    display: block;
    padding-top: 0;
    padding-bottom: 20px;
    font-size: 16px !important;
  }
}

@media (max-width: 576px) {
  .similarvideos {
    font-size: 18px !important;
  }
}

.next_arrow_img {
  width: 12px;
  height: 20px;
}

.previous_arrow_img {
  width: 12px;
  height: 20px;
}

/********************************************************
                       No data avaliable
********************************************************/
#no-result {
  display: block;
  text-align: center;
  width: 100%;
  padding: 3.64vw 0;
  border: solid 1px var(--border-color);
  background-color: var(--tri-bg-color);
  margin: 2.25vw 0;
}

#no-result h1, .no-content-message h2 {
  color: var(--pri-font-color);
  font-weight: 600;
  font-stretch: normal;
  font-size: 2.08vw;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  margin-bottom: 5px;
}

.no-content-message h2{
  font-size: 1.35416vw;
  padding-top: 1vw;
}

.no-content-message{
  width: 100%;
  text-align: center;
}

#no-result-saved-item {
  display: block;
  text-align: center;
  width: 100%;
  padding: 3.64vw 0;
  /* border: solid 1px var(--border-color);
  background-color: var(--tri-bg-color); */
  margin-bottom: 2.60vw;
}
.no-cirtificates{
  height: 30vh;
  margin-top: 10rem;
  display: flex !important;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border: 1px solid #000;
  width: 99% !important;
}

#no-result-saved-item h1 {
  color: var(--pri-font-color);
  font-weight: 600;
  font-stretch: normal;
  font-size: 2.08vw;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0px;
  margin-bottom: 5px;
}

#no-result-saved-item p {
  font-size: 1.04vw;
}

/*
.lms-res-sidespace{
   margin-top:15%;
}
*/

@media (max-width:991px) {
  #no-result-saved-item h1 {
    font-size: 30px;
  }

  #no-result-saved-item p {
    font-size: 18px;
  }

  #no-result-saved-item {
    margin-bottom: 70px;
  }
}

.user-courses-con {
  margin-top: 16vw;
}

.search-nav-bar,
.search-nav-bar-brows {
  position: fixed;
  z-index: 1;
  width: 90.2%;
  background: var(--pri-bg-color);
  top: 0;
}

.search-nav-bar {
  padding-top: 35px;
}

.tabs-high-margin {
  padding-top: 11.5vw;
}

.search-nav-bar-brows {
  padding-top: 8.9rem;
}

@media (max-width: 1800px) {
  .tabs-high-margin {
    padding-top: 11.8vw;
  }
  .mt-50{
    margin-top: 8rem;
  }

  .tabs-low-margin {
    margin-top: 4.8vw;
  }

  .search-nav-bar-brows {
    padding-top: 9.3rem;
  }

}

@media (max-width: 1700px) {
  .tabs-high-margin {
    padding-top: 12vw;
  }

  .cards-low-margin {
    padding-top: 9.2vw;
  }

  .search-nav-bar-brows {
    padding-top: 8.3rem;
  }

  .mt-50 {
    margin-top: 7.4rem;
  }
}

@media (max-width: 1500px) {
  .tabs-high-margin {
    padding-top: 12vw;
  }


  .tabs-low-margin {
    margin-top: 5.3vw;
  }

  .search-nav-bar-brows {
    padding-top: 7.6rem;
  }
  .mt-50 {
    margin-top: 7.2rem;
  }
}

@media (max-width: 1400px) {
  .tabs-high-margin {
    padding-top: 12vw;
  }

  .cards-low-margin {
    padding-top: 9.8vw;
  }


  .mt-50 {
    margin-top: 6.7rem;
  }

}

@media (max-width: 1300px) {
  .tabs-high-margin {
    padding-top: 13.5vw;
  }

  .tabs-low-margin {
    margin-top: 6vw;
  }
}

@media (max-width: 1200px) {
  .tabs-high-margin {
    padding-top: 14.5vw;
  }

  .cards-low-margin {
    padding-top: 11vw;
  }


  .tabs-low-margin {
    margin-top: 6vw;
  }
}

@media (max-width: 1080px) {
  .tabs-high-margin {
    padding-top: 13vw;
  }
}

.res-close-icon {
  display: none;
}

@media (max-width: 991px) {
  .cards-low-margin {
    padding-top: 140px;
  }

  .tabs-high-margin {
    padding-top: 320px;
  }

  .res-close-icon {
    display: flex;
    justify-content: end;
  }

  .search-nav-bar {
    width: 93%;
  }
}

@media (max-width: 640px) {
  .tabs-high-margin {
    padding-top: 500px;
  }
}

@media (max-width: 576px) {
  /* .lms-res-sidespace {
    margin-left: 20px;
    margin-right: 20px;
  } */

  /* .lms-res-headerspace {
    margin: 20px 20px 0 20px;
  } */
  .col-xs-12 {
    width: 100%;
  }

  .cards-low-margin {
    padding-top: 160px;
  }

}



/************************************************************************************
                                playlist(courseDetailPage)
*************************************************************************************/
ul a {
  display: block;
  margin-bottom: 0.78125vw;
}

@media (max-width: 991px) {
  ul a {
    margin-bottom: 20px;
  }


  .search-nav-bar-brows {
    padding-top: 60px;
    width: 95%;
  }
}

.playlistvideo {
  border-bottom: solid 0.5px var(--gray);
  padding: 0.68vw;
  list-style-type: none;
}

.playlistvideo_disabled {
  border-bottom: solid 0.5px var(--gray);
  padding: 0.68vw;
  background-color: #cccccc;
}

@media (max-width: 991px) {
  .playlistvideo {
    padding: 10px;
  }
}

@media (max-width: 576px) {
  .playlistvideo {
    padding: 10px;
  }

  .language-cont {
    flex-direction: column;
  }

  .languge-border {
    border: none !important;
  }
}

.language-con {
  background-color: var(--icons-color);
  border-radius: 50%;
  height: 2.04vw;
  width: 2.04vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language-con p {
  color: #ffffff;
  text-transform: capitalize;
  padding: 0 !important;
}

.lms-playlist-video img {
  width: 7.39vw;
  height: 4.16vw;
  border-radius: 0.328125vw;
  overflow: hidden;
}

.lms-playlist-video img.playlist-quiz-img {
  width: unset;
  height: unset;
}

.lms-play svg {
  width: 1.66vw;
}

@media (max-width: 991px) {
  .lms-playlist-video img {
    width: 100%;
    height: 100%;
  }

  .lms-play {
    text-align: center !important;
  }

  .lms-play svg {
    width: 30px;
  }
}

/* Changed by sreekanth

.lms-playlistvideo-content {
  margin-left: 1.5625vw;
}

@media (max-width: 991px) {
  .lms-playlistvideo-content {
    margin-left: 1.7vw;
  }
}

@media (max-width: 576px) {
  .lms-playlistvideo-content {
    margin-left: 20px;
  }
}

*/

img {
  width: 100%;
}

.lms-playicon p {
  margin-top: 5px;
}

.card-heading {
  font-size: 0.9375vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: 0.85px;
  color: var(--pri-font-color);
  margin: 0.2vw 0;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
  line-clamp:2;
  height: 2.5vw;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.documents .card-heading{
  word-wrap: break-word;
  height: 2vw;
}

@media (max-width: 991px) {
  .card-heading {
    font-size: 16px;
    height: auto;
  }
  .documents .card-heading{
    height: 30px;
  }
  .course-docs-tab li{
    width: 160px !important;
  }
}

@media (max-width: 576px) {
  .card-heading {
    font-size: 16px !important;
    height: auto;
    line-height: 21px;
  }
}

@media (max-width: 991px) {
  .playlist-heading {
    margin-top: 0;
    margin-bottom: 10px;
    width: 200px;
    -webkit-line-clamp: 1;
  }
}

@media (max-width: 768px) {
  .playlist-heading {
    margin-bottom: 4px;
  }
}

@media (max-width: 410px) {
  .playlist-heading {
    width: 120px;
  }
}

.lms-playlist-content p {
  font-size: 0.729vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: 0.66px;
  color: #979797;
  padding-top: 0.4166vw;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0px 1vw;
}

@media (max-width: 991px) {
  .lms-playlist-content p {
    font-size: 16px;
    width: unset;
    padding: 0px 20px;
  }
}

@media (max-width: 768px) {
  .lms-playlist-content p {
    font-size: 14px;
  }
}

@media (max-width: 576px) {
  .lms-playlist-content p {
    display: none;
  }
}

@media (max-width: 991px) {
  .lms-playlist-video {
    width: 17.5%;
  }
}

@media (max-width: 576px) {
  .lms-playlist-video {
    width: auto;
  }
}

.lms-playlist-content {
  width: 100%;
}

@media (max-width: 991px) {
  .lms-playlist-content {
    width: 67%;
  }
}

@media (max-width: 576px) {
  .lms-playlist-content {
    width: 60%;
  }
}

.lms-playicon {
  width: 18%;
}

@media (max-width: 991px) {
  .lms-playicon {
    width: 15.5%;
  }
}

@media (max-width: 991px) {
  .playlist-flex {
    align-items: center;
  }
}

.lms-play {
  text-align: end;
  /* Changed by sreekaanth 
  margin-left: 3.6979vw;
*/
}

.lms-play img {
  width: 1.66vw;
  height: 1.66vw;
}

@media (max-width: 991px) {
  .lms-play img {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 576px) {
  .lms-play img {
    display: none;
  }
}

/*----------admin-dashboard------*/

.admin-navbar {
  width: 100%;
  margin-top: 10vw;
}

@media (max-width: 1600px) {
  .admin-navbar {
    margin-top: 11vw;
  }
}

@media (max-width: 1300px) {
  .admin-navbar {
    margin-top: 11vw;
  }
}

.MuiAppBar-colorPrimary {
  background-color: var(--light-white-color) !important;
  color: var(--pri-font-color) !important;
}

.MuiTab-wrapper {
  flex-direction: row !important;
}

.video-tab-con {
  width: 100%;
  margin-top: 9rem;
}
.analytics-admin-space{
  margin-top: 5.5vw;
  margin-bottom: 2vw;
}
.video-tab-con-left {
  width: 50%;
  margin-top: 10px;
}

/* This is duplicate // srikanth nakka
.video-tab-con-right {
  width: 45%;
}
*/
.video-tab-con-right h4 {
  font-size: 1.04vw;
}

.video-input input[type="text"] {
  min-width: 30vw;
  border: none;
  background-color: transparent;
  font-size: 0.83vw;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: var(--pri-font-color);
  opacity: 0.5;
  padding-left: 10px;
}

.video-upload p {
  font-size: 0.833vw;
}

.upload-file {
  font-size: 1.35vw !important;
  font-weight: bold;
  font-family: var(--font-family);
  color: var(--pri-font-color);
  padding-bottom: 5px;
}

.upload-icon {
  width: 4vw;
}

.upload-btn-width {
  width: 10.5vw;
}

@media (max-width:991px) {
  .upload-btn-width {
    width: 140px;
  }
}

/* Changed by sreekanth it is duplicate

.video-upload-con {
  width: 100%;
}

.video-upload {
   border:1.2px #819abb  dashed;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='CornflowerBlue' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  align-items: center;
  text-align: center;
  vertical-align: middle;
  padding: 100px 150px;
  border-radius: 16px;
}

.video-upload img {
  width: 50px;
  height: 50px;
}

.video-upload p {
  align-items: center;
  text-align: center;
}
*/

a-scene {
  display: block;
  position: relative;
  height: 100%;
  width: 100%;
}

.upload-status {
  padding: 10px 20px;
  border-radius: 16px;
  min-height: 150px;
  border: solid 1px var(--light-violet);
  align-items: center;
  display: flex;
}

.video-thumb {
  width: 90%;
  /* width:140px;
height:80px; */
}

.thumb-upload {
  padding: 1.7vw;
}

.video-thumb-sec {
  width: 35%;
}

.upload-alert {
  padding: 10px 20px;
  border-radius: 9px;
  border: solid 1px #39b75c;
  background-color: var(--pri-bg-color);
}

.after-upload-details {
  width: 100%;
}

.margin-left {
  margin-left: 20px;
}

.lms-tab-sec li:first-child {
  margin: 0px !important;
}

.video-details {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.video-details li {
  list-style-type: none;
}

.se-btn-module:last-child {
  display: none !important;
}

.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  height: 10vw !important;
}

.sun-editor svg {
  width: 0.83vw !important;
  height: 0.83vw !important;
}

.sun-editor .se-btn {
  width: 1.77vw !important;
  height: 1.77vw !important;
  margin: 0 !important;
}

.video-button {
  width: 110px;
  align-items: center;
  justify-content: space-between;

}

@media (max-width:991px) {
  .sun-editor svg {
    width: 16px !important;
    height: 16px !important;
  }

  .sun-editor .se-btn {
    width: 38px !important;
    height: 38px !important;
    margin: 0 !important;
  }
}

.courses-details .row {
  height: auto !important;
}

.after-upload-suc {
  width: 100%;
  border: 1px solid var(--black-color);
  background-color: var(--tri-bg-color);
  padding: 10px 20px;
}

.video-desc {
  width: 100%;
}

/*--------admin Collection---------*/
/* #admin-top-navbar{
  /* background-color: #ffffff; 
  padding: 10px 0px 10px 2.02vw;
  } */
/* .admin-navbar{
    
    opacity: 0.76;
  } */
.admin-navbar-tabs {
  border-right: 1px solid var(--light-violet);
  padding: 0.52vw;
  width: 9vw;
  display: flex;
  align-items: center;
}

/*  // srikanth
.admin-navbar-tabs:hover{
  border-bottom: 2px solid var(--icons-color);
}
*/

.admin-navbar-tabs:hover p {
  font-weight: 700;
}

.admin-role {
  width: 90px;
  align-self: center;
}

.admin-role select {
  padding: unset !important;
  border: unset !important;
}

/*----------admin collection search---*/
.search-filter-con {
  border: 1px solid Var(--light-violet);
  padding: 5px 0;
}

.table-text-align {
  text-align: unset !important;
}

.sort-tab {
  border-right: none;
}

.sort-tab svg {
  width: 1.30vw;
  margin: 0px 1vw;
}

/*----------overrides-------*/
.css-19kzrtu {
  padding: 24px 0 !important;
}

.dropdown-toggle {
  display: flex !important;
  align-items: center;
}

.dropdown-toggle::after {
  color: black;
  opacity: 0.7;
  transform: rotate(0deg);
  transition: .3s;
  border-top: 0.5em solid;
  border-right: 0.5em solid transparent;
  border-bottom: 0;
  border-left: 0.5em solid transparent;
}

.lang-dpdw:hover .dropdown-toggle::after {
  transform: rotate(180deg);
}

.row {
  /* padding-left: unset !important; */
  height: 100vh;
}

.infinite-scroll,
.infinite-scroll-component {
  overflow: hidden;
}

.blue-dbbar-con .row {
  height: unset !important;
  overflow: auto;
}

.edit-user-profile-con .row {
  height: unset !important;
}

.collection-tabs,
.profile-tabs {
  width: max-content !important;
  display: flex !important;
  color: var(--pri-font-color) !important;
  flex-direction: row;
  align-items: center;
  padding: 0 0.5vw !important;
  margin: 0px 1.3vw !important;
  font-family: var(--font-family) !important;
  min-height: 3vw !important;
  text-transform: none !important;
  font-size: 0.93vw !important;
  letter-spacing: 0.75px !important;
}

.collection-bg {
  background-color: var(--tri-bg-color);
  border-bottom: 1px solid;
  border: 0.5px solid #D2DBE3;
  min-height: auto !important;
}

.css-wbttf5-MuiStack-root {
  align-items: center;
}

.col-tab .MuiTabs-flexContainer {
  height: 3.5vw !important;
}

@media (max-width:1400px) {

  /* .collection-tabs, .profile-tabs {
    width: 15vw !important;
  } */
  .admin-navbar-tabs {
    width: 11vw;
  }

}

@media (max-width:1200px) {

  /* .collection-tabs, .profile-tabs{
    width: 17vw !important;
  } */
  .admin-navbar-tabs {
    width: 12vw;
  }

}

@media (max-width:576px) {
  .col-tab .MuiTabs-flexContainer {
    width: 75% !important;
    overflow: scroll !important;
  }
}


.css-1e2bxag-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainerContent {
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-gl260s-MuiDataGrid-columnHeadersInner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -webkit-box-align: flex-start;
  -ms-flex-align: flex-start;
  align-items: flex-start;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.css-10dfkli-MuiTableCell-root {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.css-1jbbcbn-MuiDataGrid-columnHeaderTitle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 500;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  overflow: unset !important;
}

.vertical-form-controll {
  background-color: var(--tri-bg-color);
  width: 16.770vw !important;
}

.css-13b5x29-MuiButtonBase-root-MuiTab-root>* {
  margin-bottom: -8px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
  text-transform: none !important;
  font-size: 0.93vw !important;
  letter-spacing: 0.75px !important;
  opacity: 0.6;
}

@media (max-width:991px) {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-size: 16px !important;
    opacity: 0.5 !important;
    min-height: auto !important;
  }

  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    opacity: 1 !important;
  }

  .profi-left-mar {
    margin: 0 50px 0 0 !important;
    padding-bottom: 10px;
  }

  .profi-right-mar {
    margin: 0 0 0 50px !important;
    padding-left: 10px !important;
  }
}

.col-tab-mar {
  margin-bottom: 1px !important;
}

.admin-create-btn1:hover .admin-button {
  border: 1px solid var(--pri-font-color);
  font-weight: 700;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  font-weight: 700 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.94px;
  color: var(--pri-font-color);
}

.css-55vwbm-MuiTimeline-root {
  padding: 0 !important;
}

.css-1ami7lv-MuiTimelineConnector-root {
  padding-left: 0 !important;
}

.css-13b5x29-MuiButtonBase-root-MuiTab-root.Mui-selected svg path {
  color: var(--sec-bg-color) !important;
  fill: var(--sec-bg-color);
  font-weight: 700;
  border-color: var(--sec-bg-color) !important;
}

.css-13b5x29-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--sec-bg-color) !important;

}

.collection-tabs.Mui-selected,
.profile-tabs.Mui-selected,
.global-search-tab.Mui-selected {
  font-weight: 700 !important;
  color: var(--icons-color) !important;
  opacity: 1;
}

@media (max-width:991px) {
  .profile-tabs {
    align-items: flex-start !important;
  }
}

.collection-tabs:hover {
  font-weight: 700 !important;
  color: var(--icons-color) !important;
}

.MuiDataGrid-main img {
  width: 30px;
  height: 30px;
}

button#simple {
  border-right: 1px solid var(--light-violet) !important;
}

/* Changed by sreekanth nakka
 
.MuiIconButton-root {
  padding: 12px 0 !important;
}
*/
svg.MuiSvgIcon-root.MuiDataGrid-iconSeparator {
  visibility: hidden !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeader--moving {
  background-color: unset !important;
}

/* .MuiDataGrid-cell.MuiDataGrid-cell--withRenderer.MuiDataGrid-cell--textLeft {
  padding-top: 20px;
} */

.MuiDataGrid-root .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textCenter.MuiDataGrid-cell--editing {
  justify-content: flex-start !important;
}

.MuiIconButton-label {
  padding-right: 26px !important;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border: unset !important;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  border: unset !important;
}

.MuiDataGrid-root .MuiDataGrid-cell {
  border: unset !important;
}

.MuiDataGrid-root {
  border: unset !important;
}

.MuiDataGrid-root .MuiDataGrid-columnsContainer {
  color: var(--light-violet) !important;
}

.MuiCheckbox-root {
  color: var(--icons-color) !important;
}

.MuiDataGrid-root {
  color: var(--sec-bg-color) !important;
  font-family: var(--font-family) !important;
  font-weight: 500 !important;
}

/*----------overrides end-------*/
/*#colle-grid {
  margin-top: 20px;
}*/

#colle-grid {
  width: 98.2%;
}
#colle-grid table span{
  word-break: break-word;
}
.menu-img a.active {
  opacity: 1;
}

/* li:active svg path{
  fill:red
} */
#colle-grid select {
  background-color: unset;
  color: var(--pri-font-color) !important;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 0.833vw;
}

#colle-grid input::placeholder {
  color: #819ABB;
}

#colle-grid span,
#colle-grid div,
#colle-grid td,
#colle-grid th,
#colle-grid p {
  font-size: 0.84vw !important;
}

#colle-grid td {
  padding: 0.83vw !important;
}

.MuiCheckbox-root {
  padding: 0.52vw !important;
}

.video-tab-con {
  width: 100%;
}

.video-tab-con-left {
  width: 50%;
}

.video-tab-con-right {
  width: 47%;
}

.video-input {
  border-bottom: 1px solid var(--light-violet);
  margin-bottom: 1.2vw;
}

#points-for-the-Video {
  border: none !important;
}

.browse {
  color: var(--browse);
}

/* .upload-file{
    font-size: 26px;
    font-weight: bold;
  } */
.video-upload-con {
  width: 100%;
}

.videoup-close-icon {
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 1.5vw 1.5vw 0px 1.5vw;

}

.popover-width {
  width: 22vw !important;
  margin-top: 0.6vw !important;
}

.videoup-close-icon .close-icon {
  width: 13px;
  height: 13px;
}

.pause-btn {
  margin-right: 10px;
}

.dropzone {
  padding: 4.1vw 3.8vw;
}

.video-upload {
  border: 2px var(--pri-font-color) dashed;
  /* background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='CornflowerBlue' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");  */
  align-items: center;
  text-align: center;
  vertical-align: middle;
  border-radius: 16px;
  padding: 2.1vw 2.8vw;
}

.video-upload img {
  width: 50px;
  height: 50px;
}

.video-upload p {
  align-items: center;
  text-align: center;
}



.upload-ststus-details {
  width: 100%;
  align-items: center;
}

/* Duplicate

.video-thumb {
  width: 90%;
  width:140px;
  height:80px;
}

.video-thumb-sec {
  width: 35%;
}
*/

.upload-alert {
  padding: 10px 20px;
  border-radius: 9px;
  border: solid 1px #39b75c;
  background-color: var(--pri-bg-color);
}

.after-upload-details {
  width: 100%;
}


.after-upload-suc {
  width: 100%;
  border: 1px solid var(--black-color);
  background-color: var(--tri-bg-color);
  padding: 10px 20px;
}


/* .video-title{
  width:80%;
  } */
/* .video-heading{
    font-size: 20px;
    font-weight: 700;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: 0.94px;
    color:var(--pri-font-color);
    border-bottom: 1px solid  var(--light-blue);
  } */
.video-title {
  border-bottom: 1px solid var(--light-violet);
  padding-bottom: 20px;
}

#video-descripation {
  width: 100%;
  border-radius: 6px;
  padding: 10px;
  background-color: unset;
  color: var(--pri-font-color);
  margin-top: 10px;
  resize: none;
  font-weight: 400;
}

#video-descripation:focus {
  outline: var(--sec-bg-color);
}

.drappedfile {
  width: 70%;
}

.dropfile-sec {
  text-align: left !important;
  padding: 18px 11px !important;
}

.dropfile label {
  font-size: 0.65vw !important;
}

.drop-btn {
  background-color: var(--sec-bg-color);
  color: var(--sec-font-color);
  padding: 2px;
}

.dropfile {
  width: 9.37vw;
  padding: 0.76vw 0.57vw 0.57vw;
  border-radius: 6px;
  border: solid 0.5px var(--gray) !important;
  text-align: center;
}

.dropfile .pri-button {
  padding: 0.5vw 1vw;
  margin-top: 1vw;
}

.file-marg-l {
  margin-left: 1.04vw;
}

.file-marg-r {
  margin-right: 1.04vw;
}

.dropfile p {
  padding: 0px;
  margin-top: 0.5vw;
}

.drop-text {
  font-size: 0.65vw !important;
}


.upload-status h4 {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.66px;
  color: var(--pri-font-color);
}

/* changed by sreekanth
 span {
  padding-left:10px;
}
*/
#simple-tabpanel-3 span {
  padding: 0px;
}

/*
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiMenu-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  top: 400px !important;
}
 */


#demo-multiple-checkbox-label {
  right: 60px;
  z-index: 0 !important;
  line-height: normal !important;
}


.savebtn,
.savebtn:hover {
  background: var(--sec-bg-color);
  border-radius: 6.25px;
  /* padding: 0.7rem 3rem; */
  color: var(--light-violet);
  border: solid 1.2px var(--light-violet);
  cursor: pointer;
  float: right;
}

.pri-button {
  background-color: unset;
  padding: 0.7vw 3vw;
  color: var(--pri-font-color);
  border-radius: 7.2px;
  border: solid 1.2px var(--pri-font-color);
  font-weight: 700;
  opacity: 0.5;
  font-size: 0.89vw;
}

@media (max-width:991px) {
  .pri-button {
    font-size: 16px;
    padding: 0.5rem 2rem;
  }

  .dropfile {
    width: 200px;
    padding: 20px;
  }

  .file-marg-l {
    margin-left: 20px;
  }

  .file-marg-r {
    margin-right: 20px;
  }
}


@media (max-width:576px) {
  .dropfile {
    width: 100%;
    padding: 20px;
  }

  .file-marg-l {
    margin: 20px 0 0 0;
  }

  .file-marg-r {
    margin: 20px 0 0 0;
  }
}

.pri-button:hover {
  border: solid 2px var(--pri-font-color);
  font-weight: 700;
  opacity: 1;
}

.pri-button:hover svg {
  fill: var(--icons-color);
}

/*----------overrides-------*/
.css-19kzrtu {
  padding: 0 !important;
}

.col-tab {
  margin-bottom: 0.53vw;
  position: fixed !important;
  width: 90.9%;
  padding: 6vw 0px 4vw 0px;
  top: 0px;
  z-index: 1;
  height: 0px;
  border: none !important;
  background-color: var(--pri-bg-color);
}

.pro-tabs {
  padding: 5vw 0px;
}

@media (max-width:1600px) {
  .col-tab {
    width: 90.1%;
  }
}

@media (max-width:1400px) {
  .pro-tabs {
    padding: 6vw 0px 4vw 0px;
  }
}

.css-4wv2zw {
  margin-top: 11vw !important;
}

.lms-m-c2 {
  width: 80%;
}

@media (max-width:991px) {
  .col-tab {
    /* top:110px; */
    width: 95%;
    padding: 130px 0px 70px 0px;
    height: 89px;
    margin-bottom: 20px;
  }

  .lms-m-c2 {
    margin-top: 20px;
  }

  .css-4wv2zw {
    margin-top: 200px !important;
  }

  .ter-btn {
    width: 100px !important;
    height: 50px !important;
  }

  .save-btn {
    width: 100px !important;
  }

  .save-btn-wd {
    width: 140px;
  }

  .edit-del {
    padding: 0 !important;
    width: 150px !important;
  }

  /* .profile-btn-cont{
    justify-content: space-between;
  } */
}

@media (max-width:570px) {
  .col-tab {
    width: 93%;
    margin-right: 100px;
    height: 65px;
  }

  .blu-img-cont {
    width: 85px;
  }

  .lms-m-c2,
  .lms-r-c2 {
    width: 100%;
  }

  .lms-m-c2 {
    margin: 0;
  }

  .lms-r-c2 {
    margin-top: 20px;
  }
}

.profile-btn-cont {
  width: 100%;
  display: flex;
}

@media (max-width:800px) {
  .profile-btn-cont {
    flex-direction: column;
  }

  .upload-img {
    width: 100% !important;
  }

  .edit-del {
    margin-top: 20px;
  }
}

.css-13b5x29-MuiButtonBase-root-MuiTab-root>* {
  margin-bottom: -8px !important;
  margin-left: 20px;

  margin-right: 20px;
}

.css-18y2uza-MuiTypography-root-MuiTimelineContent-root {
  font-weight: 700 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: 0.94px;
  color: var(--pri-font-color);
}

.time-line-content:before {
  flex: none !important;
  --webkit-flex: none !important;
  padding: 0 !important;
}

/* This is duplicate code // Srikanth nakka
.css-55vwbm-MuiTimeline-root {
  padding: 0 !important;
}
*/
.css-1ami7lv-MuiTimelineConnector-root {
  padding-left: 0 !important;
}

.css-13b5x29-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: var(--sec-bg-color) !important;
  font-weight: 700;
  border-color: var(--sec-bg-color) !important;
}

/* Changed by sreekanth

.MuiButtonBase-root {
   width: 10vw !important;
   margin-top: 10px !important; 
  padding-top: 18px !important;
  min-height: 54px !important;
}
*/

span.MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.MuiCheckbox-root.MuiCheckbox-colorPrimary.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  /*padding-top: unset !important; // srikanth */
  min-height: unset !important;
  width: unset !important;
}

li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
  width: 100% !important;
}

/*----------overrides end-------*/
.body-search-icon img {
  width: 25px;
  height: 25px;
}

.body-search-icon input[type="text"] {
  min-width: 30vw;
  border: none;
  background-color: unset;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.75px;
  color: var(--pri-font-color);
  opacity: 0.5;
  padding-left: 2vw;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  overflow: visible !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
  overflow: unset !important;
}


.right-icon p:nth-child(4) {
  color: var(--pri-font-color) !important;
}

.menu-img .rectangle {
  opacity: 0.37;
}


.colle-img img {
  width: 100%;
  height: 46px;
}

.MuiDataGrid-root .MuiDataGrid-row {
  margin: 20px 0;
}

/*User Profile style*/
.profileDropDown {
  position: absolute;
  top: 50px;
  width: 15vw;
  background-color: var(--light-white-color);
  z-index: 100;
  border: 1px solid rgba(0, 0, 0, 0.04);
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
}

.ar-profile-drop {
  left: 0px !important;
}

.en-profile-drop {
  right: 0px !important;
}

.width-for-profile {
  width: 3.64vw;
  display: flex;
}

.ulProfile {
  list-style: none;
  padding: 0.55vw;
  margin: 0;
}

.ulProfile li {
  padding: 12px;
}
.red-logout-p {
  color: #DC5050;
}
.svg-red-fill {
  fill: #DC5050;
}
.ulProfile li:hover {
  background-color: var(--pri-bg-color);
  font-weight: 600;
}
.user-drop-svg{
  fill: var(--icons-color);
}
.ulProfile li:hover p {
  color: #ffffff !important;
}
.ulProfile li:hover .user-drop-svg{
  fill: #ffffff;
}
.ulProfile li:hover .svg-red-fill{
  fill: #ffffff;
}
.profileDropDown span{
  color: var(--pri-font-color) !important;
}
.user-personal-details .profile-badges p{
  font-size: 1.35vw;
  font-weight: 700;
}
.badge-note-desc{
  font-size: 0.93vw;
  font-weight: 700;
}

.liMenu {
  padding: 8px 12px;
}

.liMenu a {
  margin-bottom: 0;
}

.liMenu img {
  width: 20%;
  padding-right: 10px;
}

.liMenu h3 {
  font-size: 0.93vw;
  margin-bottom: 0;
}

.liMenuhover {
  background-color: rgba(0, 0, 0, 0.14);
  cursor: pointer;
}

.hamburgerbutton {
  padding: 0;
  width: 50px;
  border: 0;
  background-color: var(--light-white-color);
  color: var(--darkgray-color);
  cursor: pointer;
  outline: 0;
  font-size: 40px;
}

.lang-container {
  position: relative;
  display: inline-block;
  /* margin-right: 20px; */
  cursor: pointer;
}

.arrow {
  border: solid #062349;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  background-color: unset;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

/* .loginform {
  margin: auto;
  width: 40%;
  left: 50%;
  top: 50%;
  border: 5px solid #575353;
  padding: 10px;
} */

.view-all-content {
  margin-bottom: 20px;
}

.view-all-content span {
  padding-left: 10px !important;
}

.view-all-content svg {
  margin-bottom: 2px;
}

.view-heading {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 0.833vw;
  line-height: 20px;
  color: var(--pri-font-color);
}

.view-heading-back {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  color: var(--pri-font-color);
}

.view-heading-backarrow {
  color: var(--pri-font-color);
  cursor: pointer;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.viewall-image {
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

@media (max-width:991px) {
  .view-heading {
    font-size: 16px;
  }

  .liMenu h3 {
    font-size: 16px;
  }
  .user-personal-details .profile-badges p{
    font-size: 18px;
  }
  .badge-note-desc{
    font-size: 14px;
  }
}

.user-dashborad-cou-title {
  margin: 60px 0 20px 0;
  justify-content: space-between;
}

.user-dashborad-cou-title h6 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: var(--pri-font-color);
}

.status-cou {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  text-align: right;
  letter-spacing: 0.564706px;

  color: var(--sec-bg-color);
}

.status-of-course {
  justify-content: space-between;
}

.view-all {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--pri-font-color);
  padding-right: 30px;
  cursor: pointer;
}

.notes-tab-btn {
  margin-top: 20px !important;
}

.sec-btn {
  background: var(--icons-color);
  color: var(--sec-font-color);
  padding: 0.625vw 2vw !important;
  border: none !important;
  width: 8vw;
}

.certification .sec-btn {
  width: 10vw;
}

.save-btn {
  padding: 0.7rem 0px;
  width: 8vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width:1200px) {

  .save-btn,
  .sec-btn {
    width: 10vw;
  }
}

.sec-btn:hover {
  background: var(--icons-color);
  color: var(--sec-font-color);
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--pri-bg-color);
  min-width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.edit-user-profile {
  width: 60%;
  margin: auto;
}
.profile-badge-img{
  width: 4.1vw;
}

.profile-tbs-l-sort {
  right: 0;
}

.profile-tbs-r-sort {
  left: 0;
}

.profile-tbs {
  padding: 0vw 2vw !important;
  right: 0;
}

.profile-tbs-label {
  padding: 0 1vw !important;
}

.pro-tbs-sort {
  position: absolute;
  top: 55%;
}

@media (max-width: 1400px) {
  .pro-tbs-sort {
    top: 59%;
  }
}

@media (max-width: 991px) {
  .edit-user-profile {
    width: 100%;
  }
  .profile-badge-img{
    width: 60px;
  }

  .profile-tbs-label {
    padding: 0px 10px !important;
  }

  .collection-bg {
    background-color: transparent;
    border: none;
    align-items: flex-end !important;
  }

  .border-for-collection-tabs {
    display: none;
  }

  .profile-tabs {
    width: max-content !important;
  }
}

@media (max-width: 576px) {

  .col1,
  .col2 {
    width: 100% !important;
    margin: 10px 0px;
  }
}

.userprofile {
  width: 100%;
  margin: 0 1vw;
  padding: 12px 0;
}

.sec-pro-heading {
  color: var(--pri-font-color);
}

.profile-mailid {
  color: var(--pri-font-color) !important;
}

.css-10d9dml-MuiTabs-indicator {
  background-color: var(--light-white-color) !important;
}

.kDqVVO {
  color: var(--pri-font-color) !important;
}

.edit-user-details .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  background: var(--pri-bg-color) !important;
  border-radius: 2px !important;
  margin: 4px !important;
}

@media (max-width:576px) {
  .edit-user-details .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    opacity: 1;
  }
}

.user-close-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-dashborad-cou-title .view-all span img {
  width: 8px;
}

button {
  border: none;
}

.user-personal-details {
  padding: 20px 1.5vw 10px 1.5vw;
  background-color: var(--light-white-color);
}

.user-personal-details h6 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 600;
  font-size: 1.354vw;
  line-height: 24px;
  color: var(--pri-font-color);
}

.personal-info {
  padding: 20px 0;
  width: 38vw;
  overflow: hidden;
}

.edit-user-details .MuiTabs-root.MuiTabs-vertical.css-17n50n7-MuiTabs-root {
  width: 45%;
}

/* changed by sreekanth
.upload-img {
  padding-left: 3vw;
}  */

.languge-border-r {
  border-right: 0.5px solid #A8B6C3;
}

.languge-border-l {
  border-left: 0.5px solid #A8B6C3;
}


.lang-margin-l {
  margin-left: 0.9vw;
}

.lang-margin-r {
  margin-left: 0.9vw;
}


.ter-btn {
  padding: 0.6vw 2.5vw;
  background-color: rgba(4, 54, 119, 0.06);
}

.edit-del {
  padding: 12px 3vw;
}

.edit-user-details .MuiBox-root.css-19kzrtu {
  height: max-content !important;
}

.personal-details .row {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

.col1 {
  width: 48%;
}

.col2 {
  width: 48%;
}

/* input[type="text"] {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  box-sizing: border-box;
} */
.user-password-details {
  padding: 20px;
  margin: 20px 0 !important;
  background-color: var(--light-white-color) !important;
}

.checkbox-container {
  display: flex;
  align-items: center;
  position: relative;
  /* padding-left: 35px;
  margin: 12px; */
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: #eee;
}

.is-rtl-checkmark {
  left: 0;
}

.is-ltr-checkmark {
  right: 0;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
  background-color: var(--light-gray);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: var(--icons-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 7px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-container .lang-img {
  width: 15%;
  /* margin-left: 1.35vw; */
}

.lang-img-edit {
  padding: 0.5vw;
}

.personal-details {
  padding-top: 30px;
}

/* .checkbox-container p {
  padding:0px 15px;
} */

.email-notification {
  padding-bottom: 30px;
}

.action-items {
  display: flex;
  padding: 0 0 20px 0;
  justify-content: flex-end;
}

.user-cerficates-con {
  width: 100%;
  align-items: flex-start;

  justify-content: space-between;
}

@media (max-width:991px) {
  .user-cerficates-con {
    flex-direction: column;
  }
  .user-personal-details h6{
    font-size: 24px;
  }

}

.user-certificates {
  width: 100%;
}

@media (max-width:991px) {
  .user-certificates {
    width: 100%;
  }

}

.user-certificates h6,
.badges-con h6 {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: var(--pri-font-color);
}

.badges-con {
  width: 20%;
}

.certificates-list {
  padding: 20px 0;
}

.certi-list {
  flex-wrap: wrap;
}


.certi-list li {
  width: 25%;
  /* Changed by sreekanth 
   margin-right: 3%;*/
  margin-bottom: 6%;
}

@media (max-width:991px) {
  .certi-list li {
    width: 33%;
  }
  .user-personal-details>div{
    display: block !important;
  }
}

@media (max-width:800px) {
  .certi-list li {
    width: 50%;
  }
}

@media (max-width:576px) {
  .certi-list {
    flex-direction: column;
  }

  .certi-list li {
    width: 100%;
    /* Changed by sreekanth 
     margin-right: 3%;*/
    margin-bottom: 60px;
  }
}

.cer-card {
  position: relative;
  /* text-align: center; */
  color: var(--form-field-color);
}

.download-btn {
  background-color: var(--icons-color);
  color: var(--sec-font-color);
  font-size: 0.9vw !important;
  line-height: normal !important;
  height: -webkit-fill-available;
}

@media (max-width:991px) {
  .download-btn {
    font-size: 14px !important;
    width: 100%;
    height: 43px;
    padding: 0 10px;
    text-align: center;
  }
  .badges-con {
    display: none;
  }
}
.admin-create-btn1 {
  margin: 0px 1vw;
}

.share-btn {
  padding: 0.593vw;
  background-color: var(--icons-color);
  color: var(--light-white-color);
}

.share-btn-collection {
  padding: 0px 0.52vw;
  background-color: transparent;
  color: var(--sec-font-color);
}

.share-btn-collection svg {
  width: 1vw !important;
  height: 1vw !important;
}

.share-btn-del-icon svg {
  width: 1.5vw !important;
  height: 1.5vw !important;
}

@media (max-width:991px) {
  .share-btn {
    padding: 0;
    height: 43px;
    width: 43px;
  }

  .share-btn img {
    width: 45%;
  }
}

.profile-avatar {
  width: 55px;
  border-radius: 50%;
}

.share-user .profile-avatar {
  height: 45px;
}

.width-for-profile .profile-avatar {
  height: 55px;
}

.collection-profile,
.profile-avatar {
  width: 2.083vw;
  height: 2.083vw !important;
}

@media (max-width:991px) {

  .collection-profile,
  .profile-avatar {
    width: 40px;
    height: 40px !important;
  }
}

.collection-profile {
  width: 60px;
}

.email {
  margin-top: 5px;
  font-size: 0.83vw;
}

.cource-name {
  position: absolute;
  top: 40%;
  left: 65%;
  transform: translate(-50%, -50%);
}

.user-actions {
  padding: 10px 0;
  gap:20px;
}

.padding-for-cirtificate {
  padding: 0.6vw;
}

.badges-list-con {
  background-color: var(--light-white-color);
  margin: 20px 0;
  height: 100vh;
}

.badges-list li {
  padding: 0 20px;
}

@media (max-width:991px) {
  .badges-list-con {
    height: auto;
  }
}

.badges-list li .level {
  padding: 14px 0;
  border-bottom: 0.5px solid #bdc7d0;
  align-items: center;
}

.badge-logo {
  width: 70px;
}


.badge-name {
  font-weight: 700;
}


/*login css*/
.login-section {
  display: flex;
  width: 100%;
  background: var(--pri-bg-color);
}

.login-details-section {
  width: 40%;
  padding: 0 5.52vw;
}

.login-heading-container {
  margin-top: 5.333333333333334vw;
}

.login-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 2.3958333333333335vw;
  line-height: 2.9166666666666665vw;
  color: var(--pri-font-color);
}

.logo {
  width: 12.76vw;
}

.login-img-2 {
  width: 26vw;
  margin-top: 3.75vw;
}

.login-details-container {
  margin-top: 3.16vw;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.login-form-section {
  /* margin-top: 2.45vw; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form-section .label {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 1.0416666666666667vw;
  line-height: 1.25vw;
  color: var(--pri-font-color);
  padding-bottom: 10px;
}

@media (max-width:991px) {
  .login-form-section .label {
    font-size: 14px;
    line-height: 150%;
    opacity: 0.6;
  }
}

.employee-password-input-container {
  position: relative;
  margin-top: 30px;
}

.search-box {
  z-index: 9;
  position: absolute;
  background-color: white;
  width: 93.5%;
}

.suggestions_ul {
  cursor: pointer;
  box-sizing: border-box;
  font-size: 16px;
  /* box-shadow: 5px 5px 13px 4px rgb(0 0 0 / 7%); */
  overflow-y: auto;
  height: 15vw;
}

.suggestions {
  padding: 0.4vw 0px !important;
}

.no-suggestions {
  margin: 1vw 0px;
  font-size: 0.83vw;
}

@media (max-width:1600px) {
  .suggestions_ul {
    height: 13vw;
  }
}

@media (max-width:991px) {
  .search-box {
    width: 88%;
  }

  .suggestions_ul {
    height: 225px;
  }

  .suggestions {
    padding: 10px 0px 0px 0px !important;
  }

  .no-suggestions {
    font-size: 16px;
    margin: 10px 0px;
  }
}

@media (max-width:576px) {
  .suggestions_ul {
    width: 90%;
  }

  .login-form-section {
    width: 100%;
  }
}

.suggestions_active,
.suggestions:hover {
  background-color: #F5F7F9 !important;
}

.collection-suggestions .suggestions:hover {
  background-color: #ffffff !important;
}

.suggestions {
  padding-left: 10px;
}

.collection-suggestions .suggestions {
  padding: 0.5vw 1vw !important;
}

.collection-suggestions .suggestions_ul {
  width: 76%;
  background-color: #F5F7F9;
  padding: 1vw;
}

.table_cell_design {
  font-family: var(--font-family) !important;
  font-weight: 500 !important;
  border: unset !important;
}

.table_cell_colour_active {
  color: #278A42;
}

.table_cell_colour {
  color: var(--pri-font-color) !important;
}

.playlist_videos {
  box-shadow: 4px 4px 21px rgb(0 0 0 / 6%);
  background-color: var(--tri-bg-color);
  max-height: 58vw;
  overflow-y: auto;
}

.left-border-play-list {
  border-left: 4px solid var(--icons-color) !important;
}

.right-border-play-list {
  border-right: 4px solid var(--icons-color) !important;
}

.employee-id-input,
.employee-password-input {
  padding: 20px 30px !important;
  border: 0.052083333333333336vw solid #062349;
  border-radius: 0.3645833333333333vw;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  padding-left: 1.5625vw;
  font-size: 0.9375vw;
  line-height: 1.1458333333333333vw;
  color: var(--pri-font-color);
}

.employee-password-input::-ms-reveal {
  display: none;
}

.eye-Shape {
  position: absolute;
  right: 1vw;
  top: 3vw;
  width: 1.7708333333333333vw;
  height: 1.0416666666666667vw;
  cursor: pointer;
}

.forgot-password {
  font-family: var(--font-family);
  text-decoration: none;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8333333333333334vw;
  line-height: 1.0416666666666667vw;
  text-align: right;
  color: var(--pri-font-color);
  opacity: 0.7;
  margin-top: 1.0416666666666667vw;
}

.button-container {
  margin-top: 5.29vw;
  width: 100%;
}

.login-button {
  width: 100%;
  height: 3.8541666666666665vw;
  background: var(--login-btn-background);
  color: var(--tri-bg-color);
  border-radius: 0.3645833333333333vw;
  font-style: normal;
  font-weight: 500;
  font-size: 1.3541666666666667vw;
  line-height: 1.6666666666666667vw;
  text-align: center;
  border: none;
  cursor: pointer;
}

.sign-up {
  font-style: normal;
  font-weight: 400;
  font-size: 1.0416666666666667vw;
  line-height: 1.6666666666666667vw;
  color: var(--pri-font-color);
  margin-top: 1.5625vw;
  display: flex;
  justify-content: center;
}

.sign-up-span {
  color: var(--pri-font-color);
  text-decoration: none;
  font-weight: 700;
  margin-left: 0.5208333333333334vw;
}

.login-page-image {
  border-radius: 2.6041666666666665vw;
  height: 100%;
  width: 100%;
}

.login-image-section {
  width: 60%;
  height: 100vh;
  background: radial-gradient(50% 50% at 50% 50%, #7D9FF5 0%, #5681EF 100%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-image-container {

  padding: 1.0416666666666667vw;
}

@media only screen and (max-width: 600px) {
  .login-section {
    width: 100%;
    flex-direction: column;
    background: var(--tri-bg-color);
    justify-content: space-between;
    height: 100vh;
  }

  .login-img-2 {
    width: 100%;
    margin-top: 40px;
  }

  .login-page-image {
    border-radius: 30px;
    height: 100%;
    width: 100%;
  }

  .login-details-section {
    width: 100%;
    height: 50%;
    margin-top: 40px;
    padding: 0px 20px;
  }

  .employee-id-input,
  .employee-password-input {
    padding: 30px !important;
  }

  .forgot-password {
    margin: 10px 0px;
    font-size: 14px;
    line-height: 150%;
  }

  .button-container {
    margin-top: 50px;
  }

  .logo {
    width: 140px;
    height: auto;
    margin: 40px 0px 20px 0px;
  }

  .login-heading-container {
    width: 100%;
  }

  .login-heading {
    font-size: 30px;
    line-height: 150%;
  }

  .login-details-container {
    padding: 0px;
    margin: 0px;
  }

  .login-image-section {
    width: 100%;
    padding: 20px;
    height: 50%;
  }

  .login-image-container {
    padding: 0px;
  }

  .employee-id-input {
    width: 270px;
    height: 40px;
    font-size: 15px;
  }

  .employee-password-input {
    width: 100%;
    height: 40px;
    font-size: 15px;
    border: 1px solid #BCBCBC;
  }

  .label {
    font-size: 20px;
  }

  .login-button {
    height: 50px;
    width: 100%;
    font-size: 20px;
    margin-top: 10px;
    line-height: normal;
  }

  .sign-up {
    font-size: 15px;
    margin-top: 20px;
    line-height: normal;
  }

  .eye-Shape {
    width: 25px;
    height: 17px;
    right: 15px;
    top: 55px;
  }
}

.share-con-btn {
  width: 100% !important;
  background-color: var(--icons-color);
  color: #FFFFFF;
  /* margin-top: 20px; */
  font-size: 0.80vw;
}

.share-con {
  padding: 1.04vw;
  height: 23.75vw !important;
}

.share-con .sec-btn {
  width: 5vw !important;
}

.sharebtn-con {
  width: 49%;
}

.share-icons-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 48%;
}

.share-icons-con svg {
  width: 2vw;
}

.sharebtn-con button {
  font-size: 0.72vw;
}

.share-con-ul {
  height: 250px;
  overflow-y: scroll;
}

.share-flex-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap:1.5vw;
}

.share-flex-con svg{
  width: 1.87vw;
  height: 1.87vw;
}

.share-flex{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width:1500px) {
  .share-flex{
    display: block;
  }
  .share-video-con-dis span{
    display: block !important;
  }
}

.share-flex-con>div{
  gap:1.5vw;
}

.sharebtn-con svg{
  width: 1vw;
}
.share-through-con{
  background-color: #ffffff;
  padding: 1vw;
  border-radius: 0.8vw;
}

.copy-link-btn{
  border: 1px solid var(--icons-color);
  background-color: transparent;
  color:var(--icons-color);
  font-size: 0.729vw;
  width: 100%;
  display: flex;
  align-items: center;
  gap:0.5vw;
  justify-content: center; 
}
.search-no-user-con{
  height: 100%;
  background-color: #F5F7F9;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1vw;
}
.search-no-user-con h4{
  font-size: 1.04vw;
  font-weight: 600;
  margin: 0;
  line-height: normal;
  color: var(--pri-font-color);
}
.search-no-user-con p {
  font-size: 0.72vw;
  color: #6C6C6C;
}
.i-icon{
  background-color: var(--icons-color);
  border-radius: 50%;
  font-size: 0.7vw;
  padding:0.2vw 0.5vw;
  line-height: normal;
  color: #ffffff;
}

@media (max-width:991px) {
  .share-con {
    height: 400px !important;
    padding: 20px
  }
}

@media (max-width: 1500px) {
  .share-icons-con {
    width: 100%;
    margin-bottom: 15px;
  }

  .sharebtn-con {
    width: 100%;
  }


  .share-icons-con svg {
    width: 58px;
  }

}

.share-con input[type="text"] {
  width: 75%;
  box-sizing: border-box;
  border: 2px solid var(--light-gray);
  border-radius: 4px;
  font-size: 0.83vw;
  background-color: transparent;
  background-image: url("../public/assets/search-icon.svg");
  background-position: 0.5vw 0.7vw;
  background-repeat: no-repeat;
  padding: 0.625vw 1.04vw 0.625vw 2.08vw;
  margin: 0px !important;
  background-size: 1.2vw 1vw;
}


/* .MuiPaper-elevation8 {
  width: 24.47vw !important;
} */

@media (max-width:991px) {
  .MuiPaper-elevation8 {
    width: 335px !important;
  }

  .share-con input[type="text"] {
    padding: 12px 20px 12px 40px;
    font-size: 16px;
    background-size: 35px 25px;
    background-position: 7px 13px;
  }
}

@media (max-width:576px) {
  .MuiPaper-elevation8 {
    right: 0px !important;
  }
}

.ar-popup {
  right: unset !important;
  left: 69px !important;
  top: 218px !important;
}

.share-user-inf {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 0.729vw;
  line-height: 0.88vw;
  color: var(--form-field-color);
}

.MuiIconButton-root {
  padding: 0.4vw !important;
}

.share-user-inf h3,
.list-text span {
  font-weight: 600;
  font-size: 0.83vw;
  color: #1A1A1A;
}

.input:disabled {
  cursor: not-allowed !important;
}

.share-user {
  width: 2.864vw;
}

@media (max-width:991px) {

  .share-user-inf h3,
  .share-con-btn {
    font-size: 14px;
  }

  .share-user {
    width: 58px;
  }

  .email {
    font-size: 14px;
    margin: 0px;
    line-height: normal;
    /* width: 97%; */
  }
}

/*
.share-user-inf span,
p {
  padding-left: 16px;
}
*/
.share-con .share-btn {
  background-color: unset;
}

/* .share-con li {
  padding-bottom: 20px;
} */

.css-78trlr-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: unset !important;
}

.add-icon,
.remove-icon {
  cursor: pointer;
}

.remove-icon {
  width: 1.04vw;
}

/*notifications*/
.notification-dpdn {
  padding: unset;
}

.notification-noun-close {
  width: 10%;
  cursor: pointer;
}

.notification-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 20px;
}

.notification-heading {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 0.9375vw;
  line-height: 1.14583vw;
  color: var(--form-field-color);
  margin: 0;
}

.notification-list-container {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.notification-profile-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.625vw;
  width: 100%;
}

.notification-profile-pic {
  width: 2.604165vw;
}

.green-profile {
  border: #379e53 1px solid;
  border-radius: 0.78125vw;
  padding: 1px;
}

.notifi-name-container .user-name {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 0.885416vw;
  line-height: 0.78125vw;
  color: var(--form-field-color);
  margin: 0;
  margin-top: 0.264167vw;
}

.notifi-name-container {
  margin-left: 10px;
}

.notifi-name-container .blue-name {
  color: var(--browse);
}

.notifi-name-container .green-name {
  color: #379e53;
}

.profile-drop-container {
  cursor: pointer;
}

.profile-content {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 0.78125vw;
  line-height: 0.78125vw;
  color: var(--form-field-color);
  margin-top: 0.520834vw;
  margin-bottom: 0.2604167vw;
}

.notification-profile-section {
  padding: 10px 20px 20px 20px;
}

.name-container {
  text-align: start;
  margin-left: 0.520834vw;
}

.notification-action-icon {
  width: 1.302083vw;
}

.action-icon-container {
  width: 7%;
}

.profile-pic-container {
  display: flex;
  align-items: center;
  width: 70%;
}

.notification-list-item {
  border-radius: 0.3125vw;
  padding-bottom: 20px;
}

.notification-list-item:hover .notification-profile-container {
  background-color: var(--pri-bg-color);
}

.notification-content {
  top: 30px !important;
  right: -50px !important;
  width: 22vw !important;
  height: 404px;
  overflow-y: scroll;
}

/*admin share  css*/
.admin-share-con .css-15bxf7v {
  width: 60% !important;
  height: 650px !important;
  padding: 0 !important;
  border: unset !important;
}

.share-course-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 0.83vw;
  line-height: 1.04vw;
  /* identical to box height */
  letter-spacing: 0.658824px;
  color: #062349;
  mix-blend-mode: normal;
}

.share-course-title-pad {
  margin-left: 10px;
}

@media (max-width:991px) {
  .share-course-title {
    font-size: 16px;
  }
}

.admin-share-user .share-title,
.share-title {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 1.14vw;
  line-height: 1.40vw;
  padding: 1.4vw 2vw 1.4vw 0;
  color: var(--form-field-color);
  width: 50%;
}

.share-title {
  width: 100%;
  padding: 0px 0px 1vw 0px;
}

.share-con-input input {
  width: 100% !important;
}

.share-con-input {
  margin-top: 10px;
}

.admin-share-user-left .react-tabs .react-tabs__tab {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--pri-font-color);
}

.admin-share-user-lists {
  height: 16.3vw;
  overflow: auto;
  margin-top: 1.04vw !important;
  padding: 0 !important;
}

.admin-share-user {
  overflow-y: scroll;
  height: inherit;
  padding: 0vw 1.5vw;
  /*padding: 1.5vw 0 0 2vw; // srikanth */
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}
.admin-share-user-right hr{
  margin-bottom: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.admin-share-user::-webkit-scrollbar {
  display: none;
}

.admin-share-user-left {
  width: 50%;
}

.admin-share-user-left ul li {
  border-bottom: 1px solid #d2dbe3;
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0 !important;
}

ul li:last-child {
  border: none;
}

.admin-share-user-right {
  width: 50%;
  background-color: var(--pri-bg-color);
  padding: 2vw 0vw 0vw 0vw;
  height: 37.5vw;
}

@media (max-width:1400px) {
  .admin-share-user-right {
    height: 37vw;
  }

  .admin-share-user-lists {
    height: 15.38vw;
  }
}

.admin-share-user-right ul {
  background-color: var(--pri-bg-color);
}

.admin-share-btn svg {
  width: 2vw;
  height: 2vw;
}

.admin-share-list {
  height: 28.87vw;
  overflow: auto;
  margin-top: 1.041vw !important;
  padding: 0 !important;
}

.share-container .admin-share-list {
  height: 18.22vw;
}

.admin-share-user-right .admin-share-list {
  height: 11vw !important;
}

@media (max-width:1500px) {
  .admin-share-user-right .admin-share-list {
    height: 10vw !important;
  }
}

.admin-share-user-right ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.7rem 0 !important;
}

.share-buttons {
  align-items: center;
  justify-content: end;
  background-color: var(--pri-bg-color);
  padding: 1vw 0 0 0;
  /* min-height: 18vw; // srikanth */
}


.ad-share-btn{
  padding: 0.6vw 2.5vw !important;
}

.ad-share-btn:disabled{
  opacity: 0.3;
}



.admin-share-user-right .share-title {
  padding: 2.7vw 0 1.4vw 0;
}

.create-group .share-buttons {
  width: 100%;
  background-color: var(--tri-bg-color);
}

.create-group .suggestions_ul {
  height: 13vw;
}

input[type="text"],
[type="password"],
[type="time"],
[type="date"] {
  border-radius: 4px;
  font-size: 0.833vw;
  background-color: transparent;
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 0.6vw 1.14vw 0.6vw 1vw;
  /* Changed by sreekanth 
  margin: 0 20px 0 0;
  */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d2dbe3;
}



.managment-container input[type="text"],
[type="password"] {
  font-size: 0.83vw;
  padding: 0.625vw 1.04vw 0.625vw 0.524vw;
}

#drag-file {
  display: none;
}

@media (max-width:991px) {

  input[type="text"],
  [type="password"] {
    font-size: 16px;
    padding: 12px 20px 12px 10px;
  }
}

textarea,
select {
  border-radius: 4px;
  font-size: 16px;
  background-color: var(--tri-bg-color);
  color: var(--pri-font-color);
  background-position: 10px 10px;
  background-repeat: no-repeat;
  padding: 12px 20px 12px 10px;
  /* Changed by sreekanth                              
   margin: 0 20px 0 0; */
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d2dbe3;
}

.btn-bd-primary {
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: var(--sec-bg-color);
  color: var(--tri-bg-color);
  font-size: 15px;
}

.edit-buttons {
  align-items: flex-end;
  justify-content: end;
  margin-left: 10px;
}

.edit-buttons .ad-edit-btn {
  margin-left: 10px;
}

.identify {
  padding: 0.364vw 0.8333vw 0.4166vw 0vw;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.56px;
  text-align: center;
  font-size: 12px;
  color: var(--pri-font-color);
}

.searbox {
  padding: 12px 20px 12px 40px;
  background-image: url("../public/assets/search-icon.svg");
}

/*admin css*/
.collection-tab-right {
  justify-content: end;
  width: 100% !important;
}

.collection-tab-right-collections {
  justify-content: end;
  width: 10%;
}

.collection-tab-right-collection {
  width: 100%;
  justify-content: flex-end;
}
.collection-tab-right-collection button:disabled{
  opacity: 0.4;
}
.collection-tab-right-collection button:disabled path,
.collection-tab-right-collection button:disabled svg{
  cursor: not-allowed;
}

.collection-thumb {
  border-radius: 0.5vw;
}

.admin-cou-btn {
  margin-right: 2vw;
}

.css-1e2bxag-MuiDataGrid-root {
  line-height: 1.8 !important;
}

.create-group input[type="text"] {
  width: 98%;
  font-size: 0.83vw;
  padding: 0.62vw 1.04vw 0.62vw 0.52vw;
}

.create-group-model .css-15bxf7v {

  width: 35% !important;
  height: 28.64vw !important;
}

.create-group-model .create-group .user-inf {
  width: 60%;
}

/* .create-group {
  padding: 0vw 2vw;
} */

.add-to-group-padding{
  padding: 0 1.5vw;
}
.add-to-group-inp{
  padding: 0.5vw 1.3vw !important;
  font-size: 0.88vw;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}

/* .create-group .share-title {
  padding: 1.4vw 0;
} */

.create-group .copy-invite{
  font-family: var(--font-family);
  font-weight: 600;
  font-size:0.72vw;
  line-height: 0.88vw;
  color: var(--progress-bar-color);
  align-items: center;
  cursor: pointer;
  display: flex;
  text-decoration: underline;
}

.copy-invite-success{
  color: #278A42 !important;
}

.create-group .add-new-user{
  width: unset;
}

.create-group .copy-invite svg{
  width: 1.04vw;
  height: 1.04vw;
  margin-right: 0.52vw;
}

/* .create-group .copy-invite{
  
} */

.create-group .add-user-close-icon{
  align-items: center;
  display: flex;
  padding-left: 1.04vw;
}

.create-group .add-user-close-icon img{
  cursor: pointer;
  width: 0.78vw;
  height: 0.78vw;
}

.create-grp-btn {
  width: 8%;
  display: flex;
  background: unset;
  color: unset;
}

.user-inf {
  align-items: center;
}

.create-group .user-info {
  padding: 0.5rem 0;
  border-bottom: 0.5px solid #F2F2F2;
  display: flex;
  justify-content: space-between;
}

.share-with-span {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-top: 20px;
  /* identical to box height */

  letter-spacing: 0.658824px;

  color: #062349;
}

/* Changed by sreekanth 
.collection-tab {
  float: right;
}
*/

.add-course-p {
  height: 2vw !important;
  margin: 0px 2vw !important;
  align-self: center !important;
  width: 10.01vw !important;
}

.group-dpdn-size {
  font-size: 0.84vw !important;
}

.mui-devider {
  margin: 0.41vw 0 !important;
}

.right-icon p {
  padding: 0 0.52vw !important;
}

/*
.select-cat-wid{
  justify-content: space-between; // Srikanth 
}
*/
.admin-vedio-select-option:hover .select-cat-wid svg {
  opacity: 1 !important;
}

.admin-vedio-select-option {
  margin: 1.56vw 0 0.8vw 0;
}

.admin-vedio-select-option [type="text"] {
  background-color: var(--tri-bg-color) !important;
}

.admin-vedio-select-option .vertical-form-controll,
.admin-vedio-select-option .MuiTextField-root {
  width: 50% !important;
  margin: 0 0.25vw !important;
}

.admin-vedio-select-option p {
  font-size: 0.933vw !important;
  font-family: var(--font-family) !important;
}

.MuiInputLabel-shrink .select-cat-wid svg {
  opacity: 1 !important;
}

.category-width {
  padding: 0px 0.5vw !important;
  display: flex;
  justify-content: flex-end;
}

.select-catagery .MuiSelect-select {
  padding: 0 !important;
}

.admin-vedio-select-option label {
  font-size: 0.93vw !important;
  margin: 0 !important;
  font-family: var(--font-family) !important;
  left:-4px !important;
  top: 1% !important;
}
.admin-course-select-option label {
  font-family: var(--font-family) !important;
  font-size: 0.93vw !important;
  margin: 0 !important;
  left: 6px !important;
  top: 1% !important;
}

@media (max-width:1400px) {
  .admin-vedio-select-option label {
    top: 3.4% !important;
  }
  .admin-course-select-option label{
    top: 3.4% !important;
  }
}
.select-option{
  font-size: 0.83vw !important;
}
.admin-course-select-option svg{
  right: 20px;
}
.select-cat-upload::placeholder{
  font-weight: 500;
}
.select-cat-upload{
  font-weight: 500;
}
.select-cat-upload{
  width: 50% !important;
}

.collection-tabs.Mui-selected #active-svg {
  opacity: 1;
}


.active #video-active-svg {
  opacity: 1;
}

.right-icon:hover #video-active-svg {
  opacity: 1;
}

.css-184w5ny-MuiListItem-root {
  text-align: start !important;
  padding: 0px !important;
  border-bottom: 0.5px solid #D2DBE3;
}

@media (max-width:991px) {
  .css-184w5ny-MuiListItem-root {
    margin: 10px 0px;
  }
}

.css-168pop2-MuiList-root {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  /* padding-top: 8px; */
  padding-bottom: 8px;
  width: 100%;
  background-color: #fff;
}

.css-14f9zar-MuiListItemSecondaryAction-root {
  position: initial !important;
  transform: none !important;
  -webkit-transform: none !important;
}

.css-1tnift2-MuiListItem-root {
  text-align: start !important;
}

.svg-icons-color {
  fill: var(--icons-color);
  opacity: 0.3;
}

.svg-for-menu {
  fill: var(--icons-color);
}

.svg-for-menu-saved {
  fill: var(--light-white-color);
}

.MuiTabs-indicator {
  background-color: var(--icons-color) !important;
}

.svg-stroke {
  stroke: var(--icons-color);
}

label,
input {
  color: var(--pri-font-color);
}

.admin-navbar div,
span {
  color: var(--pri-font-color);
}

.del-svg,
.edit-icon-svg,
.link-icon-svg {
  fill: #B8B8B8;
}


path,
svg {
  cursor: pointer;
}

svg:hover .svg-icons-color {
  opacity: 1;
}

.del-stroke,
.edit-icon-stroke,
.link-icon-stroke {
  stroke: #B8B8B8;
}

#del-icon:hover .del-svg {
  fill: var(--icons-color);
}

.quiz-del-svg:hover .del-svg {
  fill: var(--icons-color);
}


#del-icon:hover .del-stroke,
#small-del-icon:hover .del-stroke {
  stroke: var(--icons-color);
}

.quiz-del-svg:hover .del-stroke {
  stroke: var(--icons-color);
}

#edit-icon:hover .edit-icon-svg {
  fill: var(--icons-color);
}

.edit-icon:hover .edit-icon-svg {
  fill: var(--icons-color);
}

#edit-icon:hover .edit-icon-stroke {
  stroke: var(--icons-color);
}

.edit-iconhover .edit-icon-svg {
  fill: var(--icons-color);
}

#link-icon:hover .link-icon-stroke {
  stroke: var(--icons-color);
}

#link-icon:hover .link-icon-svg {
  fill: var(--icons-color);
}

.quiz-del-svg,
.edit-icon {
  width: 1.3vw !important;
  height: 100% !important;
}

#edit-icon,
#del-icon,
#link-icon {
  width: 2vw !important;
  height: 100% !important;
}

#small-del-icon {
  fill: none;
}

g {
  opacity: 1;
}

.progress-p {
  color: var(--pri-font-color);
  font-size: 0.83vw;
}

.tumb-svg {
  width: 1vw !important;
  height: 1vw !important;
}

.checkbox svg {
  width: 1.19vw !important;
  height: 1.19vw !important;
}

.progress-color {
  background-color: #ffffff !important;
  border: 0.3px solid rgba(0, 0, 0, 0.28) !important;
  height: 0.6vw !important;
  border-radius: 3.9vw;
}

@media (max-width:991px) {
  .progress-color {
    height: 10px !important;
  }
}

.MuiLinearProgress-bar {
  background-color: var(--pri-font-color) !important;
}

.MuiLinearProgress-bar {
  background-color: var(--progress-bar-color) !important;
  border-radius: 3.9vw;
}

.in-progress-bar {
  z-index: -1 !important;
  background-color: #ffffff !important;
}

#browse-videos-m {
  margin-top: 200px !important;
}

.collection-tabs:hover .svg-icons-color {
  opacity: 1;
}

.blu-bar-height {
  height: 5.5vw;
  background-color: var(--pri-bg-color);
  position: fixed !important;
  top: 10vw;
  width: 89.6%;
  z-index: 1;
}

@media (max-width:991px) {
  .blu-bar-height {
    top: 200px;
  }

  .lms-blue-res {
    flex-direction: column;
    align-items: flex-start;
  }

  .blue-cont-res {
    width: 100%;
  }
}

@media (max-width:576px) {
  .blu-bar-height {
    top: 200px;
  }
}

.lms-video-image {
  position: relative;
}

.video-duration-cont {
  position: absolute;
  background-color: var(--pri-font-color);
  top: 3.0vw;
  border-radius: 3px;
  color: var(--sec-font-color);
  padding: 0px 5px;

}

.ltr-video-duration {
  right: 0.6vw;
}

.rtl-video-duration {
  left: 0.6vw;
}

.video-duration {
  font-size: 0.67vw;
  font-weight: 500;
}

@media (max-width: 1673px) {
  .video-duration-cont {
    top: 2.9vw;
  }
}

@media (max-width: 1380px) {
  .video-duration-cont {
    top: 2.8vw;
  }

  .ltr-video-duration {
    right: 0.3vw;
  }

  .rtl-video-duration {
    left: 0.3vw;
  }

}

@media (max-width: 1200px) {
  .video-duration-cont {
    top: 2.9vw;
  }

  .ltr-video-duration {
    right: 0;
  }

  .rtl-video-duration {
    left: 0;
  }

  .video-duration {
    font-size: 10px;
  }

  @media (max-width:991px) {
    .user-courses-con {
      margin-top: 390px;
    }

    .video-duration {
      font-size: 13px;
    }
  }

  @media (max-width:576px) {
    .user-courses-con {
      margin-top: 450px;
    }
  }
}

@media (max-width: 991px) {
  .video-duration-cont {
    top: 5.3vw;
  }

  .ltr-video-duration {
    right: 0.3vw;
  }

  .rtl-video-duration {
    left: 0.7vw;
  }
}

@media (max-width: 576px) {
  .video-duration-cont {
    top: 40px;
  }

  .lms-video-image {
    width: 100px;
  }
}

.css-1yhpg23-MuiTableCell-root {
  text-align: -webkit-center !important;
}

.progress-bg {
  background-color: #B8B8B8 !important;
}

/* Analytics section */

.blue-analytics-con {
  display: flex;
}

.analytics-sharing {
  width: 20.83vw;
  height: 5.20vw;
  border-radius: 0.3125vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.save-icon-user {
  width: 3.22vw;
  margin: 0.98958vw;
}

.no-of-sharing {
  margin: 0.260vw;
}

.no-of-sharing h1 {
  color: var(--pri-font-color);
  font-size: 2.23vw;
  line-height: 2.4583vw;
  font-weight: 600;
  font-style: normal;
}


.no-of-sharing p {
  color: var(--pri-font-color);
  font-size: 0.833vw;
  line-height: 175%;
  font-weight: 400;
  font-style: normal;
}

.video-icon {
  width: 1.25vw;
}

.video-icon-container {
  height: 100%;
  padding: 0.625vw;
}

.admin-all-analytics h1 {
  color: var(--pri-font-color) !important;
}

.admin-all-analytics p {
  color: var(--pri-font-color) !important;
}

.user-blue-containers {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 5px;
  border: 1px solid #D2DBE3;
  background: #FFF;
}

.admin-all-analytics {
  margin: 0 0 1vw 0;
}

.user-blue-con {
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-radius: 5px;
  background: transparent;
  flex-wrap: wrap;
}

.white-circle {
  background-color: var(--light-white-color);
  border-radius: 50%;
  width: 3.38vw;
  height: 3.38vw;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.04vw;
}

.only-user path,
.only-user path {
  fill: var(--icons-color);
  stroke: var(--icons-color);
}

.only-user rect {
  stroke: var(--icons-color);
}

.analytics-status svg {
  width: 1.5625vw;
  height: 1.5625vw;
}

.analytics-status {
  background-color: var(--icons-color);
  border-radius: 5px;
  width: 24%;
  margin-bottom: 1.04vw;
  display: flex;
  align-items: center;
}

.analytics-status h1,
.analytics-status p {
  color: #ffffff;
}

.user-graph-con-1,
.user-graph-con-2 {
  width: 49.5%;
  height: 31.197vw;
  background: #FFFFFF;
  border: 1px solid #D2DBE3;
  border-radius: 0.3125vw;
}

.course-completion-rate {
  width: 100%;
  background: #FFFFFF;
  border: 1px solid #D2DBE3;
  border-radius: 0.3125vw;
  padding: 1.5625vw;
  margin-top: 1.04vw;
}

.user-analy-main,
.admin-analy-main {
  width: 100%;
  margin-top: 2.083vw;
  display: flex;
  justify-content: space-between;
}

.admin-graph-con-1,
.admin-graph-con-2 {
  width: 74.5%;
  height: 31.197vw;
  background: #FFFFFF;
  border: 1px solid #D2DBE3;
  border-radius: 0.3125vw;
}

.admin-course-analy-con-1 {
  height: 53.072vw;
  width: 24.5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-course-analy-con-2 {
  width: 29.5%;
  /* height:53.072vw; */
  background: #FFFFFF;
  border: 1px solid #D2DBE3;
  border-radius: 0.3125vw;
}

.admin-course-analy-con-2 {
  width: 74.5%;
}

.admin-course-box-1 {
  width: 24%;
}

.admin-course-box-1,
.admin-course-box-2 {
  height: 30.197vw;
  background: #FFFFFF;
  border: 1px solid #D2DBE3;
  border-radius: 0.3125vw;
}

.admin-course-box-2 {
  height: 30.197vw;
  width: 74.8%;
}

@media (max-width:991px) {
  .analytics-status {
    width: 49%;
    margin-bottom: 20px;
  }

  .analytics-status svg {
    width: 30px;
    height: 30px;
  }

  .white-circle {
    width: 60px;
    height: 60px;
  }
}

@media (max-width:700px) {
  .analytics-status {
    width: 100%;
  }
}

.admin-graph-con-2 {
  width: 24.5%;
}

.admin-analy-main {
  margin-top: 1.083vw;
}

.heading-drop-con {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 1.16vw;
}
.admin-course-box-1 canvas {
  width: 100% !important;
}

.user-analy-drop-down {
  width: auto;
  background: #FFFFFF;
  border: 1px solid #062349;
  border-radius: 0.15625vw;
  padding: 0.425vw 1.041vw 0.425vw 0.52vw;
}

.heading-drop-con h1 {
  font-style: normal;
  font-weight: 500;
  font-size: 1.041vw;
  line-height: 1.45vw;
  color: #061E3E;
}

.user-analy-drop-down option,
select {
  font-style: normal;
  font-weight: 400 !important;
  font-size: 0.72vw !important;
  line-height: 0.88vw;
  color: #062349 !important;
  border: 1px solid #062349 !important;

}

.admin-row-1,
.admin-row-2 {
  border-radius: 6px;
  border: 1px solid #D2DBE3;
  background: #FFF;
  height: 49%;
}

.latest-analytics-con {
  height: 1092px;
  ;
}

.admin {
  width: 16.770vw;
  height: 3.85vw;
  background-color: #ffffff !important;
}

.admin-analytics-col-1,
.admin-analytics-col-2 {
  width: 49.3%;
}

.admin-analytics-col-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-analytics-col-2 {
  background-color: var(--icons-color);
  border-radius: 6px;
  border: 1px solid #D2DBE3;
  width: 49.3%;
  padding: 2vw;
}

.analytics-search {
  width: 1.25vw;
  height: 1.25vw;
}

.admin-ana-inp-con input {
  padding: 0 2vw 0 1vw !important;
  color: var(--light-white-color) !important;
}

.admin-ana-inp-con {
  padding-bottom: 1vw;
  border-bottom: 1px solid #D2DBE3;
}

.admin-statistics {
  background-color: rgba(210, 219, 227, 0.2);
  width: 49%;
  border-radius: 6px;
}

.admin-icon-circle {
  width: 3.38vw;
  height: 3.38vw;
  border-radius: 50%;
  margin: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light-white-color);
}

.admin-icon-circle svg {
  width: 1.56vw;
  height: 1.56vw;
}

.admin-stat-content h1 {
  color: var(--light-white-color);
  font-size: 1.979vw;
  font-weight: 600;
  line-height: 1.45vw;
}

.admin-stat-content p {
  color: var(--light-white-color);
  font-size: 0.833vw;
  font-weight: 600;
  line-height: 1.45vw;
  margin-top: 0.3vw;
}

.blue-con-row {
  border-radius: 6px;
  border: 1px solid #D2DBE3;
  margin-top: 1vw;
  height: 40%;
}

.admin-analytics-tab {
  padding: 0 !important;
  color: var(--pri-font-color) !important;
  font-family: var(--font-family) !important;
  opacity: 0.5;
  font-weight: 600 !important;
  width: max-content !important;
  min-width: unset !important;
  max-width: unset !important;
}

.analytics-right-padd {
  margin-right: 4.16vw !important;
}

.analytics-left-padd {
  margin-left: 4.16vw !important;
}

.admin-analytics-tab.Mui-selected {
  opacity: 1;
}

.admin-analytics-tabs .MuiTabs-indicator {
  background-color: var(--pri-font-color) !important;
}

#simple-tabpanel-8 {
  margin-top: 1vw;
}

.admin-search-img {
  width: 50%;
  opacity: 0.76;
  position: relative;
}

.admin-search-img img {
  width: 1.25vw;
  height: 1.25vw;
  margin: 0px 2vw;
  position: absolute;
}

.admin-course {
  border-bottom: 0.5px solid #D2DBE3;
  padding: 1.5vw;
}

.stat-container {
  padding: 2.96vw 1.5625vw;
  width: 100%;
}

.svg-item {
  background-color: #F5F7F9;
  border-radius: 50%;
  width: 4.791vw;
  height: 4.791vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timer-icon {
  width: 3.020vw;
  height: 3.020vw;
}

.svg-icon-content p,
h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 0.83vw;
  line-height: 1.45vw;
  color: #061E3E;
}

.svg-icon-content h2 {
  font-style: normal;
  font-weight: 600;
  font-size: 1.875vw;
  margin-top: 0.5vw;

}

.video-text {
  margin: 1.04vw 1.6vw 0 1.6vw;
}

.video-upload-btn {
  margin: 0 1.6vw 3vw 1.6vw;
}

.video-text-area {
  margin-top: 1vw !important;
}

.svg-item-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.svg-icon-content {
  width: 66%;
}

.item-container {
  margin: 0px 1.6vw;
}

.statistics-items {
  height: 12vw;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.admin-table {
  width: 86%;
}

.ad-table-rtl {
  margin: 1.3vw 0 0 3.125vw;
}

.ad-table-ltr {
  margin: 1.3vw 3.125vw 0 0;
}

.profile-con {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.profile-con h2 {
  margin: 0;
  width: 80%;
}

.admin-table-item {
  padding: 1.041vw 0;
}

.admin-analy-act {
  color: #278A42;
}

#progress-bar {
  vertical-align: middle;
}

progress[value] {
  height: 7px;
  border-radius: 5px;
}

progress::-webkit-progress-bar {
  background-color: #ffffff;
  border-radius: 5px;
}

progress::-moz-progress-bar {
  background-color: transparent;
  border-radius: 5px;

}

progress[value]::-webkit-progress-value {
  background-color: #EB7736;
  border-radius: 5px;
}

.progress-con {
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: space-between;
}

.progress {
  margin-bottom: 1.04vw !important;
}

.active-courses {
  float: right;
}

.active-courses h2 {
  margin: unset;
}

.admin-header h2 {
  margin-bottom: 1.6vw;
}

.menu-res {
  position: absolute;
  bottom: 0;
  top: 0;
  overflow: hidden;
  width: 106%;
  left: -150%;
  right: -150%;
  height: 100vh;
  transition: .5s ease;
  z-index: 1;
  display: flex;
}

.menu-right {
  right: -30px;
}

.menu-left {
  left: -30px;
}

.user-analytics-section {
  width: 98.5%;
  margin-top:10rem;
}

@media (max-width: 991px) {

  .tran-left {
    left: -20px;
    right: -20px;
  }

  .tran-right {
    right: -20px;
    left: -30px;
  }

  .menu-cont-1 {
    width: 70%;
    padding: 15px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .menu-cont-2 {
    width: 30%;
    background-color: rgba(0, 0, 0, 0.64);
  }

  .menu-close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }

  .res-menu-heading a {
    font-size: 20px;
    line-height: 150%;
    font-weight: 600;
    color: var(--pri-font-color);
    margin: 0;
  }

  .res-menu-heading p {
    font-size: 16px;
    line-height: 150%;
    font-weight: 600;
    color: var(--pri-font-color);
  }

  .Res-menu-profile {
    display: flex;
    align-items: center;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.22);
    padding: 50px 20px 30px 20px;
  }

  .menu-res-image img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }

  .menu-res-image {
    width: 100px;

  }

  .menu-res-img {
    width: 90%;
    padding: 20px 0px;
  }

  .menu-res-img h1 {
    font-size: 16px;
  }

  .menu-res-svg {
    width: 35px;
    display: flex;
  }

  .menu-res-ul {
    padding: 40px 20px;
  }

  .res-logout {
    border-top: 0.5px solid rgba(0, 0, 0, 0.22) !important;
    ;
  }

  .analytics-sharing {
    width: 44vw;
    margin-bottom: 20px;
    height: auto;
  }

  .user-blue-containers {
    flex-wrap: wrap;
  }

  .heading-drop-con {
    padding: 10px;
  }

  .user-analy-drop-down option,
  select {
    font-size: 16px !important;
    padding: 10px;
  }

  .no-of-sharing h1 {
    font-size: 30px;
    line-height: 100%;
  }

  .no-of-sharing p {
    font-size: 12px;
  }

  .video-icon {
    width: 24px;
  }

  .no-of-sharing {
    margin: 0px 15px;
  }

  .heading-drop-con h1 {
    font-size: 16px;
    line-height: normal;
  }

  .save-icon-user {
    width: 50px;
  }

  .analytics-sharing,
  .analytics-status {
    padding: 14px 20px;
    border-radius: 6px;
  }

  .user-analy-main {
    flex-direction: column;
  }

  .user-graph-con-1,
  .user-graph-con-2 {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media (max-width: 700px) {
  .blue-analytics-con {
    width: 100%;
  }

  .analytics-sharing {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .res-menu-heading a {
    font-size: 16px;
  }

  .res-menu-heading p {
    font-size: 12px;
  }

}

.canvas-analy {
  display: flex;
  align-items: center;
  width: 100%;
  height: 25.197vw;
  justify-content: center;
}

.canvas-analy span {
  display: table;
  margin: 0 auto;
}

.canvas-doughnut-admin {
  margin: 0 auto;
  height: 410px;
  width: 500px;
}

.canvas-doughnut-all {
  margin: 0 auto;
  height: 347px;
  width: 304px;
}

.canvas-line-all {
  margin: 0 auto;
  height: 436px;
  width: 890px;
}


.canvas-analy canvas {
  margin: 0 auto;
  width: 76.5%;
  height: 34.197vw;
}

/* Global Search */
#global-search {
  position: relative;
  border: unset;
  padding: 0px 2vw;
  font-size: 0.9375vw;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.85px;
  color: var(--pri-font-color);
  background-color: transparent;
}

.globle-search-icon {
  position: absolute;
  width: 1.25vw;
  height: 1.25vw;
  margin: 0px;
}


.global-search-tab {
  font-size: 0.72vw !important;
  padding: 0.2vw 0.5vw 0.5vw 0px !important;
  align-items: flex-start !important;
  font-family: var(--font-family) !important;
  width: auto !important;
  max-width: none !important;
  min-width: unset !important;
  min-height: 1.3vw !important;
}

.global-left-padd {
  margin-left: 1.04vw !important;
}

.global-right-padd {
  margin-right: 1.04vw !important;
}

.global-search-tabs {
  min-height: 1.5vw !important;
  border-bottom: 0.5px solid #DFDFDF !important;
  padding: 0.78vw 2.60vw 0px 2.60vw;
}

.global-list-con {
  padding: 1.56vw;
  height: 29vw;
  overflow: scroll;
}

.btn-model-close {
  width: 0.67vw !important;
  height: 0.67vw !important;
}

.globle-head h1 {
  font-size: 0.93vw;
  margin: 0px 0.7vw;
}

.globle-search-main-icon {
  width: 1.25vw;
  height: 1.25vw;
  position: absolute;
}

.globle-head {
  padding: 1.04vw 1.56vw 1.04vw 0.5vw;
  border-bottom: 0.5px solid #DFDFDF !important;
}

.globle-list-head {
  background: #F5F7F9;
  padding: 0.5vw 1.04vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.globle-list-head h1,
.globle-list-item h3 {
  font-size: 0.72vw;
}

.globle-list-head p {
  font-size: 0.62vw;
  opacity: 0.5;
  cursor: pointer;
}

.globle-list-head p:hover {
  opacity: 1;
  font-weight: 700;
}

.globle-list-item p {
  font-size: 0.625vw;
}

.globle-list-btn {
  margin: 0 !important;
  padding: 10px 20px !important;
}

.people-btn {
  padding: 5px 8px;
  border-radius: 3px;
  font-size: 0.4vw;
  margin: 0px 20px;
}

.is-shared-by-you {
  background: rgba(196, 32, 22, 0.13);
}

.is-shared-with-you {
  background: rgba(36, 196, 22, 0.13);
}

.globle-inp::placeholder {
  color: var(--pri-font-color) !important;
  opacity: 0.555 !important;
}

.globle-inp::-moz-placeholder {
  color: var(--pri-font-color) !important;
  opacity: 0.555 !important;
}

.globle-inp::-webkit-input-placeholder {
  color: var(--pri-font-color) !important;
  opacity: 0.555 !important;
}

.collection-suggestions {
  padding: 0px 1vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.collection-suggestions .suggestions {
  opacity: 0.7;
}

.collection-suggestions .suggestions:hover {
  opacity: 1;
}

@media (max-width:991px) {
  .globle-inp {
    display: none;
  }

  .globle-search-icon {
    width: 18px !important;
    height: 18px !important;
    margin: 0px;
    left: 2%;

  }

  .globle-search-main-icon {
    position: unset;
    width: 24px;
    height: 24px;
  }

  #global-search-modal {
    width: 90%;
    height: 90vh;
    right: 0%;
    left: 5%;
  }

  #global-search {
    font-size: 18px;
    padding: 17px;
  }

  .btn-model-close {
    width: 13px !important;
    height: 13px !important;
  }

  .globle-head {
    padding: 0px 20px;
  }

  .global-search-tab {
    font-size: 12px !important;
  }

  .global-right-padd {
    margin-right: 40px !important;
  }

  .global-left-padd {
    margin-left: 40px !important;
  }

  .global-search-tabs {
    min-height: 20px;
    padding: 17px 17px 0px 17px;
  }

  .global-search-tabs .MuiTabs-scroller {
    height: 20px !important;
  }

  .globle-list-btn {
    padding: 15px 0px !important;
  }

  .global-list-con {
    padding: 17px 0px !important;
    height: 75vh;
  }

  .globle-list-head h1,
  .globle-list-item h3,
  .globle-list-item p,
  .globle-list-head p {
    font-size: 12px;
  }

  .people-btn {
    font-size: 8px;
  }

  .globle-list-head {
    padding: 10px 17px;
  }

  .globle-list-item {
    padding: 0px 17px;
  }
}

/* Quiz creator */

.quiz-creator-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.56vw 1.40vw 0px 1.19vw;
}

.quiz-creator-con h3,
.new-quiz-con h3 {
  font-size: 0.93vw;
  line-height: 1.145vw;
  font-weight: 700;
}

.quiz-creator-con img,
.new-quiz-con img {
  width: 0.8vw;
}

.quiz-text-input {
  margin-top: 1vw;
  padding: 0px 1.40vw 0px 1.19vw;
}

.new-quiz-list {
  height: max-content;
  overflow-y: auto;
}

.quiz-text-input input {
  width: 100%;
}

.quiz-select {
  width: 49% !important;
  display: flex;
  flex-direction: column;
}

.quiz-selection-con {
  margin-top: 1vw;
  padding: 0px 1.40vw .08vw 1.19vw;
}

.quiz-select label,
.quiz-select p,
.quiz-text-input label {
  margin-bottom: 0.72vw;
  font-weight: 500;
  font-size: 0.8vw;
}

.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-title p {
  font-size: 0.83vw;
}

.add-question-btn {
  padding: 0vw 1.40vw 0vw 1.19vw;
}

.quiz-checkbox {
  padding: 0px 1.40vw .08vw 0.8vw;
}

.add-question-btn .sec-btn {
  width: 100% !important;
  padding: 0.8vw 0px !important;
}

.quiz-checkbox label {
  margin: 0 !important;
}

.quiz-text-input input {
  width: 100%;
}

.b-0 {
  border: none;
}

.check-box-label {
  margin-right: 2.67vw !important;
}

.check-box-label span {
  color: #979797;
  font-size: 0.72vw;
}

.options-container {
  padding: 0px 1.19vw 1.19vw 1.19vw;
}

.questions-list-con {
  padding: 1vw 1.19vw 0px 1.19vw;
  border-bottom: 0.5px solid #D2DBE3;
}

.options-container h1,
.question-title {
  font-size: 0.83vw;
  font-weight: 500;
  margin-bottom: 0.5vw;
}

.input-option input {
  width: 100%;

}

.input-option {
  width: 94%;
  display: flex;
  align-items: center;
}

.option-item {
  width: 100% !important;
  margin-bottom: 0.543vw;
}

.option-icons {
  position: absolute;
  background-color: transparent;
  padding: 0.5vw 0.833vw;
  right: 7%;
}

.options-container h2 {
  font-size: 0.83vw;
  color: #043677;
  font-weight: 500;
  margin: 0px 0.5vw;
  cursor: pointer;
}

.save-next-btn,
.save-publish-btn {
  font-size: 0.93vw;
  padding: 0.72vw 0.625vw;
  font-weight: 500;
  background-color: var(--icons-color);
  color: var(--sec-font-color);
  width: unset !important;
  margin: 1vw 0 0 1vw !important;
}

.save-next-btn {
  margin: 3vw 0px 1.5vw 0px;
}

.save-publish-btn {
  margin: 1.5vw 0px;
}

.question-list-item {
  padding: 0px 1.19vw 1.19vw 1.19vw;
}

.quiz-selection-con legend {
  display: none !important;
}

.quiz-select .MuiSelect-select {
  padding: 0.4vw 1.7vw 0.7vw 0.7vw !important;
  font-size: 0.83vw !important;
}

.input-option input,
.quiz-text-input input[type="text"] {
  padding: 0.6vw 0.833vw;
  font-size: 0.83vw;
}

.op-item-l {
  padding-left: 2.83vw !important;
}

.op-item-r {
  padding-right: 2.83vw !important;
}

.op-edit-r {
  padding-right: 7.53vw !important;
}

.op-edit-l {
  padding-left: 7.53vw !important;
}

@media (max-width:1600px) {
  .op-edit-r {
    padding-right: 8.53vw !important;
  }

  .op-edit-l {
    padding-left: 8.53vw !important;
  }
}

.quiz-select svg {
  width: 1.9vw !important;
}

.select-text {
  font-size: 0.83vw !important;
}

.select-cat-wid p {
  font-size: 0.83vw;
}

.select-cat-wid svg {
  width: 1.25vw;
  /* height: 1.25vw; */
}

.quiz-checkbox svg {
  width: 1.2vw !important;
  /* height: 1.2vw !important; */
}

.option-icons svg,
.option-item svg,
.options-container svg {
  width: 1.3vw !important;
}

.option-icons svg {
  fill: var(--icons-color);
}

.option-icons img {
  width: 0.9vw;
}

.quiz-del-icon svg {
  width: 1.3vw !important;
}

.quiz-del-icon {
  padding: 0 0.8vw !important;
}

/* Add New Quiz */

.new-quiz-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.562vw;
}

.videoEdit {
  margin-bottom: 1vw;
  padding: 0.5vw 1.4vw;
  border-radius: 4px;
  display: flex !important;
}

.newQuizLinkItem {
  display: flex !important;
}

.newQuizLinkItem,
.link-inp-con,
.course-edit {
  border: 0.5px solid #D2DBE3 !important;
  margin-bottom: 1vw;
  padding: 0.5vw 1.4vw;
  border-radius: 4px;
}

.new-quiz-thumb {
  padding: 0px 1.4vw;
}

.name-the-course {
  width: 16.5vw;
}

.name-the-course h2 {
  font-size: 0.83vw;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.new-quiz-thumb img {
  width: 4.61vw;
}

.new-quiz-list {
  padding: 0px 1.56vw;
}

.new-quiz-ver-bars img {
  width: 0.88vw;
}

.modal-pop .MuiBackdrop-root {
  opacity: 0.05 !important;
  background-color: #000 !important;
}

.new-quiz-edit-btn {
  border-left: 1px solid #D2DBE3;
  border-right: 1px solid #D2DBE3;
  padding: 0.6vw 1.30vw;
  margin: 0px 1.30vw;
}

.link-inp-con svg {
  width: 0.98vw;
}

.quiz-icon-con svg{
  width: unset;
}

.document-icon-con img {
  width: 2.39vw;
}

.document-icon-con {
  padding: 0px 1.5vw;
}

.link-inp-con input {
  padding: 0;
}

.link-inp-con input {
  border: none;
  font-size: 0.8vw;
}

.link-inp-con {
  margin: 0px 1.6vw;
}

.slick-next,
.slick-prev {
  top: 30% !important;
  width: 1vw !important;
  height: 1vw !important;
}

.slick-next:before,
.slick-prev:before {
  font-size: 1vw !important;
}

/* User Quiz */

.user-quiz-container {
  width: 100.5%;
  height: 100%;
  background-color: #ffffff;
}

.question-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.question-con {
  padding: 1.03vw 2.08vw;
}

.user-quiz-header {
  margin: 0.233vw;
  padding: 1.43vw 2.08vw;
  background-color: #F5F7F9;
}

.user-quiz-progress {
  padding: 0 2.08vw;
}

.user-quiz-header h3 {
  font-size: 1.04vw;
  margin: 0px;
  font-weight: 600;
}

.user-quiz-header h2 {
  margin: 0px;
  font-size: 0.93vw;
}

.quiz-question-con {
  width: 40%;
  margin-top: 2vw !important;
}

#question-title h2,
.user-quiz-container h6 {
  font-size: 0.93vw;
  margin: 0px;
  font-weight: 500;
  line-height: 133%;
  color: var(--pri-font-color);
}

#question-title {
  margin-bottom: 1.5vw;
}

.user-quiz-btn-group {
  margin: 1.5vw;
}

.quiz-cancel-btn,
.quiz-cancel-btn-1 {
  margin: 0px 1vw;
  background: rgba(4, 54, 119, 0.06);
  padding: 0.625vw 1.5vw;
}

.quiz-cancel-btn-1 {
  padding: 0.625vw 3vw;
}


.MuiRadio-root {
  padding: 0.5vw !important;
}

.question-container svg {
  width: 1.04vw !important;
  height: 1.04vw !important;
  fill: var(--icons-color);
}

.submission-con {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.score-card-one p {
  width: 100% !important;
}

.success-icon img {
  width: 2.29vw;
}

.success-icon {
  margin-top: 1vw;
}

.submission-con h1 {
  font-size: 1.56vw;
  font-weight: 500;
  line-height: 1.45vw;
  margin-top: 1vw;
}

.score-container {
  margin-top: 1.344vw;
  text-align: center;
}

.score-container-1 {
  margin-top: 0.844vw;
  text-align: center;
}

.score-container p {
  font-size: 0.88vw;
  font-weight: 500;
  width: 60%;
}

.scrore-percentage {
  display: flex;
  align-items: center;
  justify-content: center;

}

.scrore-percentage p {
  width: unset !important;
}

.result-alert p {
  font-size: 0.88vw;
  font-weight: 500;
  width: unset !important;
}

.result-alert {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(139, 238, 196, 0.2);
  margin-bottom: 10px;
  padding: 0.5vw;
}

.res-failed {
  background-color: rgba(255, 141, 175, 0.1);
}

.result-alert svg {
  margin: 0 10px;
  fill: transparent !important;
  width: 1.04vw !important;
}

.score-container .quiz-desc-con {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3vw;
}

.submission-con h3,
.submission-con h6 {
  font-size: 1.85vw;
  font-weight: 700;
  line-height: 89%;
  margin: 0.95vw 0px 0.8vw 0px;
}

.submission-con h3 {
  margin: 0 0.6vw;
}

.correct-continer {
  text-align: center;
}

.result-con {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35%;
  margin-top: 1vw;
}

.result-con img {
  width: 0.74vw;
}

.result-con h3,
.result-con h6 {
  font-size: 0.833vw;
  font-weight: 700;
  line-height: 169%;
  margin: 0.5vw 0px;
}

.result-con p {
  font-size: 0.72vw;
  font-weight: 500;
  line-height: 89%;
}

.result-btn-group {
  margin: 1.74vw 0 1.04vw 0px;
}

.result-btn-group button {
  font-size: 0.93vw;
}

@media (max-width:991px) {

  .user-quiz-btn-group button,
  .result-btn-group button {
    width: 114px !important;
    font-size: 14px;
    height: 36px !important;
    padding: 0px !important;
  }

  .submission-con h3 {
    margin: 0 10px;
  }

  .quiz-cancel-btn {
    margin: 0px 20px;
  }

  .user-quiz-btn-group {
    margin: 20px;
  }

  .user-quiz-header {
    padding: 20px;
  }

  .user-quiz-header h3,
  #question-title h2,
  .user-quiz-container h6 {
    font-size: 16px;
  }

  .user-quiz-header h2 {
    font-size: 14px;
  }

  .question-container svg {
    width: 20px !important;
    height: 20px !important;
  }

  .MuiRadio-root {
    padding: 10px !important;
  }

  .quiz-question-con {
    width: 100%;
    padding: 20px !important;
    margin-top: 20px !important;
  }

  .success-icon img {
    width: 38px;
    margin-top: 20px;
  }

  .submission-con h1 {
    font-size: 18px;
    line-height: 178%;
  }

  .score-container p,
  .result-alert p {
    font-size: 14px;
  }

  .score-container h6,
  .score-container h3 {
    font-size: 28px !important;
  }

  .result-con img {
    width: 15px;
    height: 15px;
  }

  .correct-continer h3,
  .correct-continer span {
    font-size: 16px !important;
  }

  .result-con p {
    font-size: 14px;
  }

  .score-container {
    margin-top: 30px;
  }

  .submission-con h3,
  .submission-con h6 {
    margin: 10px 0px;
  }
}

@media (max-width:576px) {
  .user-quiz-container {
    position: unset;
  }

  .user-quiz-container {
    padding: 0px;
  }

  .score-container p {
    width: 100%;
  }

  .submission-con {
    padding: 20px;
  }

  .user-quiz-header h3 {
    font-size: 12px;
  }

  #question-title h2,
  .user-quiz-container h6 {
    font-size: 14px;
  }

  .user-quiz-header h2 {
    font-size: 10px;
  }

  .result-con {
    width: 60%;
    margin-top: 30px;
  }

  .user-quiz-header {
    padding: 11px 13px;
  }

  .user-quiz-container {
    margin-bottom: 60px;
    height: 100%;
  }

  .submission-con {
    justify-content: unset;
  }

  .result-btn-group {
    margin-top: 30px;
  }
}

/* Course Edit  */
.edit-inp-con {
  padding: 0.5vw 1.5vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.edit-inp-con input[type = 'text'] {
  border: 0 !important;
  height: max-content !important;
  padding: 0.7vw 1vw;
  font-size: 1.04vw !important;
}
.edit-inp-con{
  margin-top: 0.5vw;
}
.edit-inp-con .vertical-form-controll > div > div::before,
.courseEditDesc,
.edit-video-text-area textarea{
  border: none !important;
}
.courseEditDesc,
.edit-inp-con input[type = 'text'],
.catagory-con .MuiSelect-select,
.course-points,
.edit-video-text-area textarea{
  background-color: #f3f3f3 !important;
}
.catagory-con .MuiSelect-select{
  padding: 0.6vw 1vw !important;
  font-size: 0.8vw !important;
  min-height: unset !important;
  line-height: 160% !important;
}

.edit-inp-con svg{
  margin: 0 1vw;
}
.catagory-con fieldset,
.catagory-con #outlined-basic{
  border: 0 !important;
}

.edit-inp-con label {
  font-size: 1.04vw;
  line-height: normal !important;
  color: var(--pri-font-color);
  font-family: var(--font-family);
  margin-bottom: 0.7vw;
}

.edit-modal-pop .MuiBackdrop-root {
  opacity: 0.6 !important;
}

.course-points{
  border: 0 !important;
  padding: 0.6vw 1vw;
  border-radius: 5px;
  font-size: 0.8vw;
}
.course-points-tags > div,
.course-points-tags .vertical-form-controll{
  width: 32% !important;
}
.course-points-tags label,
.video-tilte-table {
  font-size: 0.79vw !important;
  font-weight: 400;
  font-family: var(--font-family);
}

.course-edit-inp {
  border: 0 !important;
  border-bottom: 1px solid #D2DBE3 !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;


}

.course-edit-inp::placeholder {
  color: var(--pri-font-color);
  font-weight: 600;
}

.newQuizLinkItem .course-edit-inp,
.course-edit .course-edit-inp {
  font-size: 0.886vw !important;
}

.videoEdit {
  font-size: 0.886vw !important;
}

.edit-inp-con svg {
  position: absolute;
  right: 7%;
}

.catagory-con {
  padding: 0.9vw 1.5vw;
}

.catagory-con .vertical-form-controll {
  margin: 0 !important;
}

.newQuizLinkItem h4,
.course-edit h4 {
  font-size: 0.83vw;
  opacity: 0.5;
}

.drag-n-drop-svg,
.drag-n-drop-svg rect {
  width: 31px;
  height: 31px
}

.videoEdit h4 {
  font-size: 0.83vw;
  opacity: 0.5;
}

.video-update-btn {
  cursor: not-allowed;
}

.video-edit-documents-list {
  display: flex;
  margin-top: 0.087vw;
  gap: 1.5vw;
}

.video-edit-documents-list .video-upload-docs li{
  width: 9.87vw;
  list-style-type: none;
}

.spinner-video-update {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.newQuizLinkItem textarea,
.course-edit textarea {
  font-size: 0.72vw;
  line-height: 0.88vw;
  height: 11.09vw !important;
}

.videoEdit textarea {
  font-size: 0.72vw;
  line-height: 0.88vw;
  height: 11.09vw !important;
}

.course-edit-text-area {
  margin-top: 2.03vw;
}

.border-unActive {
  border: none !important;
}

.course-edit-drag {
  margin-top: 1.125vw;
}

.course-edit {
  display: block !important;
}

.collapsed-con {
  padding: 0px 2vw;
}

.document-icon-heading h3 {
  font-size: 0.833vw;
  margin: 0;
  padding: 0.8vw 1vw;
}

/* Admin message for Mobile Access */

.alert-close-icon {
  display: flex;
  justify-content: end;
}

.alert-close-icon svg {
  margin: 20px;
  width: 16px;
  height: 16px;
}

.admin-alert-mess {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.admin-alert-mess span {
  margin-top: 15px;
  font-size: 16px;
  width: 70%;
}


.privacy-policy-section,#terms-and-conditions {
  padding: 3vw 10.41vw;
}
.privacy-policy-section :is(h1, h2, summary),
#terms-and-conditions :is(h1, h2,){
  font-size: 22px;
  line-height: normal;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.privacy-policy-section h1,
#terms-and-conditions h1 {
  font-weight: 700;
}
#terms-and-conditions a{
  color: #0582ff;
}
.privacy-policy-section h2 {
  margin: 5px 0 20px 0;
}
#terms-and-conditions h2{
  margin: 15px 0 10px 0;
}
#terms-and-conditions ul{
  padding: 5px 20px;
}
#terms-and-conditions ul>li{
  list-style-type: disc;
}
.privacy-policy-section :is(p, li),
#terms-and-conditions :is(p, li){
  font-size: 16px;
  line-height: 180%;
  letter-spacing: 0.5px;
  margin-top: 10px;
  font-weight: 500;
}
.privacy-policy-section details {
  margin-top: 40px;
}
.privacy-policy-section li {
  list-style-type: disc;
}
.privacy-policy-section a {
  color: #0582ff;
}
.privacy-policy-section ol li {
  list-style-type: none !important;
}

.privacy-policy-section ol {
  padding: 0 !important;
}

.privacy-policy-section :is(ol, ul) {
  margin: 15px 0 5px 40px;
}

@media (max-width:991px) {
  .privacy-policy-section,
  #terms-and-conditions {
    padding: 20px;
  }

  .privacy-policy-section :is(p, li),
  #terms-and-conditions :is(p, li) {
    font-size: 14px;
  }
}

/* User Groups */

.add-svg-icon {
  width: 0.8vw !important;
  height: 0.8vw !important;
  opacity: 0.3;
  margin-left: 0.5vw;
}

.add-user-con:hover .add-svg-icon {
  opacity: 1;
}

.add-svg-icon path {
  fill: var(--icons-color);
}

.create_group {
  border: none !important;
  border-bottom: 2px solid Var(--light-violet) !important;
  margin-bottom: 1.54vw;
  border-radius: unset !important;
  padding-left: 0 !important;
  font-weight: 700;
}

.group_list_handel {
  height: 11.8vw;
}

.create-group input[type="text"] {
  padding-left: 3vw;
}

.search-image::placeholder {
  color: var(--pri-font-color);
}

.groupSearch img {
  margin: 0 1vw !important;
}

.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}

.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

@media (max-width:1100px) {
  .group_list_handel {
    height: 10.8vw;
  }
}

.manage_groups .react-tabs__tab-list {
  display: flex;
  padding: 0 !important;

}

.manage_groups .react-tabs__tab-list li {
  margin-right: 1.5vw !important;
  border: 0 !important;
  padding: 0 !important;
  display: unset !important;
}

.manage_groups .react-tabs__tab--selected:after {
  margin-top: 4px !important;
}

.group_list-item {
  padding: 0.8rem 2vw !important;
  cursor: pointer;
}

.floating-btns {
  position: absolute;
  bottom: 1.5vw;
}

.left-float {
  left: 0;
}

.right-float {
  right: 0;
}

.group_list-item:hover {
  background: #F5F7F9;
  border: 1px solid #d2dbe3;
}

.admin-share-user-left .share-title {
  padding-left: 2vw;
}

.left-side-group {
  padding: 0 2vw;
}

.manage_groups .react-tabs {
  margin-top: 2vw;
}

.manage_groups .react-tabs__tab-list {
  padding: 0 2vw !important;
}

.user_heading {
  padding: 1vw 2vw 0.5vw 2vw !important;
}

.right_side_list {
  cursor: pointer;
  padding: 0 2vw !important;
  margin-bottom: 0.5vw;
}

.right_side_list:hover {
  background-color: #fff;
}

.groupSearch input[type="text"] {
  padding: 0.625vw 2.724vw 0.625vw 2.724vw;
  background-color: #fff;
}

input.error {
  border-color: red;
}

textarea.error {
  border: solid 1.2px red;
}

textarea.normal {
  border: solid 1.2px var(--form-color);
}

textarea:focus {
  border: 1px solid var(--sec-bg-color);
}

.error-label {
  color: red;
  padding: 0.5vh;
}
.info-label {
  color: var(--icons-color);
  margin-top: 0.6vw;
}

.right_con_group_inp {
  padding: 0px 2vw;
  margin-bottom: 1vw;
}

.user_heading .react-tabs__tab {
  margin-bottom: 0.5vw;
}

.admin-share-user-right .share-buttons {
  padding: 1vw 2vw 0vw 2vw;
}

.manage_group {
  justify-content: unset !important;
}

.manage_group span {
  margin-right: 1vw;
}

.manage_group svg {
  width: 1.46vw !important;
  height: 1.46vw !important;
}

.manage_group:hover {
  background: #F5F7F9;
  cursor: pointer;
}

/* Admin Settings */

.admin-settingsIcon {
  max-width: max-content !important;
  min-width: max-content !important;
  position: absolute !important;
}

.col-tab .MuiTabs-flexContainer {
  align-items: center;
}

.left-setting-con {
  left: 0;
}

.right-setting-con {
  right: 0;
}

.setting_tab_i {
  width: 0.989vw !important;
  margin: 0px 1vw !important;
}

.setting-icon-tab h3 {
  font-size: 0.93vw;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.setting-icon-tab .items-con {
  margin-top: 1vw;
  align-items: center;
}

.radio-button-con {
  width: 100%;
  background: #fff;
  margin-top: 2vw;
  display: flex;
  align-items: center;
  padding:1vw 2vw;
}
.tenent-setting-icon{
  margin: 6vw 0 4vw 0;
}
.setting-icon-tab .admin-navbar div,
span {
  color: var(--icons-color);
}

.setting-icon-tab .MuiFormControlLabel-label {
  color: var(--pri-font-color);
}

.setting-icon-tab p {
  opacity: 0.8;
  font-size: 0.83vw;
  margin-right: 1vw;
}


/* Page Not Found */

.page-not-found-con {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #ffffff;
}

.page-not-found-con h1 {
  font-size: 11.3vw;
  font-weight: 400;
  line-height: normal;
}

.page-not-found-con h2 {
  font-size: 2.89vw;
  margin-bottom: 2vw;
  font-weight: 400;
  line-height: normal;
}

.page-not-right-con {
  text-align: center;
  width: 25%;
  margin: 0px 4vw;
}

@media (max-width:991px) {
  .page-not-found-con {
    flex-direction: column;
    padding: 20px;
    justify-content: flex-start;
  }

  .page-not-right-con {
    width: 100%;
    margin-top: 30px;
  }

  .page-not-found-con h2 {
    font-size: 20px;
  }

  .page-not-found-con h1 {
    font-size: 58px;
  }
}


.share-course-drop-down .admin-share-user-left {
  padding: 0px 2vw;
}

.share-course-drop-down .react-tabs__tab-list {
  padding: 0 2vw;
}

.main-drop-dwn-tilte {
  padding: 1.4vw 2vw 1.4vw 2vw !important;
}

.share-course-drop-down .admin-share-user-right, .managment-container .certificate-share-right {
  padding: 2vw 2vw 0vw 2vw;
  margin-top: -7.5vw;
}

.managment-container .certificate-share-right{
  margin-top: -5.9vw;
}

.certificate-share-right .share-through-con .share-title, .managment-container .certificate-share-right .share-title{
  padding: 1vw 0 !important;
}

.certificate-share-right .admin-share-con-user{
  display: none;
}

.certificate-share-right ul{
  min-height: 16vw !important;
}
.share-course-drop-down .admin-share-list {
  max-height: 24.70vw !important;
  min-height: 19vw !important;
}

.user-list{
  height: 26.87vw;
}

.user-addition{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #FFFFFF;
}

.share-course-drop-down .share-buttons {
  padding: 1vw 0 0 0;
}

@media (max-width:1400px) {
  .share-course-drop-down .admin-share-user-right {
    margin-top: -8.5vw;
  }
}


/* New Quiz and create Question */

.create-new-con {
  justify-content: center !important;
}

.quiz-and-question-tab {
  background-color: #ffffff;
  padding: 5px 0px;
  border: 1px solid #D2DBE3;
  width: 99%;
}

.quiz-and-question-tab .search-right .active::after {
  bottom: -5px;
}

.mui_select_con {
  line-height: normal !important;
}

.quiz-selection-con .MuiSelect-select {
  min-height: unset !important;
}

.select-four-layout {
  width: calc(25% - 0.5vw) !important;
}

#difficulty-level {
  padding: 0.63vw;
  border: 1px solid rgba(0, 0, 0, 0.23);
  font-size: 0.83vw;
  border-radius: 5px;
  margin-top: -3px;
}

.select-four-label {
  margin-top: 0.36vw !important;
}

#outlined-number {
  padding: 0.4vw 0.7vw 0.7vw 0.7vw !important;
}

.quiz-selection-con .MuiOutlinedInput-root {
  font-size: 0.83vw !important;
  line-height: normal !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input[type=number] {
  appearance: textfield;
}

.breadcrumbs {
  color: var(--pri-font-color) !important;
  font-size: 0.9375vw !important;
}

.breadcrumbs-first {
  font-weight: 700 !important;
}


.MuiMenuItem-root {
  font-size: 0.83vw !important;
}

.assign-quiz-close-con {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 9;
}

.assign-quiz-con .quiz-creator-con,
.assign-quiz-con .quiz-selection-con {
  padding: 0;
}

.questionBank-title h4 and p {
  font-size: 0.72lvw;
  font-weight: 600;
}

.questionBank-title h4 {
  font-weight: 600;
  margin-top: 1vw;
}

.questionBank-title h4,
.questionBank-title p {
  font-size: 0.83vw;
}

#question-bank-grid-search {
  border: 1px solid rgba(0, 0, 0, 0.23);
  padding: 0.5vw 2.5vw;
  border-radius: 0.3vw;
  font-size: 0.83vw;
}

.assign-quiz-con .search-img img {
  margin: 0px 0.9vw;
}

.quiz-multi-label {
  margin-bottom: 0.5vw !important;
}

.assign-to-quiz-list {
  width: 100%;
  margin-top: 1vw !important;
}

.assign-to-quiz-list h2,
.assign-to-quiz-list p,
.assign-to-quiz-list strong {
  font-size: 0.72vw;
  margin: auto;
}

.assign-to-quiz-list h2 {
  font-weight: 600;
}

.assign-to-quiz-list li {
  margin-bottom: 0.4vw !important;
  align-items: flex-start !important;
}
.assign-question-to-quiz li input{
  margin: 0;
}

.assign-to-quiz-list span {
  padding: 0.1vw 0.82vw 0 0.5vw !important;
}

.assign-quiz-con .share-buttons {
  background-color: white;
}

.assign-to-quiz-list svg {
  width: 1.04vw;
  height: 1.04vw;
}

.score-card-progress {
  height: 1.7vh;
}

.score-card-progress .progress-bar {
  font-weight: 500;
}

.score-card-progress .success {
  background-color: #8beec4;
  color: #043677;
}

.score-card-progress .error {
  background-color: #ff8daf;
  color: #043677
}

.score-card-progress .unattempt {
  background-color: #ffffff;
  color: #043677
}


.similar-videos {
  height: 28vh;
  overflow-y: scroll;
}

.similar-videos::-webkit-scrollbar {
  display: none;
}

.similar-videos ul {
  margin-top: 1vw;
  margin-bottom: 1vw !important;
}

.result-with-progress {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 1.5vw;
}

.progrees-div {
  width: calc(30% - 10px);
  background-color: #F2F6FB;
  padding: 0.83vw;
  border-radius: 5px;
}

.progrees-div:hover {
  border: 1px solid #FF8DAF;
  cursor: pointer;
}

.result-with-progress .MuiLinearProgress-bar {
  background-color: #8BEEC4 !important;
}

.progrees-div h2 {
  font-size: 0.83vw;
  font-weight: 600;
}

.progress-width {
  width: 90%;
}

.progrees-div h5 {
  font-size: 0.72vw;
  font-weight: 600;
  margin: 0 0.4vw;
}

.score-card-suggestions {
  background: rgba(86, 129, 239, 0.12);
  padding: 1.04vw;
  border-radius: 3px;
  border: 1px solid rgba(255, 141, 175, 1);
  margin-top: 1.04vw;
  width: 88%;
}

.score-card-suggestions ol {
  margin: 0;
}

.score-card-suggestions li {
  font-size: 0.83vw;
  color: var(--pri-font-color);
}

@media (max-width:991px) {
  .progrees-div h2 {
    font-size: 14px;
  }

  .score-card-suggestions li {
    font-size: 14px;
  }

  .progrees-div {
    padding: 15px;
  }

  .progrees-div h5 {
    font-size: 12px;
  }

  .submission-con {
    padding-bottom: 20px;
  }
}

@media (max-width:576px) {
  .result-with-progress {
    flex-wrap: wrap;
  }

  .progrees-div {
    width: 100%;
    margin: 0 0 10px 0 !important;
  }
}

.play-list-tab {
  font-size: 0.833vw !important;
  margin-bottom: 1vw !important;
  width: max-content;
  font-weight: 700 !important;
}

.play-list-tab::after {
  content: "";
  display: block;
  position: relative;
  width: 40%;
  border-bottom: 3px solid var(--pri-font-color);
  margin-top: 6px;
}

.grey-box-input input {
  background: lightgray;
}
.MuiTypography-root{
  font-size: 0.83vw !important;
  color: var(--pri-font-color) !important;
}
.quiz-table-row:hover,
.course-table-row:hover{
  background: #ffffff;
  border-radius: 0.58vw !important;
  box-shadow: 0px 2px 28px 0px rgba(0, 0, 0, 0.12);
}

.edit-quiz-popup h2 {
  font-size: 1.35vw;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  color: var(--pri-font-color);
}

.edit-quiz-popup p {
  font-size: 0.83vw;
  font-weight: 400;
  line-height: normal;
  color: var(--pri-font-color);
}

.edit-quiz-popup .tags-with-ellips p::before {
  content: "";
  width: 0.56vw;
  height: 0.56vw;
  border-radius: 50%;
  background-color: var(--icons-color);
  display: inline-block;
  margin: auto;
}

.editQuiz-left .tags-with-ellips p::before {
  margin-right: 0.5vw;
}

.editQuiz-right .tags-with-ellips p::before {
  margin-left: 0.5vw;
}

.list-inside p::before {
  width: 6px !important;
  height: 6px !important;
}

.list-inside p {
  background-color: rgba(210, 219, 227, 0.4);
  border-radius: 0.56vw;
  padding: 0 0.5vw;
  line-height: 145%;
}

.editQuiz-left p {
  margin-right: 1vw;
}

.editQuiz-right p {
  margin-left: 1vw;
}

.total-scrore span {
  color: rgba(151, 151, 151, 1);
}

.total-scrore p {
  font-weight: 600;
}

.assesment-tabs {
  font-size: 0.83vw !important;
}

.create-new-btn p::before {
  content: "+";
  font-size: 1.25vw;
  display: inline-block;
  line-height: normal;
  margin: 0 0.5vw;

}

.create-new-btn p {
  color: var(--pri-font-color);
  display: flex;
  align-items: center;
}

.create-new-btn {
  background-color: transparent;
  border: 1px solid rgba(6, 35, 73, 1);
  border-radius: 3px;
  width: 10.01vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.create-new-btn:disabled{
  cursor: not-allowed !important;
}
.assesment-tab-panel{
  background: rgba(245, 247, 249, 1);
  height: 58vh;
  overflow-x: auto;
}

.question-tab-panel {
  padding: 1.04vw 0 !important;
}

.asses-checkbox svg {
  color: var(--icons-color) !important;
  width: 1.25vw !important;
  height: 1.25vw !important;
}

.list-item-text .MuiFormControlLabel-label {
  font-size: 0.83vw !important;
  font-weight: 400;
  line-height: normal;
  color: var(--pri-font-color);
  word-break: break-all;
}

.asses-expand-icon {
  font-size: 20px !important;
}

.collapse-inside {
  margin-bottom: 1vw;
}

.coll-body-left {
  padding-left: 2.60vw;
}

.coll-body-right {
  padding-right: 2.60vw;
}

.assesment-tab-panel hr {
  opacity: 1 !important;
}

.asses-radiobox path {
  background-color: red;
}

.asses-radiobox svg {
  width: 1.04vw !important;
}

.mrb-5 {
  margin-bottom: 1.5vw;
}

.mrt-5 {
  margin-top: 1.5vw;
}

.assess-left-padd {
  margin-left: 2vw !important;
}

.assess-right-padd {
  margin-right: 2vw !important;
}

.assesment-quiz-tab,
.assesment-tabs {
  min-height: 2.5vw !important;
}

.assessment-action-btns {
  display: flex;
  align-items: center;
  gap: 10px;
}

.horizontal-divider {
  width: 1px;
  height: 1.7vw;
  background-color: var(--icons-color);
}


.marks-and-tags-info {
  margin-top: 0.5vw;
}

.marks-and-tags-info div,
.webinar-tags-part div {
  gap: 20px;
  padding: 0.3vw 1vw;
  border-radius: 1.04vw;
  border: 1px solid #979797;
}

.marks-and-tags-info p::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: max-content;
  background: gray;
}

.tooltip-content p,
.tooltip-content span {
  font-size: 0.72vw;
  color: var(--sec-font-color);
  font-weight: 400;
}

.tooltip-content span {
  font-weight: 700;
}

.main-quiz-container {
  margin-top: 0.6vw;
}

.main-quiz-container h2 {
  font-size: 0.9375vw;
  line-height: normal;
  margin: 0;
  color: var(--icons-color);
  font-weight: 500;
}
.quiz-title{
  width: 98%;
}
.word-break{
  word-break: break-word;
}
.main-quiz-container nav{
  width: 100%;
}
.main-quiz-container ol {
  flex-wrap: unset ;
  width: 99%;
}
.popup-breadcrumd ol{
  flex-wrap: unset;
}
.question-info-con{
  margin-top: 0.8vw !important;
  padding:0.52vw 0.83vw;
  background-color:#F5F7F9 ;
  width: 96%;
}

.quiz-edit-white-bg {
  background-color: #ffffff;
}

.filter-popup-main {
  padding: 1.30vw;
}

.question-info-con hr {
  opacity: 1 !important;
  border-color: #979797 !important;
}

.question-info-con span {
  color: var(--pri-font-color);
}

.questions-tabs {
  margin-top: 1vw;
  width: 99%;
}

.questions-tabs .create-new-btn {
  padding: 0.2vw 1vw;
  background-color: #ffffff;
}

.filters-pop p::before {
  content: "";
  display: none;
}

.filters-pop {
  width: 11vw;
}

.filters-arrow-icon {
  position: absolute;
  right: 1vw;
  border-color: var(--pri-font-color) !important;
  padding: 0.15vw;
}

.edit-tags-input legend {
  width: 0 !important;
}

.filter-popup-main h2 {
  font-weight: 600;
}

.Root {
  color: rgba(0, 0, 0, .85);
  font-size: 0.729vw;
}


.InputWrapper {
  width: 100%;
  border-radius: 4px;
  padding: 1px;
}

.InputWrapper:hover {
  border-color: #40a9ff;
}

.InputWrapper.focused {
  border-color: #40a9ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}

.InputWrapper input {
  height: 30px;
  padding: 1vw 2vw 1vw 1vw;
  width: 100%;
  flex-grow: 1;
  border: 1px solid #A8B6C3;
  margin: 0;
  border-radius: 6px;
  outline: 0;
}

.StyledTag {
  display: flex;
  align-items: center;
  margin: 2px;
  line-height: 22px;
  background-color: #819ABB33;
  border: 1px solid #e8e8e8;
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0.3125vw 10px 0.3125vw 10px;
  outline: 0;
  overflow: hidden;
  width: max-content;
  margin-top: 0.56vw;
  border-radius: 0.3125vw;
  gap: 10px;
}

.StyledTag:focus {
  border-color: #40a9ff;
  background-color: #e6f7ff;
}

.StyledTag span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--pri-font-color);
  font-size: 0.625vw;
}

.StyledTag svg {
  font-size: 1.25vw;
  cursor: pointer;
  padding: 4px;
}

.ExploreMoreIcon {
  display: flex;
  align-items: center;
  padding: 0 0.41vw;
  cursor: pointer;
  position: absolute;
  right: 1.5vw;
}

.ExploreMoreIcon svg {
  fill: currentColor;
  font-size: 1.35vw;
}

.filter-clear-all {
  border: 1px solid var(--icons-color);
  color: var(--icons-color);
  font-weight: 500;
  font-size: 0.9375vw !important;
  padding: 0.48vw 1.48vw;
  border-radius: 0.625vw;
  background-color: transparent;
}

.filter-btn-group {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  margin-top: 1vw;
}

.filter-clear-search {
  border: 1px solid var(--icons-color);
  color: var(--sec-font-color);
  font-weight: 500;
  font-size: 0.9375vw !important;
  padding: 0.48vw 1.48vw;
  border-radius: 0.625vw;
  background-color: var(--icons-color);
}

.popup-breadcrumd h2 {
  font-size: 0.9375vw;
  line-height: normal;
  margin: 0;
  color: var(--icons-color);
  font-weight: 500;
}

.mui-hr-line {
  width: 100%;
  height: 1px;
  background-color: #D2DBE3;
  margin-top: 1.56vw;
}

.edit-quiz-list {
  border: 1px solid #979797 !important;
  border-radius: 12px;
  padding: 0.5vw 1vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1vw;
}

.edit-quiz-list p {
  font-size: 0.83vw;
}

.popup-breadcrumd .horizontal-divider {
  height: 2.5vw;
  margin: 0px 0.5vw;
}

.load-more-edit-quiz {
  color: var(--icons-color);
  font-size: 0.72vw;
  font-weight: 500;
  cursor: pointer;
  padding: 0.1vw;
  text-decoration: underline;
}

.gap-1 {
  gap: 10px !important;
}

.gap-2 {
  gap: 20px !important;
}

.popup-breadcrumd .MuiSelect-select {
  padding: 0.7vw 1.14vw 0.7vw 0.5vw !important;
  font-size: 0.833vw !important;
  line-height: normal !important;
  margin: 0 !important;
}

.edit-quiz-select-op {
  width: 10vw;
}

.edit-quiz-select-medium {
  width: 100%;
}

.popup-breadcrumd label {
  margin-bottom: 0.72vw;
  font-weight: 500;
  font-size: 0.8vw;
}
.popup-breadcrumd nav {
  width: 95%;
}
.quiz-answer-con {
  margin-top: -0.6vw;
}

.webinar-upload-part {
  padding: 1.1vw 3.8vw;
  cursor: pointer;
}

.webinar-upload-part-parent {
  margin: 1.54vw 0;
}

.webinar-edit-title input[type="text"] {
  border: 0 !important;
  height: max-content !important;
  padding: 0.6vw 1.14vw 0.3vw 0;
  font-size: 1.04vw !important;
}

.webinar-edit-title label {
  font-size: 1.04vw !important;
  line-height: normal !important;
  font-family: var(--font-family);
}

.webinar-edit-title .MuiTextField-root {
  width: 100%;
}

.webinar-edit-title {
  margin-top: -1.30vw !important;
}

.webinar-online-modal h4 {
  margin: 3px 0 0 0;
}

.webinar-tags-select .MuiSelect-select {
  font-size: 0.9375vw !important;
  white-space: unset !important;
  line-height: normal !important;
  padding: 0.5vw 0 !important;
}

.webinar-tags-select {
  margin-bottom: 1vw;
}

.webinar-tags-select h4 {
  margin-bottom: 0.5vw;
}

.custom-tags input {
  border: none !important;
  appearance: none;
}

.custom-chip {
  height: max-content !important;
  border-radius: 0 !important;
}

.custom-chip span {
  font-size: 0.93vw !important;
  color: var(--pri-font-color) !important;
}

.webinar-tags-part h6 {
  font-size: 0.7vw;
  margin: 0;
}

.webinar-tags-part h6::before {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: var(--icons-color);
  align-items: center;
  margin: 0 0.3vw;
  border-radius: 50%;
}

.webinar-tags-part span {
  width: 1.5px;
  height: 0.78vw;
  background: gray;
}

.webinar-tags-part div {
  padding: 0.3vw 0.5vw;
}

.webinar-tab-pannel>.centered-panel {
  height: 100%;
  display: flex;
  width: 100%;
}

.add-session-modal hr {
  background-color: gray;
  width: 100%;
  opacity: 1 !important;
  margin: 1vw 0 0 0;
}

.MuiPickersDay-root,
.MuiDayCalendar-weekDayLabel,
.MuiPickersYear-yearButton {
  font-size: 0.72vw !important;
}

.MuiTimeClock-root span {
  color: var(--pri-font-color) !important;
  font-size: 0.72vw !important;
}

.MuiTimeClock-root button {
  width: max-content !important;
  font-size: 0.72vw !important;
}

.document-after-select {
  padding: 10px 20px;
  margin-top: 0.8vw;
}

.document-after-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid grey;
  border-radius: 6px;
}

.document-after-select svg {
  width: 1.30vw;
  height: 1.30vw;
  margin: 0 0.3vw;
}

.document-after-select h4 {
  font-size: 0.74vw;
  margin: 0;
}

.add-session-modal h4 {
  font-size: 0.84vw;
}

.course-session-list {
  padding: 0.6vw 1vw;
  border: 1px solid grey !important;
  border-radius: 0.57vw;
  margin-bottom: 1vw;

}

.course-session-list .course-sess-img {
  width: 20%;
}

.course-sess-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.course-sess-content h4 {
  font-weight: 600;
}

.course-sess-content h4,
.course-sess-content p {
  margin: 0 1vw;
}

.course-sess-content p {
  font-size: 0.72vw;
  margin-top: 0.4vw;
}

.list-items-padd {
  padding: 1.56vw 1.40vw 0px 1.19vw;
}

.add-session-btn {
  padding: 1.56vw 0 0px 0;
}

.add-session-btn .sec-btn {
  width: 100%;
  padding: 0.67vw 0 !important;
}

.add-session-float-btns {
  width: 39%;
  position: fixed;
  bottom: 0;
  padding: 1.3vw 1.40vw 1.3vw 1.19vw;
  background-color: #ffffff;
  justify-content: flex-end;
}

.add-session-inside {
  padding: 1vw;
}

.collapse-img {
  width: 7.55vw !important;
  height: 4.16vw;
}

.collapse-img img {
  height: 100%;
}

.webinar-tab-pannel hr {
  margin: 0.5vw 0 !important;
}

.replace-image-con {
  position: relative;
  width: 12.23vw;
  height: 7.29vw;
  margin: 1.4vw 0;
}

.replace-image-con button {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  background-color: rgba(4, 6, 23, 0.8);
  border-radius: 1.04vw;
  color: #fff;
  padding: 0.52vw 1vw;
  width: max-content;
  font-size: 0.84vw;
  font-weight: 400;
}

.replace-image-con img {
  height: 100%;
  width: 100%;
}

.webinar-about-us {
  border: 1px solid grey;
  border-radius: 0.5vw;
  padding: 1vw;
}

.wd-add-video {
  width: 7.01vw;
}

.upload-hr-line div {
  width: -webkit-fill-available;
  height: 1px;
  background-color: var(--gray-color);
}

.upload-hr-line p {
  margin: 0px 1vw;
}

.webinar-upload-part input {
  background-color: #ffffff;
  border: 0;
}

.webinar-upload-input {
  background-color: rgba(129, 154, 187, 0.1);
}

.webinar-upload-input input {
  margin-top: 2vw;
}

.webinar-uploaded-list {
  padding: 2vw;
}

.webinar-uploaded-list img {
  width: 7.29vw;
  height: unset;
}

.create-live-course {
  width: max-content !important;
}

.tags-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.autoComplete fieldset {
  border: 0;
  border-bottom: 1px #D2DBE3 solid !important;
}

.bi-chevron-right {
  width: 0.88vw !important;
  height: 0.88vw !important;
}
.quiz-answer-con{
  margin-top: -0.3vw;
}
.red-button{
  background-color: #BC4749 !important;
  color: var(--sec-font-color) !important;
  margin-right: 0.6vw !important;
}
.confirmation-popup p {
  font-size: 0.83vw;
  font-weight: 400;
  line-height: normal;
}
.confirmation-popup h2{
  font-size: 0.9375vw;
  line-height: normal;
  font-weight: 500;
}
.confirmation-popup .MuiDialog-paper{
  width: 25.10vw;
}
.no-questions-added{
  padding: 1vw;
  background-color: #ffffff;
  text-align: center;
  margin-top: 1vw;
}
.no-questions-added h3{
  font-weight: 500;
  font-size: 1.25vw !important ;
  color: rgba(151, 151, 151, 1) !important;
}
.disabled-submit-btn:disabled{
  background: #D8D8D8;
  color:var(--sec-font-color);
  border: none;
}
.MuiTooltip-tooltip{
  font-size: 12px !important;
  font-weight: 500 !important;
}
.create-new-btn:disabled{
  opacity: 0.4;
  background-color: transparent ;
}
.icon-disable:disabled{
  opacity: 0.4;
  background-color: transparent ;
}
.edit-disable:disabled #edit-icon:hover .edit-icon-svg,
.edit-disable:disabled #del-icon:hover .del-svg{
  fill:#B8B8B8 !important;
}
.edit-disable:disabled #edit-icon:hover .edit-icon-stroke,
.edit-disable:disabled #del-icon:hover .del-stroke{
  stroke: #B8B8B8 !important;
}
.edit-disable{
  background-color: transparent ;
}
.edit-disable:disabled,
.edit-disable:disabled svg,
.edit-disable:disabled path {
  cursor: not-allowed !important;
}
.assessment-tab-panel{
  margin-top: 1vw;
}

.fixed-btns{
  position: fixed;
  bottom: 0;
  width: 39%;
  background-color: #ffffff;
  padding: 1vw 1.5vw;
}
.fixed-video-btns{
  position: fixed;
  background-color: #ffffff;
  padding: 1vw;
  width: 70%;
  bottom: 0%;
  left: 20%;
  display: flex;
  justify-content: space-between;
}
.fixed-btns button:disabled{
  opacity: 0.3;
}
.model-padding{
  padding-left:1.19vw;
  padding-right: 1.40vw;
}
.thumbnail-img{
  width: 5.5vw;
}
.thumbnail-img img{
  height:3vw;
}
.thumb-line-svg{
  width: 2vw;
}
th[data-content = "Name"]{
  margin-top: 30px;
}
.delete-confirmation .MuiDialogActions-root{
  padding: 15px 20px !important;
}
.delete-confirmation p {
  word-break: break-word;
}
input[type = "date"],[type = "time"]{
  cursor: text !important;
}
input[type="date"] {
  -webkit-appearance: none; 
  appearance: none;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
  /* If you want to make it completely invisible, uncomment the following */
  background: none;
  color: transparent;
}
.centered-add-session{
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  height: 40vh;
  width: 100%;
  flex-direction: column !important;
}

.user-webinar-container{
  height: 100%;
  width: 100%;
}
.session-time-counter{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:20px;
  background-color:#ffffff;
  padding: 1vw 0;
  border-radius: 0.7vw;
}
.session-time-counter .horizontal-line::after{
  content: "";
  height: 6.77vw;
  width: 2px;
  background-color: #000;
  display: inline-block;
}
.session-time-counter h5{
  font-size: 3.33vw;
  font-weight: 900;
  color:#5681EF;
  font-family: 'Poppins',sans-serif !important;
}
.session-time-counter p{
  font-size: 0.9375vw;
  font-family: 'Poppins',sans-serif !important;
  font-weight: 400;
}
.session-time-counter .time-counter{
  height: -webkit-fill-available;
  width: 100%;
  text-align: center;
}
.user-webinar-container h2{
  font-size: 0.9vw;
  color: #043677;
  text-align: center;
  margin-bottom: 1vw;
}
.user-webinar-container h1{
  font-size: 1.25vw;
  padding: 1vw;
  margin-bottom: 1vw;
}
.main-timer-container{
  background-color: #F5F7F9;
  padding: 1.25vw;
  margin-bottom: 3.5vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  
}
.join-session-btn{
  color: var(--sec-font-color);
  font-size: 1.25vw;
  padding: 1vw 3.125vw;
  background-color:var(--icons-color) ;
  border-radius: 0.6vw;
  margin-top: 2vw;
}
.join-session-btn:disabled{
  opacity: 0.3;
}
.user-webinar-container{
  background-color: #ffffff;
  padding: 1.25vw;
}
.session-started-con p {
  width: 100%;
  background-color: rgba(86, 129, 239, 0.12);
  padding: 1vw;
  text-align: center;
}
.session-started-con img{
  width: 3.675vw;
  margin-bottom: 1.5vw;
}
.user-webinar-container h3{
  font-size: 1.5625vw;
  font-weight: 500;
  margin-bottom: 0.5vw;
  text-align: center;
}
.is-session-empty{
  background-color: #E7EBF1;
  display: grid;
  place-items: center;
  height: 40vh;
}
.is-session-empty p {
  font-size: 1vw;
  padding: 20px;
  text-align: center;
}
.sessions-video-list .playlist-heading{
  width: 18vw;
}
.course-webinar-differ{
  position: absolute;
  background-color: #FFFFFF;
  padding: 5px 15px;
  border-radius: 4px;
  color: #000;
  top: 9%;
  left: 10%;
  font-size: 0.625vw;
}

@media (max-width:991px) {
  .course-webinar-differ{
    font-size: 10px;
    left: 10%;
  }
  .user-webinar-container h3{
    font-size: 24px;
    margin-bottom: 10px;
  }
  .user-webinar-container h1{
    font-size: 20px;
    padding: 20px;
  }
  .session-started-con img{
    width: 60px;
    margin-bottom: 30px;
  }
  .user-webinar-container{
    padding: 20px;
  }
  .main-timer-container{
    margin-bottom: 75px;
    padding: 20px;
  }
  .join-session-btn{
    padding: 15px 50px;
    font-size: 20px;
    margin-top: 30px;
    border-radius: 10px;
  }
  .user-webinar-container h2{
    font-size: 18px;
    margin-bottom: 15px;
  }
  .session-time-counter h5{
    font-size: 50px;
  }
  .session-time-counter p{
    font-size: 16px;
  }
  .session-time-counter .horizontal-line::after{
    height: 80px;
  }
  .play-list-tab{
    font-size: 18px !important;
    margin-bottom: 15px !important;
  }
  .is-session-empty p {
    font-size: 20px;
  }
  .sessions-video-list .lms-playlist-content p{
    display: block;
    font-size: 12px;
    padding: 0 10px;
  }
  .sessions-video-list .lms-playlist-content{
    width: 100%;
  }
}

@media (max-width:600px){
  .main-timer-container{
    margin-bottom: 90px;
    padding: 20px;
  }
  .session-time-counter h5{
    font-size: 30px;
  }
  .session-time-counter p{
    font-size: 12px;
  }
  .session-time-counter .horizontal-line::after{
    height: 50px;
  }
  .session-time-counter .time-counter{
    width: 40%;
  }
  .session-time-counter{
    flex-wrap: wrap;
    padding: 15px 0;
  }
  .responsive-line{
    display: none;
  }
}
.MuiAlert-message{
  font-size: 0.89vw !important;
}
.edit-profile .loader{
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  margin-left: 8%;
}
.edit-profile .loader>img{
  width: 140px;
}
.cards-grid h1{
  font-size: 1.25vw;
  margin-bottom: 1vw;
  display: inline-flex;
  align-items: center;
  gap: 1vw;
}
.view-all-list{
  font-size: 0.83vw;
  padding: 0.5vw 0.8vw;
  background-color: transparent;
  font-weight: 500;
  color: var(--icons-color);
  text-decoration: underline !important;
  cursor: pointer;
}
@media (max-width:991px) {
  .edit-profile label{
    font-size: 18px;
  }
  .MuiAlert-message{
    font-size: 14px !important;
  }
  .cards-grid h1{
    font-size: 20px;
    margin-bottom: 20px;
  }
}


.language-code{
  font-weight: 500;
  font-size: 0.93vw;
  text-decoration: none !important;
  color: var(--pri-font-color);
  text-transform: capitalize;
}
.selected-lang{
  background: var(--icons-color);
  border-radius: 5px;
}

.selected-lang label{
  color: #FFFFFF !important;
}
.liMenu{
  margin-bottom: 0.4vw;
}
.liMenu:hover{
  background-color: var(--icons-color) !important;
  border-radius: 5px;
}
.liMenu:hover label{
  color: #ffffff !important;
}
.display-down {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.display-down span{
  margin-top: 0.36vw;
  font-size: 0.5729vw;
}
.right-pad{
  padding-right: 1.3vw;
}
.left-pad{
  padding-left: 1.3vw;
}
@media (max-width:991px) {
  .language-code{
    font-size: 18px;
  }
  .right-pad{
    padding-right: 0;
  }
  .left-pad{
    padding-left: 0;
  }
  .view-all-list{
    font-size: 16px;
  }
}
.video-course-space{
  margin-top: 3vw;
}
.admin-nav-icon .admin-nav-arrow{
  position: absolute;
  width: 1vw !important;
  height: 1vw !important;
}
.admin-nav-childs li a {
  display: flex;
  align-items: center;
  gap: 20px;
}
.admin-nav-childs li p{
  font-size: 0.72vw;
}

.menu-img {
  position: relative;
}

.admin-nav-childs {
  display: none; 
  position: absolute;
  top: 70%;
  left: 100%;
  transform: translateY(-50%);
  z-index: 9999;
  background-color: white;
  padding: 0;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}
.admin-nav-childs li a {
  padding: 0.5vw 1.5vw;
}
.admin-nav-childs li:hover .admin-nav-childs path{
  fill: #ffffff;
}

.admin-nav-icon:hover .admin-nav-childs {
  display: block;
}
.admin-nav-icon hr{
  margin: 0;
}
.lms-logo p {
  font-size: 0.625vw;
}
.admin-nav-childs a::after{
  display: none !important;
}
.menu-res-ul a{
  gap:20px;
}
.menu-res-img .admin-nav-arrow{
  position: absolute;
  right: -15px;
  top: 7px;
}
.admin-nav-res-con{
  position: relative;
}
#NoEnrolls{
  background-color: #ffffff;
  height: 70vh;
  width: 100%;
  display: grid;
  place-items: center;
}
#NoEnrolls p{
  margin-top: 2vw;
  font-size: 1.25vw;
  text-align: center;
}
#NoEnrolls>div{
  width: 50%;
}
#NoEnrolls button{
  background-color: var(--icons-color);
  color: #ffffff !important;
  padding: 0.5vw 1vw;
  border-radius: 3px;
  margin-top: 2vw;
}

#NoEnrolls a{
  color: #ffffff !important;
  font-size: 0.9375vw;
}
.no-enrolls-img{
  width: 10.416vw;
}
.place-items-center{
  place-items: center;
}
@media (max-width:991px) {
  #NoEnrolls button{
    padding: 10px 20px;
  }
  #NoEnrolls a{
    font-size: 18px;
  }
  .no-enrolls-img{
    width: 80%;
  }
  #NoEnrolls a{
  color: #ffffff !important;
  font-size: 18px;
  }
  #NoEnrolls>div{
    width: 100%;
  }
  #NoEnrolls p{
    margin-top: 2vw;
    font-size: 16px;
    text-align: center;
  }
}
.profileDropDown p {
  font-size: 0.72vw;
  padding: 0 0.8vw;
}
.category-toggle{
  font-size: 0.8vw !important;
  font-weight: 500;
  font-family: var(--font-family) !important;
  color: var(--pri-font-color) !important;
  background-color: #ffffff !important;
  border-radius: 5.20vw !important;
  padding: 0.5vw 2.1vw !important;
}
.category-toggle.Mui-selected{
  background-color: var(--icons-color) !important;
  color: #ffffff !important;
}
.certificates-con li{
  width: 23%;
}
.certificates-con{
  flex-wrap: wrap;
  gap: 2vw;
}
@media (max-width:991px) {
  .certificates-con li{
    width: 31%;
  }
  .certificates-con{
    gap: 20px;
  }
}
@media (max-width:700px) {
  .certificates-con li{
    width: 48%;
  }
}
@media (max-width:576px) {
  .certificates-con li{
    width: 100%;
  }
}
.score-card ul{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 2%;
  gap: 2.3vw;
  padding: 1vw;
}
.score-card li {
  flex-basis: 29.5%;
}
.score-card img {
  width: 4.166vw;
}
.score-card h2{
  font-size: 1.35vw;
}
.score-card p{
  font-size: 0.9375vw;
  margin-top: 0.4vw;
}
@media (max-width:991px) {
  .category-toggle{
    font-size: 12px !important;
    padding: 10px 30px !important;
  }
  .preffered-cat>div{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  .score-card img{
    width: 50px;
  }
  .score-card li{
    width: 40%;
  }
  .score-card h2{
    font-size: 20px;
    line-height: normal;
  }
  .score-card p{
   font-size: 16px; 
   line-height: normal; 
  }
  .score-card ul{
    gap: 20px;
    align-items: center;
  }
  .score-card li{
    flex-basis: 48%;
  }
}
@media (max-width:576px) {
  .score-card li{
    flex-basis: 100%;
  }
}
.info-img{
  width: 1.30vw;
  height: 1.30vw;
}
.upload-btn{
  font-size: 1.25vw;
  width: 100%;
  padding: 1vw !important;
}
.video-upload-card{
  padding: 1vw;
  background-color: #ffffff;
  width: 33%;
}
.video-upload-card p {
  font-size: 0.88125vw;
  margin: 0.2vw 0;
}
.mui-stepper svg{
  width: 25px;
  height: 25px;
}
.upload-con-width{
  width: 80%;
}
.upload-center-con{
  display: flex;
  justify-content: center;
  width: 100%;
}
.upload-center-con .video-input input{
opacity: 1 !important;

}
.fixed-video-btns button{
  padding: 0.5vw 2vw;
  border-radius: 8px;
  font-size: 0.83vw;
  background-color: var(--icons-color);
  color: #ffffff;
}
.fixed-video-btns .back-btn{
  background-color: transparent;
  color: var(--pri-font-color);
  border: 1px solid var(--icons-color);
}
.fixed-video-btns button:disabled{
  opacity: 0.3;
}
.video-upload-position{
  position: absolute;
  width: 75%;
}
.upload-white-bg{
  background-color: #ffffff;
  margin-bottom: 3vw;
  margin-top: 1vw;
  height: 67vh;
  position: relative;
  overflow: auto;
}
.upload-white-bg .quiz-list:last-child{
  margin-bottom: 7vw;
}

@media (max-width:1800px) {
  .upload-white-bg{
    height: 65vh;
  }
}
@media (max-width:1600px) {
  .upload-white-bg{
    height: 63vh;
  }
}
@media (max-width:1100px) {
  .upload-white-bg{
    height: 60vh;
  }
}
.upload-ststus-details .delete-img{
  width: 20px;
  cursor: pointer;
}
#video-up{
  padding: 20px;
}
#video-up li {
  list-style: none;
}
.studio-course-creation  fieldset{
  border: none;
}
.studio-course-creation .webinar-upload-part-parent{
  margin-bottom: 0;
}
.studio-course-creation #select-category{
  font-size: 1vw;
  background-color: #f5f5f5;
}
.studio-course-creation textarea{
  border: 0;
  background-color: #f5f5f5;
  height: 200px;
}
.studio-course-creation .form-control{
  height: unset;
}
.studio-course-creation input,
.studio-course-creation textarea{
  border: none;
  background-color: #f5f5f5 !important;
  font-size: 1vw !important;
}
.studio-course-creation .form-control{
  box-shadow: none;
}
.studio-course-creation h4{
  margin-bottom: 0.8vw;
}
.MuiBreadcrumbs-li a{
  opacity: 0.5;
}
.studio-course-creation .fixed-btns {
  width: 72%;
  left: 17.5%;
}
.MuiStepIcon-text{
  font-size: 0.79vw !important;
  line-height: normal !important;
}
.uploaded-file-edit li {
  list-style: none;
  padding: 0.7vw 1vw;
  border: 1px solid #D2DBE3 !important;
  border-radius: 4px;
}
.uploaded-file-edit h4{
  font-size: 1.04vw;
}
.upload-ststus-details .video-thumb{
  width: 4vw;
  height: 2.5vw;
  object-fit: cover;
}
.uploaded-file-edit input,
.uploaded-file-edit textarea,
.uploaded-file-edit select,
.uploaded-file-edit .select-cat-upload{
  background-color:#f5f5f5 !important ;
  border: none !important;
}
.uploaded-file-edit fieldset{
  border:none !important;
}
.uploaded-file-edit .MuiSelect-select{
  background-color:#f5f5f5 !important;
}
.fixed-progress-bar{
  position: fixed;
  display: flex;
  gap: 1vw;
  background-color: #ffffff;
  z-index: 99;
  width: 34%;
}
.progress-box{
  width: 30%;
  height: 6px;
  border-radius: 3px;
  background-color: #D9D9D9;
}
.fixed-progress-bar .completed{
  background-color: #5681EF;
}
.mt-20{
  margin-top: 2.5vw;
}
.quiz-list{
  padding: 0.8vw 1vw !important;
  border: 1px solid #D2DBE3 !important;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5vw;
}
.quiz-list h3{
  font-size: 0.8333vw;
  font-weight: 400;
  margin: 0;
}

.quiz-video-title{
  word-wrap: break-word;
  width: 40%;
}
.quiz-list img{
  width: 4vw;
}

.preview-items h4{
  font-weight: 700;
  font-size: 1.04vw;
  line-height: 1.25vw;
  color: var(--progress-bar-color);
}
 
.preview-titles{
  width: 100%;
}
 
.preview-titles-flex{
  display: flex;
  justify-content: space-around;
  padding-top: 0.78vw;
}
 
.preview-titles-flex p,  .preview-desc{
  font-size: 0.83vw;
  line-height: 1.14vw;
}
.preview-list ul {
  margin-bottom: 6vw;
}

.preview-list li {
  background-color: #ffffff;
}
.preview-list .collapse-item{
  background-color: #ffffff;
}
.bg-white{
  background-color: #ffffff;
}

#no-courses{
  height: 75vh;
  background-color: #ffffff;
  border-radius: 8px;
  margin-top: 1vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
#no-courses img{
  width: 11vw;
}
#no-courses button{
  font-size: 0.9375vw;
  background-color:var(--icons-color);
  padding: 0.5vw 2vw;
  color: #ffffff;
  border-radius: 4px;
}
#no-courses p{
  font-size: 1.258vw;
  margin: 2vw 0 1vw 0;
}

.preview-item img{
  width: 10.41vw;
}
.dragable-Quiz-list{
  padding: 1.2vw !important;
}
.studio-title{
  font-size: 1vw;
}
.dragable-Quiz-con .upload-white-bg{
  height: 65vh !important;
}
.create-user-title{
  padding: 1.5vw !important;
}
#video-up li {
  padding:1vw 1.5vw;
  border: 1px solid #D2DBE3;
  border-radius: 4px;
}
.invite-user{
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 6px;
  background-color: transparent;
  width: 11.45vw;
  padding: 0.6vw 1vw;
  font-size: 0.833vw;
}
.add-to-group-inp[type=button]:disabled{
  cursor: not-allowed !important;
}
.user-email-list li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.6vw 0vw;
  border-bottom: 1px solid #D2DBE3 !important;
}
.add-user-select{
  font-size: 0.88vw !important;
  line-height: normal;
  padding: 0.5vw 1.5vw  0.5vw 1vw !important;
  min-width: 7vw;
  max-width: 13vw;
}
.user-email-list{
  height: 15vw;
  overflow: auto;
}
.user-email-list .MuiSelect-select{
  padding: 0 !important;
}
button[disabled]{
  cursor: not-allowed !important;
}
.search-no-user-con .invite-user{
  padding: 0.4vw 1vw;
  width: 8vw;
}
.no-selected-users{
  background-color: #ffffff;
}
.copy-success-btn{
  border-color: #278A42;
  color: #278A42;
}
.PrivateSwitchBase-input {
  margin: 0 !important;
}
.course-preview-thumb{
  width: 12vw !important;
}
.disabled-cat{
  background-color: #f5f5f5;
  margin: 1vw 0;
  width: 50%;
}
.disabled-cat h2{
  font-size: 0.86vw;
  font-weight: 500;
  padding: 0.6vw 1vw;
  margin: 0;
}
.points-input{
  padding: 0.6vw 1vw;
  font-weight: 500 !important;
}
.vertical-bar{
  background-color: #DEE5F8;
  width: 100%;
  padding: 0.8vw 1vw;
  display: flex;
  justify-content: end;
  margin-top: 0.6vw;
  align-items: center;
}
.vertical-bar .vertical-bar-delete{
  background-color: var(--icons-color) !important;
  color:#ffffff !important;
  display: flex;
  align-items: center;
  gap: 0.5vw;
  padding: 0.4vw 0.8vw;
  border-radius: 3px;
}
.vertical-bar .horizontal-divider{
  margin: 0 1vw;
}

.collection-tab-right-collection .vertical-bar-share{
  background-color: unset !important;
  padding: 0 0.78vw 0 0;
}
.collection-tab-right-collection .create-new-btn{
  padding: 0.2vw 0 ;
  width: 7vw;
}

.vertical-bar-delete{
  background-color: var(--progress-bar-color) !important;
  margin: 0 0.78vw 0 0;
}

.vertical-bar-delete svg{
  height: 1vw;
  width: 1vw;
}
.share-cirfificate-left,.share-cirfificate-right{
  padding: 0 1.5vw !important;
}
.share-cir-title .share-title{
  padding:1vw 1.5vw !important;
}
.share-cir-title .react-tabs__tab{
  margin-bottom: 0 !important;
}
.share-cirfificate-right .share-title{
  padding:3.5vw 1vw 1vw 0 !important;
}
.share-cirfificate-right .admin-share-list{
  height: 23vw !important;
}
.share-cirfificate-right{
  margin-top: -6vw;
}
.user-email-list li h2{
  width: 13vw;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}
.group_list_padding{
  padding: 0 1.5vw !important;
}
.group_list_padding li{
  padding: 1rem 0 !important;
}
.video-edit-margin{
  margin-top: 3vw;
}

@media (max-width:1600px) {
  .video-edit-margin{
    margin-top: 3.5vw;
  }
}
.dropfile .MuiSvgIcon-fontSizeMedium{
  width: 1.3vw;
  height: 1.3vw;
}
.gap-0_5{
  gap:0.5vw;
}
.document-close-icon .MuiSvgIcon-fontSizeMedium{
  display: none;
}
.user-question-title-con{
  width:100% !important;
}

.share-video-con-dis span{
  width: 100%;
  display: flex;
  gap: 1vw;
}
#documentError{
  border: 1px solid rgb(255, 73, 73) !important;
}
.documents-list{
  flex-wrap: wrap;
}
.documents-list .dropfile{
  width: 8.30vw;
}
.tags-input{
  padding: 1vw 1.14vw 1vw 1vw !important;
}
.multipleVideoSpinner img{
  width: 8vw;
  height: 8vw;
}

.multipleVideoSpinner .spinner-border{
  width: 100%;
}
.upload-white-bg input[type = "radio"]{
  cursor: pointer;
}
.mui-retake-feild input[type= "text"]{
  height: 2.1vw;
}